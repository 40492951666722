import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import placeholder from './../../assets/images/temp.png';
import Button from '../../components/Base/Button';
import UserRow from '../../components/Profile/UserRow';
import { useNavigate } from 'react-router-dom';
import CancelButton from '../../components/Pages/CancelButton';
import useCurrentUser from '../../hooks/useCurrentUser';
import Modal from '../../components/Base/Modal';
import { Skeleton } from '@mantine/core';
import UpdateProfilePictureModal from '../../components/Profile/UpdateProfilePictureModal';
const Profile = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState('');
  const user = useCurrentUser();
  const updatePassword = () => {
    navigate('/profile/password/update');
  };
  const goToEdit = () => {
    navigate('/profile/update');
  };

  useEffect(() => {
    user && setImage(user?.profileImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className="w-full">
      <Header header="Profile" subtext="Update and make profile changes" />

      {true ? (
        <div className="px-10 mt-10 lg:px-20">
          <div className="card">
            <div className="px-10 lg:py-16">
              <div className="flex flex-col items-center gap-5 sm:w-2/6 xl:w-1/6">
                {image ? (
                  <img src={image} alt="" className="rounded-[50%] w-24 h-24" />
                ) : (
                  <img src={placeholder} alt="" />
                )}

                {user?.role !== 'Sales' && (
                  <Button onClick={() => setShowModal(true)}>
                    Change image
                  </Button>
                )}
              </div>

              <div className="flex flex-col gap-5 mt-20">
                <div className="flex items-center justify-between pb-5 border-b border-gray-200">
                  <h1 className="font-bold">Admin information</h1>
                  {user?.role !== 'Sales' && (
                    <p
                      className="cursor-pointer text-primary"
                      onClick={goToEdit}
                    >
                      Edit
                    </p>
                  )}
                </div>

                <UserRow
                  title="Admin names"
                  content={
                    user?.firstname ? user.firstname + ' ' + user.lastname : ''
                  }
                />
                <UserRow
                  title="Email address"
                  content={user?.email ? user?.email : 'No email'}
                />
                <UserRow
                  title="Phone number"
                  content={user?.phone ? user?.phone : 'No phone'}
                />
                <UserRow
                  title="Role type"
                  content={user?.role ? user?.role : 'No role'}
                />
                <UserRow
                  title="Branch"
                  content={user?.branch.name ? user?.branch.name : 'No branch'}
                />

                <div className="mt-14 lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <CancelButton />
                    <Button type="submit" onClick={updatePassword}>
                      Update Password
                    </Button>
                  </div>
                </div>
                <Modal width="w-[30rem]" isOpen={showModal}>
                  <UpdateProfilePictureModal
                    setIsOpen={() => setShowModal(false)}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white p-30">
          <Skeleton />
        </div>
      )}
    </div>
  );
};

export default Profile;
