import React from 'react';
import logo from './../.././assets/images/logo.svg';
import Input from '../../components/Base/Input';
import Button from '../../components/Base/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

import { toast } from 'react-toastify';
import { Field, Form, Formik } from 'formik';
import { changePasswordValidationSchema } from '../../interfaces/forms';
import InputValidationError from '../../components/Base/InputValidationError';

const ChangePassword = () => {
  const { changeNewPassword } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center py-8 mx-3 w-[25rem] sm:mx-0 ">
      <div>
        <div>
          <img src={logo} alt="" />
        </div>
      </div>
      <h2 className="pt-5 mb-3 lg:text-[22px] font-semibold">
        Create a New Password
      </h2>
      <div className="w-full">
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={changePasswordValidationSchema}
          onSubmit={async ({ password }, { setSubmitting }) => {
            setSubmitting(true);
            const data = await changeNewPassword(password);
            if (data.status === 200) {
              toast.success('Password was successfully changed');
              navigate('/auth/login');
              localStorage.removeItem('tempEmail');
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div className="flex flex-col space-y-6">
                <div>
                  <Field
                    name="password"
                    type="password"
                    as={Input}
                    label="New Password"
                    placeholder="Enter a new password"
                    isPassword
                  />
                  {errors.password && touched.password ? (
                    <InputValidationError text={errors.password} />
                  ) : null}
                </div>
                <div>
                  <Field
                    name="confirmPassword"
                    type="password"
                    as={Input}
                    label="Confirm New Password"
                    placeholder="Confirm your new password"
                    isPassword
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <InputValidationError text={errors.confirmPassword} />
                  ) : null}
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isSubmitting={isSubmitting}
                >
                  {isSubmitting
                    ? 'Creating new password...'
                    : 'Create new password'}
                </Button>{' '}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <p className="pt-6 text-textcolor">
        Take me back to
        <Link to={'/auth/login'} className="pl-1 cursor-pointer text-primary ">
          Login
        </Link>
      </p>
    </div>
  );
};

export default ChangePassword;
