import React from 'react';
import { GiftIcon } from '../../assets/icons/icons';
import { formatFullString } from '../../functions/formatDate';

interface Props {
  color: string;
  header: string;
  description: string;
  startdate: string;
  enddate: string;
}

const ActivePromotionBox = ({
  startdate,
  enddate,
  color,
  header,
  description,
}: Props) => {
  const hexToRgb = (hex: string): string => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
  };

  const backgroundColor1 = `rgba(${hexToRgb(color)}, 0.2)`;
  const backgroundColor2 = `rgba(${hexToRgb(color)}, 0.8)`;
  return (
    <div>
      <div
        style={{ backgroundColor: backgroundColor1 }}
        className={`flex gap-2 flex-col-reverse lg:flex-row justify-between rounded-t-lg px-4 md:items-center pb-3 pt-5 bg-opacity-20`}
      >
        <div>
          <p style={{ color: color }} className={`font-bold  text-opacity-80 `}>
            {header}
          </p>

          <p className={`text-[10px] text-[${color}] text-opacity-80 `}>
            {description}
          </p>
        </div>
        <div>
          <div
            style={{ backgroundColor: backgroundColor1 }}
            className={` p-2 rounded-[50%] bg-opacity-20 flex justify-center items-center w-14 h-14`}
          >
            <GiftIcon />
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: backgroundColor2 }}
        className={` rounded-b-lg px-4 py-3 bg-opacity-80`}
      >
        <div className="flex items-center gap-6 text-white">
          <div className="flex flex-col gap-1">
            <p className="text-[10px] font-semibold">Start Date</p>
            <p className="text-[10px]">{formatFullString(startdate)}</p>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[10px] font-semibold">End Date</p>
            <p className="text-[10px]">{formatFullString(enddate)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivePromotionBox;
