import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface IContext {
  mobileMenu: boolean;
  setMobileMenu: Dispatch<SetStateAction<boolean>>;
}

let AppContext = createContext<IContext>({
  setMobileMenu: (boolean) => boolean,
  mobileMenu: false,
});

export const useApp = () => useContext(AppContext);

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const values = {
    mobileMenu,
    setMobileMenu,
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
