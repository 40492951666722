import { createContext, useContext } from 'react';
import { IUser } from '../interfaces/user';
import useLocalStorage from '../hooks/useLocalStorage';
import axiosInstance from '../api/axios';

interface IContext {
  loginUser: (email: string, password: string) => Promise<[]>;
  user: IUser | null;
  logoutUser: () => void;
  resetPassword: (email: string) => Promise<any>;
  sendVerificationCode: (
    email: string,
    code: number,
    type: 'password' | 'device'
  ) => Promise<any>;
  reSendVerificationCode: (
    email: string,
    type: 'password' | 'device'
  ) => Promise<any>;
  changeNewPassword: (password: string) => Promise<any>;
  token: null;
  // tempEmail: string;
}

let AuthContext = createContext<IContext>({
  loginUser: async () => [],
  token: null,
  user: {
    id: 0,
    firstname: '',
    lastname: '',
    fullname: '',
    email: '',
    phone: '',
    password: '',
    profileImage: '',
    currentDeviceId: '',
    currentDeviceName: '',
    createdBy: '',
    lastLogin: '',
    lastLoginIp: '',
    isEmailVerified: false,
    status: false,
    role: '',
    branch: {
      id: 0,
      name: '',
      createdBy: '',
      assignedStaff: 0,
      location: '',
      branchImage: '',
      createdAt: '',
      updatedAt: '',
    },
    createdAt: '',
    updatedAt: '',
  },
  logoutUser: () => {
    return;
  },
  sendVerificationCode: async (
    email: string,
    code: number,
    type: 'device' | 'password'
  ) => Promise<{ data: []; status: number }>,
  changeNewPassword: async (password: string) => Promise<{ data: [] }>,
  reSendVerificationCode: async (email: string, type: 'device' | 'password') =>
    Promise<{ data: []; status: number }>,
  resetPassword: async () => {
    return;
  },
  // tempEmail: '',
});

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [user, setUser] = useLocalStorage('efgauth', null);
  const [token, setToken] = useLocalStorage('token', null);

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const loginUser = async (email: string, password: string) => {
    try {
      const { data, status } = await axiosInstance.post('/auth/admin/signin', {
        email,
        password,
      });

      setUser(data.user);

      if (status === 200) {
        setUser(data.user);
        setToken(data.access_token);
      }

      return data;
    } catch (err) {
      window.localStorage.setItem('tempEmail', email);
      return err;
    }
  };

  const logoutUser = async () => {
    try {
      const { data, status } = await axiosInstance.get(
        '/auth/admin/signout',
        options
      );

      if (status === 200) {
        setUser(null);
        setToken(null);
      }

      return data;
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  const resetPassword = async (email: string) => {
    try {
      const { data } = await axiosInstance.post('/admin/password-reset/code', {
        email,
      });

      return data;
    } catch (err) {
      return err;
    }
  };

  const sendVerificationCode = async (
    email: string,
    code: number,
    type: 'password' | 'device' = 'password'
  ) => {
    const data = await axiosInstance.post('/code/admin/verify', {
      email,
      code,
      type,
    });
    return data;
  };
  const reSendVerificationCode = async (
    email: string,
    type: 'password' | 'device' = 'password'
  ) => {
    const data = await axiosInstance.post('/code/admin/resend', {
      email,
      type,
    });
    return data;
  };
  const changeNewPassword = async (password: string) => {
    const email = window.localStorage.getItem('tempEmail');
    const data = await axiosInstance.post('admin/password-reset/change', {
      email,
      password,
    });
    return data;
  };

  return (
    <AuthContext.Provider
      value={{
        changeNewPassword,
        reSendVerificationCode,
        sendVerificationCode,
        token,
        resetPassword,
        logoutUser,
        loginUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
