import React from 'react';
import { getColor } from '../functions/getColor';
interface Props {
  details: {
    title: string;
    value: string | number;
    isStatusString?: boolean;
  }[];
}
const UserDetailCard = ({ details }: Props) => {
  return (
    <div className="">
      <div className="grid grid-cols-2 gap-10 xl:grid-cols-3">
        {details.map((item, key) => (
          <div className="flex flex-col gap-1" key={key}>
            <h4 className="text-xs text-textcolor">{item.title}</h4>

            {item.isStatusString || item.title === 'Transaction Type' ? (
              <div
                className="p-2 text-xs capitalize rounded-lg status w-fit"
                style={getColor(item.value)}
              >
                {item.value}
              </div>
            ) : item.title === 'Status' ? (
              <span
                className={` status w-4/12 text-xs ${
                  item.value === 'active' ? 'active' : 'inactive'
                } `}
              >
                {item.value}
              </span>
            ) : (
              <p className="capitalize break-words">{item.value}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserDetailCard;
