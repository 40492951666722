import React from 'react';

interface Props {
  title: string;
  content: string;
}

const UserRow = ({ title, content }: Props) => {
  return (
    <div className="flex items-center justify-between">
      <h1 className="text-sm text-textcolor">{title}</h1>
      <p className="cursor-pointer text-primary">{content}</p>
    </div>
  );
};

export default UserRow;
