import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import { PlusIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import { useNavigate } from 'react-router-dom';
import { INotification } from '../../interfaces/user';
import { useDashboard } from '../../context/DashboardContext';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import PushNotificationsTable from '../../components/Notification/PushNotificationTable';
import TableSkeleton from '../../components/Base/TableSkeleton';

const PushNotification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const { token } = useAuth();
  const { usersReload } = useDashboard();
  const [loading, setLoading] = useState(false);

  const fetchNotifications = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('/push-notification/get/list', {
        headers,
      });

      setNotifications(data.data);

      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReload]);
  return (
    <section className="w-full pb-32">
      <Header header="Push Notification" subtext="Handle push notifications">
        <Button
          icon={<PlusIcon />}
          onClick={() => navigate('/push_notification/add')}
        >
          Send Push Notification
        </Button>
      </Header>

      <div className="px-8 my-20">
        <div className="my-10 ">
          <div className="mt-8">
            {loading ? (
              <TableSkeleton />
            ) : (
              <PushNotificationsTable items={notifications} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PushNotification;
