import React from 'react';
import Header from '../../components/Pages/Header';
import { Form, Formik, Field } from 'formik';
import Button from '../../components/Base/Button';
import Input from '../../components/Base/Input';
import InputValidationError from '../../components/Base/InputValidationError';
import { changeAdminPasswordValidationSchema } from '../../interfaces/forms';
import CancelButton from '../../components/Pages/CancelButton';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';

const UpdatePassword = () => {
  const { token } = useAuth();
  return (
    <div className="w-full">
      <Header header="Profile" subtext="Update and make profile changes" />

      <div className="px-10 mt-10 lg:px-20">
        <div className="card">
          <div className="px-5 md:px-10 lg:py-16">
            <h1 className="text-xl font-bold">Update Password</h1>

            <div className="px-4 py-10 lg:w-7/12">
              <Formik
                initialValues={{
                  currentPassword: '',
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={changeAdminPasswordValidationSchema}
                onSubmit={async (data, { setSubmitting }) => {
                  setSubmitting(true);
                  const options = {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                  };
                  const body = {
                    currentPassword: data.currentPassword,
                    newPassword: data.newPassword,
                  };
                  try {
                    const { data } = await axiosInstance.post(
                      '/admin/update-password',
                      body,
                      options
                    );

                    toast.success('Password updated successfully');
                    // setImage(url);
                    // setUploading(false);
                    return data;
                  } catch (err) {
                    // setUploading(false);
                    return err;
                  }
                }}
              >
                {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <div className="flex flex-col w-full space-y-8">
                      <div>
                        <Field
                          name="currentPassword"
                          as={Input}
                          label="Current Password"
                          placeholder="**********"
                          isPassword
                        />
                        {errors.currentPassword && touched.currentPassword ? (
                          <InputValidationError text={errors.currentPassword} />
                        ) : null}
                      </div>
                      <div>
                        <Field
                          name="newPassword"
                          as={Input}
                          label="New Password"
                          placeholder="**********"
                          isPassword
                        />
                        {errors.newPassword && touched.newPassword ? (
                          <InputValidationError text={errors.newPassword} />
                        ) : null}
                      </div>
                      <div>
                        <Field
                          as={Input}
                          name="confirmPassword"
                          label="Confirm password"
                          placeholder="**********"
                          isPassword
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <InputValidationError text={errors.confirmPassword} />
                        ) : null}
                      </div>
                    </div>
                    <div className="mt-16 lg:w-4/6">
                      <div className="flex items-center gap-5">
                        <CancelButton />
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          isSubmitting={isSubmitting}
                        >
                          Update Password
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
