import React from 'react';
import Spinner from './Spinner';
interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  full?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isSubmitting?: boolean;
  icon?: React.ReactNode;
  variant?: string;
}

const Button = ({
  type = 'button',
  full,
  children,
  onClick,
  disabled,
  isSubmitting,
  icon,
  variant = 'primary',
}: Props) => {
  return (
    <button
      className={`${variant} ${
        full && 'w-full'
      } capitalize disabled:cursor-not-allowed whitespace-nowrap flex items-center justify-center  gap-3 px-2.5 xmd:px-4 py-2.5 text-xs font-bold rounded-lg  hover:opacity-70 focus:outline-none focus:shadow-outline`}
      onClick={onClick}
      disabled={disabled || isSubmitting}
      type={type}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onClick && onClick();
        }
      }}
    >
      {icon && icon}
      {isSubmitting && <Spinner />}

      {children}
    </button>
  );
};

export default Button;
