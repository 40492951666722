import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be 6 or more characters'),
});
export const addStaffValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('Please enter the staffs first name'),
  lastname: Yup.string().required('Please enter the staffs last name'),
  phone: Yup.string().required('Please enter the staffs phone number'),
  branch: Yup.string().required('Please enter a branch'),
  role: Yup.string().required('Please enter the staffs role'),
  email: Yup.string().required('Please enter the email address or password'),
});
export const addTransactionValidationSchema = Yup.object().shape({
  description: Yup.string().required('Please enter a description'),
  amount: Yup.string().required('Please enter an amount'),
  accountNumber: Yup.string().required('Please enter an account number'),
});
export const addCustomerValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('Please enter the customers first name'),
  lastname: Yup.string().required('Please enter the customers last name'),
  phone: Yup.string().required('Please enter the customers phone number'),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter the customers email'),
});

export const changePasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
});
export const changeAdminPasswordValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords must match'),
});
export const addBranchValidationSchema = Yup.object().shape({
  branchname: Yup.string().required('Branch Name is Required'),
  location: Yup.string().required('Location is Required'),
});
export const editProfileValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('First Name is Required'),
  lastname: Yup.string().required('Last Name is Required'),
  branch: Yup.string().required('Branch is Required'),
});
export const addPromotionFormSchema = Yup.object().shape({
  header: Yup.string().required('Header is Required'),
  description: Yup.string().required('Description is Required'),
  startDate: Yup.string().required('Start Date is Required'),
  endDate: Yup.string().required('End Date is Required'),
});
export const discountCouponFormSchema = Yup.object().shape({
  percentageOff: Yup.number().required('Percentage Off is Required'),
  quantity: Yup.number().required('Quantity is Required'),
  endDate: Yup.string().required('End Date is Required'),
  startDate: Yup.string().required('Start Date is Required'),
  branch: Yup.string().required('Branch is Required'),
});
export const pushNotificationFormSchema = Yup.object().shape({
  header: Yup.string().required('Header is Required'),
  description: Yup.string().required('Description is Required'),
  type: Yup.string().required('Type is Required'),
});
