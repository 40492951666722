import React, { useEffect, useState } from 'react';
import { EmptyStateIcon, PlusIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import Dropdown from '../../components/Base/Dropdown';
import Header from '../../components/Pages/Header';
import { useNavigate } from 'react-router-dom';
import GeneralCard from '../../components/GeneralCard';
import SearchInput from '../../components/Base/SearchInput';
import { useDashboard } from '../../context/DashboardContext';
import CustomerTable from '../../components/Tables/CustomerTable';
import { useDebouncedValue } from '@mantine/hooks';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IBranch, IUser } from '../../interfaces/user';
import Modal from '../../components/Base/Modal';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import TableSkeleton from '../../components/Base/TableSkeleton';
import Pagination from '../../components/Base/Pagination';
import downloadExcel from '../../functions/downloadExcel';
const Customers = () => {
  const navigate = useNavigate();
  const { fetchCustomers, usersReload, loading } = useDashboard();
  const [items, setItems] = useState<IUser[]>([]);
  const currentdate = formatTomorrow();
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [lastPage, setLastPage] = useState(1);
  const [params, setParams] = useState({
    search_term: '',
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 10,
    page_number: 0,
    type: null,
  });
  const [meta, setMeta] = useState({ total: 0, active: 0, inactive: 0 });
  const goToAddPage = () => {
    navigate('/customer/add');
  };

  const fetchData = async () => {
    const data = await fetchCustomers(params);
    setMeta((obj) => ({
      ...obj,
      total: data.meta?.total,
      active: data?.activeCustomers,
      inactive: data?.inActiveCustomers,
    }));

    setCurrentPage(data.meta.currentPage);
    setLastPage(data.meta?.lastPage);
    setItems(data?.data);
  };

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setParams((prevObj) => ({
        ...prevObj,

        type: null,
      }));
    }
    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }

    if (type === 'type') {
      if (event === 'Active') {
        setParams((prevObj) => ({
          ...prevObj,
          type: 1 as any,
        }));
      }
      if (event === 'Inactive') {
        setParams((prevObj) => ({
          ...prevObj,
          type: 0 as any,
        }));
      }
    }
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
        page_number: 0,
      }));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);

  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };

  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };

  const handleDownload = () => {
    downloadExcel(items, 'customers');
  };

  return (
    <>
      {items && (
        <section className="w-full pb-32">
          <Header
            header="Customers"
            subtext="Monitor and Manage customers here"
          >
            <div className="flex flex-wrap items-center gap-2 xs:flex-nowrap xl:gap-10">
              <Dropdown
                options={[
                  'All',
                  'Today',
                  'This Week',
                  'This Month',
                  'Last Month',
                  'This Year',
                  'Custom',
                ]}
                text="All"
                title="Date"
                onChange={(val) => {
                  handleChange(val, 'date');
                }}
              />
              <Dropdown
                options={['All', 'Active', 'Inactive']}
                text="All"
                title="Type"
                onChange={(val) => {
                  handleChange(val, 'type');
                }}
              />

              <Button icon={<PlusIcon />} onClick={goToAddPage}>
                Add New Customer
              </Button>
              <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
                <Button variant="outline" onClick={handleDownload}>
                  Download CSV
                </Button>
              </div>
            </div>
          </Header>

          <div className="px-8">
            <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3">
              <GeneralCard
                title={'Total Customers'}
                numbers={meta?.total ? meta?.total : 'Nill'}
                additions={'+5 this week'}
              />
              <GeneralCard
                title={'Total Active Customers'}
                numbers={meta.active ? meta?.active : 'Nill'}
                additions={'+5 this week'}
              />
              <GeneralCard
                title={'Total Inactive Customers'}
                numbers={meta.inactive ? meta?.inactive : 'Nill'}
                additions={'+5 this week'}
              />
            </div>

            <div className="mt-10 card">
              <div className="flex flex-col justify-between gap-3 sm:flex-row">
                <div className="sm:w-3/6 xmd:w-2/6">
                  <SearchInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search..."
                  />
                </div>
                <Dropdown
                  options={[
                    '5',
                    '10',
                    '15',
                    '20',
                    '30',
                    '50',
                    '70',
                    '100',
                    '120',
                  ]}
                  text="10"
                  title="Table Size"
                  onChange={(val) => {
                    handleChange(val, 'size');
                  }}
                />
              </div>

              <div className="mt-8">
                {loading ? (
                  <div className="py-10">
                    <TableSkeleton />
                  </div>
                ) : items.length > 0 ? (
                  <CustomerTable items={items} />
                ) : (
                  <div className="flex flex-col items-center gap-4 pb-3">
                    <EmptyStateIcon />
                    <p>No Customers</p>
                    <div className="md:w-2/12">
                      <Button icon={<PlusIcon />} onClick={goToAddPage}>
                        Add New Customer
                      </Button>
                    </div>
                  </div>
                )}

                <Pagination
                  handleMoreClicks={(val) => handleMoreClicks(val)}
                  lastPage={lastPage}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />

                <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
                  <FilterMonthModal
                    setIsOpen={() => setShowFilterMonthModal(false)}
                    getStartDate={(val) => {
                      handleCustomDates(val, 'start_date');
                    }}
                    getEndDate={(val) => {
                      handleCustomDates(val, 'end_date');
                    }}
                  />
                </Modal>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Customers;
