import { Formik, Field, Form } from 'formik';
import { useState } from 'react';
import { addStaffValidationSchema } from '../../interfaces/forms';
import Button from '../Base/Button';
import Input from '../Base/Input';
import InputValidationError from '../Base/InputValidationError';
import FileUploadView from '../Base/FileUploadView';
import { useDashboard } from '../../context/DashboardContext';
import { useNavigate } from 'react-router-dom';
import Select from '../Base/Select';
import useBranches from '../../hooks/useBranches';

const AddStaffForm = () => {
  const navigate = useNavigate();
  const { addStaff } = useDashboard();
  const [selectedBranch, setSelectedBranch] = useState('');
  const branches = useBranches();
  const [isSales, setIsSales] = useState(false);
  const [file, setFile] = useState<any>();
  const goToPrevPage = () => {
    navigate('/staffs/');
  };

  return (
    <div>
      <div className="py-5 ">
        <FileUploadView
          setFile={(file) => {
            setFile(file);
          }}
        />
      </div>
      <div className="md:px-10">
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            branch: '',
            role: '',
            email: '',
            password: '',
            profile_image: {},
            phone: '',
          }}
          validationSchema={addStaffValidationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            try {
              const formData = new FormData();
              setSubmitting(true);
              formData.append('firstname', data.firstname);
              formData.append('lastname', data.lastname);
              formData.append('branch', selectedBranch);
              formData.append('role', data.role);

              if (isSales) {
                formData.append('password', data.password);
              }
              formData.append('email', data.email);

              formData.append('profile_image', file);
              formData.append('phone', data.phone);

              await addStaff(formData);
              navigate('/staffs/');
              setSubmitting(false);
            } catch (err) {
              setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, values }) => (
            <Form>
              <div className="flex flex-col space-y-8">
                <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                  <div className="md:w-3/6">
                    <Field
                      name="firstname"
                      as={Input}
                      label="Staff’s first name*"
                      placeholder="Enter first name"
                    />
                    {errors.firstname && touched.firstname ? (
                      <InputValidationError text={errors.firstname} />
                    ) : null}
                  </div>

                  <div className="md:w-3/6">
                    <Field
                      name="lastname"
                      as={Input}
                      label="Staff’s last name*"
                      placeholder="Enter last name"
                    />
                    {errors.lastname && touched.lastname ? (
                      <InputValidationError text={errors.lastname} />
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                  <div className="md:w-3/6">
                    <Field
                      name="email"
                      type="email"
                      as={Input}
                      label="Staff’s email address*"
                      placeholder="example@mail.com"
                    />
                    {errors.email && touched.email ? (
                      <InputValidationError text={errors.email} />
                    ) : null}
                  </div>

                  <div className="md:w-3/6">
                    <Field
                      name="phone"
                      as={Input}
                      label="Phone Number*"
                      placeholder="Enter phone number"
                    />
                    {errors.phone && touched.phone ? (
                      <InputValidationError text={errors.phone} />
                    ) : null}
                  </div>
                </div>
                <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                  <div className="md:w-3/6">
                    <Field
                      name="branch"
                      label="Staff’s Branch*"
                      as={Select}
                      options={branches}
                      value={values.branch}
                      onChange={(event: { name: string; id: number }) => {
                        const newEvent = {
                          target: {
                            name: 'branch',
                            value: event.name,
                          },
                        };
                        setSelectedBranch(String(event.id));
                        handleChange(newEvent);
                      }}
                    />

                    {errors.branch && touched.branch ? (
                      <InputValidationError text={errors.branch} />
                    ) : null}
                  </div>
                  <div className="md:w-3/6">
                    <Field
                      name="role"
                      label="Staff’s role*"
                      as={Select}
                      options={['Super', 'Manager', 'Sales']}
                      value={values.role}
                      onChange={(event: string) => {
                        if (event === 'Sales') {
                          setIsSales(true);
                        } else {
                          setIsSales(false);
                        }
                        const newEvent = {
                          target: {
                            name: 'role',
                            value: event,
                          },
                        };
                        handleChange(newEvent);
                      }}
                    />

                    {errors.role && touched.role ? (
                      <InputValidationError text={errors.role} />
                    ) : null}
                  </div>
                </div>
                {isSales && (
                  <div className="md:w-3/6">
                    <Field
                      name="password"
                      as={Input}
                      label="Staff’s Password"
                      placeholder="example@mail.com"
                    />
                  </div>
                )}

                <div className="lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <Button variant="outline" onClick={goToPrevPage}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {isSubmitting ? 'Creating Staff' : ' Add Staff'}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddStaffForm;
