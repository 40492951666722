import React, { useCallback, useEffect, useState } from 'react';
import Button from '../Base/Button';
import NameBackground from '../Base/NameBackground';

interface Props {
  fullName: string;
  email: string;
  phone?: string | number;
  image?: string;
  handleClick?: () => void;
  noButton?: boolean;
  walletBalance?: string | number;
  lastName?: string;
}

const CustomerProfileCard = ({
  handleClick,
  image,
  phone,
  fullName,
  email,
  noButton,
  lastName,
  walletBalance,
}: Props) => {
  const handleClickMemoized = useCallback(() => {
    handleClick && handleClick();
  }, [handleClick]);

  const [profileImage, setProfileImage] = useState('');

  useEffect(() => {
    image && setProfileImage(image);
  }, [image]);
  return (
    <div className="p-5 bg-white rounded">
      <div className="flex flex-col items-center gap-3 xmd:items-start">
        {profileImage ? (
          <div>
            <img
              src={profileImage}
              alt=""
              className="rounded-[50%] w-20 h-20 min-h-auto"
            />
          </div>
        ) : (
          <NameBackground
            firstName={fullName}
            lastName={lastName ? lastName : ''}
          />
        )}
        <p className="header">{fullName}</p>
        <p>{email}</p>
        {phone && <p>+{phone}</p>}
        {!noButton && (
          <Button variant="outline" onClick={handleClickMemoized}>
            Send push notification
          </Button>
        )}
        {walletBalance && (
          <div className="flex items-center justify-between w-full gap-3 p-3 text-white rounded bg-primary">
            <p className="whitespace-nowrap">Wallet Balance</p>
            <p className="text-base font-bold break-words">
              {`${walletBalance ? walletBalance : 'Nil'}`}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerProfileCard;
