import React from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import Button from '../Base/Button';

interface Props {
  setIsOpen: () => void;
  obj:
    | {
        title: string | undefined;
        content: string | undefined;
        type: string | undefined;
        platform: string | undefined;
      }
    | undefined;

  onSubmit: () => void;
}

const PreviewNotification = ({ onSubmit, setIsOpen, obj }: Props) => {
  return (
    <div className="bg-[#F8F8F8]  mx-auto gap-10 h-[73vh]  rounded-xl ">
      <div className="p-3">
        <div className="flex justify-end">
          <span
            onClick={setIsOpen}
            className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
          >
            <XMarkIcon />
          </span>
        </div>
        <div className="flex justify-center">
          <h1 className="text-center header">Preview Push Notification </h1>
        </div>

        {obj && (
          <div className="flex flex-col gap-8 mt-10 px-14">
            <div className="flex flex-col gap-2 ">
              <h2 className="text-center text-dark text-opacity-60">
                Notification’s Header
              </h2>
              <p className="text-xs font-medium text-center">{obj.title}</p>
            </div>
            <div className="flex flex-col gap-2 ">
              <h2 className="text-center text-dark text-opacity-60">
                Notification’s Description
              </h2>
              <p className="text-xs font-medium text-center">{obj.content}</p>
            </div>
            <div className="flex flex-col gap-2 ">
              <h2 className="text-center text-dark text-opacity-60">
                Recieving Platform
              </h2>
              <p className="text-xs font-medium text-center">{obj.platform}</p>
            </div>
            <div className="flex flex-col gap-2 ">
              <h2 className="text-center text-dark text-opacity-60">
                Recieving Customers
              </h2>
              <p className="text-xs font-medium text-center">{obj.type}</p>
            </div>
          </div>
        )}
        <div className="mt-14">
          <Button full onClick={onSubmit}>
            Send Push Notification
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PreviewNotification;
