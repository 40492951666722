import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { formatDate } from '../../functions/formatDate';
import { IBranch } from '../../interfaces/user';
import Modal from '../../components/Base/Modal';
import Button from '../../components/Base/Button';
import { XMarkIcon } from '../../assets/icons/icons';
import AddBranchForm from '../../components/Store/AddBranchForm';
import UpdateProfilePictureModal from '../../components/Profile/UpdateProfilePictureModal';
import { useDashboard } from '../../context/DashboardContext';
import ConfirmationModal from '../../components/Base/ConfirmationModal';
import useDisableUser from '../../hooks/useDisableUser';

const Branch = () => {
  const { user, token } = useAuth();
  const { reload, usersReload } = useDashboard();
  const [showModal, setShowModal] = useState(false);
  const [branchData, setBranchData] = useState<IBranch>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState<'Deactivate' | 'Activate'>(
    'Deactivate'
  );
  const [editModal, setEditModal] = useState(false);
  let { id } = useParams();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const getBranch = async (): Promise<void> => {
    const { data } = await axiosInstance.get(`/branch/get/${id}`, options);

    setBranchData(data.data);
    try {
    } catch (err) {
      console.log(err);
    }
  };
  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: changeStatus,
    id: branchData?.id ? branchData.id : 0,
    isBranch: true,
  });
  const handleDisableUser = () => {
    disableUser();
    setConfirmModal(false);
  };
  useEffect(() => {
    getBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, usersReload]);
  useEffect(() => {
    setChangeStatus(branchData?.status ? 'Deactivate' : 'Activate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, usersReload, branchData]);
  return (
    <section className="pb-20 ">
      <div className="">
        <div className="flex items-center justify-between card md:p-8">
          <h3 className="font-bold md:text-xl text-dark">View Branch</h3>

          {user?.role !== 'Sales' && (
            <div className="flex items-center gap-2">
              <Button onClick={() => setEditModal(true)}>Edit Branch</Button>
              <Button onClick={() => setConfirmModal(true)} variant="outline">
                {branchData?.status ? 'Deactivate Branch' : 'Activate Branch'}
              </Button>
            </div>
          )}
        </div>

        <Modal width="w-[30rem]" isOpen={showModal}>
          {branchData && (
            <UpdateProfilePictureModal
              isBranch
              branchObj={branchData}
              setIsOpen={() => setShowModal(false)}
            />
          )}
        </Modal>

        <Modal
          width="w-[40rem]"
          isOpen={editModal}
          onClose={() => setEditModal(false)}
        >
          {branchData && (
            <div className="bg-[#F8F8F8]  mx-auto gap-5 h-fit  rounded-xl ">
              <div className="p-3">
                <div className="flex justify-end">
                  <span
                    onClick={() => setEditModal(false)}
                    className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
                  >
                    <XMarkIcon />
                  </span>
                </div>

                {user?.role !== 'Sales' && (
                  <div className="flex justify-center">
                    <h1 className="text-center header">Edit Branch</h1>
                  </div>
                )}
                <div className="px-10 pb-10">
                  <AddBranchForm
                    isEdit
                    branchObj={branchData}
                    setIsOpen={() => setEditModal(false)}
                  />
                </div>
              </div>
            </div>
          )}{' '}
        </Modal>
        <Modal
          width="w-[30rem]"
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
        >
          {branchData && (
            <ConfirmationModal
              closeModal={() => setConfirmModal(false)}
              name={branchData?.name}
              type="branch"
              confirmAction={handleDisableUser}
              submitting={isSubmitting}
              status={changeStatus}
            />
          )}{' '}
        </Modal>
        {branchData && (
          <div className="flex flex-col gap-4 md:m-20 card">
            <div className="flex flex-col gap-4 px-4">
              <img
                src={branchData.branchImage}
                className="object-cover w-24 h-auto"
                alt=""
              />
              {user?.role !== 'Sales' && (
                <div>
                  <Button onClick={() => setShowModal(true)}>
                    Change image
                  </Button>
                </div>
              )}
            </div>

            <div className="grid grid-rows-3 gap-10 pt-6 xs:grid-cols-2 sm:grid-cols-3 md:px-8">
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Branch Name</p>
                <p className="text-sm font-light">{branchData.name}</p>
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Branch</p>
                <p className="text-sm font-light">{branchData.location}</p>
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Created By</p>
                <p className="text-sm font-light">{branchData.createdBy}</p>
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Status</p>
                <p
                  className={`status w-fit ${
                    branchData.status ? 'active' : 'inactive'
                  }`}
                >
                  {' '}
                  {branchData.status ? 'Active' : 'Inactive'}
                </p>
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Created At</p>
                <p className="text-sm font-light">
                  {' '}
                  {formatDate(branchData.createdAt)}
                </p>
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="font-medium">Assigned Staffs</p>
                <p className="text-sm font-light">
                  {' '}
                  {branchData._count?.staffs}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Branch;
