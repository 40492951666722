import React from 'react';
import Header from '../../components/Pages/Header';
import { LongArrowLeftIcon } from '../../assets/icons/icons';
import AddStaffForm from '../../components/Staff/AddStaffForm';
import { useNavigate } from 'react-router-dom';

const AddStaff = () => {
  const navigate = useNavigate();

  const goToPrevPage = () => {
    navigate('/staffs/');
  };

  return (
    <div className="w-full pb-36">
      <Header
        header="Add a New Staff"
        subtext="Fill in the form to add new staff"
      />
      <div className="px-8 mt-6">
        <div className="card">
          <div
            onClick={goToPrevPage}
            className="flex items-center gap-4 cursor-pointer text-textcolor"
          >
            <LongArrowLeftIcon />
            <p>Back to staff</p>
          </div>

          <AddStaffForm />
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
