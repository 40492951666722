import Header from '../../components/Pages/Header';
import Button from '../../components/Base/Button';
import { AddIcon, GiftIcon } from '../../assets/icons/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const Marketing = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <section className="pb-20">
      <Header header="Marketing" subtext="Improve sales & create discounts" />
      <div className="px-3 mt-10 md:px-8">
        <div className="w-full overflow-auto">
          <div className="flex flex-wrap items-center justify-between gap-5 p-1 md:flex-nowrap md:p-3 card">
            <div className="flex items-center h-10 gap-4">
              <p
                className={`font-bold cursor-pointer  ${
                  pathname === '/marketing' ||
                  pathname === '/marketing/addpromotion'
                    ? 'text-primary'
                    : 'text-textcolor'
                }`}
                onClick={() => navigate('/marketing')}
              >
                Promotional Information
              </p>
              <p
                className={`font-bold cursor-pointer  ${
                  pathname.includes('discounts') ||
                  pathname === '/marketing/addcoupon'
                    ? 'text-primary'
                    : 'text-textcolor'
                }`}
                onClick={() => navigate('/marketing/discounts')}
              >
                Discounts
              </p>
            </div>
            <div className="flex flex-wrap gap-5 md:flex-nowrap">
              <>
                <Button
                  icon={<AddIcon />}
                  variant="purpleoutline"
                  onClick={() => navigate('/marketing/addpromotion')}
                >
                  Add New Promotion
                </Button>
                <Button
                  icon={<GiftIcon />}
                  variant="purple"
                  onClick={() => navigate('/marketing/addcoupon')}
                >
                  Create Discount coupon
                </Button>
              </>
            </div>
          </div>
          <div className="p-3 mt-3 ">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Marketing;
