import React from 'react';
import {
  AnalyticsGrowthIcon,
  AnalyticsPeopleIcon,
} from '../../assets/icons/icons';
import WhiteDropdown from './WhiteDropdown';
import { AnalyticsLineChart } from '../Charts/AnalyticsLineChart';
import { IAnalytics } from '../../interfaces/user';
import { formatNumberWithCommas } from '../../functions/formatNumber';

interface Props {
  analyticsData: IAnalytics;
  handleChange: (value: string, type: string) => void;
  filteredValue: string;
}

const AnalyticsMiddleBar = ({
  filteredValue,
  analyticsData,
  handleChange,
}: Props) => {
  return (
    <div className="grid grid-cols-2 gap-5 md:grid-rows-2 md:gap-6 md:grid-cols-6">
      <div className="col-span-4 md:row-span-2">
        <div className="w-full px-5 pt-5 pb-10 overflow-auto bg-white md:px-10 rounded-xl">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1 mb-5">
              <h2 className="font-bold xl:text-3xl">Revenue Insights</h2>
              <p className="text-[#5321CA] font-medium">Total revenue</p>
            </div>

            <div className="px-5 py-1 fullflex">
              <WhiteDropdown
                options={[
                  'All',
                  'This Week',
                  'Past Month',
                  '2 Months',
                  '3 Months',
                  '6 Months',
                  'Custom',
                ]}
                onChange={(val) => {
                  handleChange(val, 'graph');
                }}
                text={filteredValue}
              />
            </div>
          </div>
          <div className="w-full md:h-52">
            <AnalyticsLineChart
              plots={analyticsData.revenueAndDeposits.revGraph.data}
            />
          </div>
        </div>
      </div>

      <div className="w-full col-span-2 gap-2 p-5 bg-white h-fit rounded-xl">
        <div className="flex flex-col gap-3 lg:flex-row">
          <div className="p-3.5 bg-[#EAE6FF] h-12 w-12 rounded-[50%] flex items-center justify-center">
            <AnalyticsGrowthIcon />
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="font-bold xl:text-3xl">
              {' '}
              {formatNumberWithCommas(
                analyticsData.revenueAndDeposits.revenue.data
              )}
            </h2>
            <p className="text-[#5321CA] font-medium">Total revenue</p>

            <WhiteDropdown
              options={[
                'All',
                'This Week',
                'Past Month',
                '2 Months',
                '3 Months',
                '6 Months',
                'Custom',
              ]}
              onChange={(val) => {
                handleChange(val, 'revenue');
              }}
              text={filteredValue}
            />
          </div>
        </div>
      </div>
      <div className="w-full col-span-2 gap-2 p-5 bg-white h-fit rounded-xl">
        <div className="flex flex-col gap-3 lg:flex-row">
          <div className="p-3.5 bg-[#88FF8D] bg-opacity-20 h-12 w-12 rounded-[50%] flex items-center justify-center">
            <AnalyticsPeopleIcon />
          </div>

          <div className="flex flex-col gap-1">
            <h2 className="font-bold xl:text-3xl">
              {' '}
              {formatNumberWithCommas(
                analyticsData.revenueAndDeposits.deposits.data
              )}
            </h2>
            <p className="font-medium text-green-500">Deposits</p>

            <WhiteDropdown
              options={[
                'All',
                'This Week',
                'Past Month',
                '2 Months',
                '3 Months',
                '6 Months',
                'Custom',
              ]}
              onChange={(val) => {
                handleChange(val, 'deposits');
              }}
              text={filteredValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsMiddleBar;
