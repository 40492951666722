import React, { useEffect, useState } from 'react';
import { ShareIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import CustomerProfileCard from '../../components/Customers/CustomerProfileCard';
import Header from '../../components/Pages/Header';
import UserDetailCard from '../../components/UserDetailCard';
import { useAuth } from '../../context/AuthContext';
import { IPaymentRequest } from '../../interfaces/user';
import axiosInstance from '../../api/axios';
import { useParams } from 'react-router-dom';
import { formatDate } from '../../functions/formatDate';
import Modal from '../../components/Base/Modal';
import PaymentRequestModal from '../../components/PaymentRequests/PaymentRequestModal';
import CardSkeleton from '../../components/Base/CardSkeleton';
import { formatNumberWithCommas } from '../../functions/formatNumber';

const PaymentRequest = () => {
  const { token } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  const [paymentRequest, setPaymentRequest] = useState<IPaymentRequest>();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const getPaymentRequest = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/payment-request/${id}`,
        options
      );
      setPaymentRequest(data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    getPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading ? (
        <div className="p-10">
          <CardSkeleton />
        </div>
      ) : (
        <section className="w-full">
          <Header
            breadCrumb="View transactions"
            header="Payment Requests"
            subtext="Monitor and manage transactions "
          >
            <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
              <Button variant="outline">Download CSV</Button>
            </div>
          </Header>

          <div className="flex flex-col justify-between gap-4 mx-5 my-8 xmd:flex-row lg:mx-10">
            <div className="xl:w-[23%]">
              {paymentRequest?.user && (
                <CustomerProfileCard
                  fullName={
                    paymentRequest.user.firstname +
                    ' ' +
                    paymentRequest.user.lastname
                  }
                  lastName={paymentRequest.user.lastname}
                  email={paymentRequest.user.email}
                  phone={paymentRequest.user.phone}
                  walletBalance={
                    '₦' +
                    formatNumberWithCommas(
                      Number(paymentRequest.user.walletBalance)
                    )
                  }
                  image={paymentRequest.user.profileImage}
                  noButton
                />
              )}
            </div>
            <div className="overflow-auto xl:w-9/12">
              <div className="p-5 mt-3 bg-white card">
                {paymentRequest && (
                  <UserDetailCard
                    details={[
                      {
                        title: 'Created at',
                        value: paymentRequest?.createdAt
                          ? formatDate(paymentRequest.createdAt)
                          : '',
                      },
                      {
                        title: 'Expiring at',
                        value: formatDate(paymentRequest.expiry),
                      },
                      {
                        title: 'Payment Type',
                        value: paymentRequest.type,
                      },
                      {
                        title: 'Transaction ID',
                        value: paymentRequest.transactionId
                          ? paymentRequest.transactionId
                          : 'Not Avaialble',
                      },
                      {
                        title: 'Amount',
                        value: paymentRequest.amount,
                      },
                      {
                        title: 'Transaction Fee',
                        value: paymentRequest.transactionFee,
                      },
                      {
                        title: 'Status',
                        value: paymentRequest.status,
                        isStatusString: true,
                      },
                      {
                        title: 'Title',
                        value: paymentRequest.title,
                      },

                      {
                        title: 'Invoice ID',
                        value: paymentRequest.invoiceId
                          ? paymentRequest.invoiceId
                          : 'Not available',
                      },
                      {
                        title: 'Branch',
                        value: paymentRequest.branch.name,
                      },
                    ]}
                  />
                )}
              </div>
              <Modal
                width="w-[30rem]"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              >
                {paymentRequest?.user && (
                  <PaymentRequestModal
                    userEmail={paymentRequest?.user.email}
                    setIsOpen={() => setIsOpen(false)}
                  />
                )}
              </Modal>
              <div className="flex items-center justify-between p-3 card">
                <div className="">
                  <Button
                    icon={<ShareIcon />}
                    onClick={() => setIsOpen(true)}
                    variant="purple"
                  >
                    Send payment Request
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default PaymentRequest;
