import React from 'react';
import { SearchIcon } from '../../assets/icons/icons';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  isPassword?: boolean;
  value?: string;
}
const SearchInput = ({
  required,
  label,
  placeholder = 'Search...',
  onChange,
  isPassword,
  value,
}: Props) => {
  return (
    <div
      className=" px-4 relative items-center rounded-lg py-2 flex w-full bg-[#8F92A1] bg-opacity-10
"
    >
      <SearchIcon />

      <input
        type="search"
        className="w-full pl-3 bg-transparent rounded placeholder:font-medium focus:outline-none focus:shadow-outline placeholder:text-sm text-dark"
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default SearchInput;
