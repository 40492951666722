import Pusher, { Channel } from 'pusher-js';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface MyPusher extends Pusher {
  getInstance?: () => MyPusher;
}

if (!process.env.REACT_APP_PUSHER_KEY) {
  toast.error('Missing API Key');
  throw new Error('Missing API Key');
}
const pusher: MyPusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: 'eu',
});

pusher.getInstance = () => pusher;

export const usePusher = (channelName: string) => {
  const [channel, setChannel] = useState<Channel | null>(null);

  useEffect(() => {
    const channel = pusher.subscribe(channelName);
    setChannel(channel);

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [channelName]);

  return channel;
};
