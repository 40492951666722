import { RouterProvider } from 'react-router-dom';
import './App.scss';
import { routes } from './routes/routes';
import { AuthContextProvider } from './context/AuthContext';
import ToastContainer from './components/Base/Toast';
import AppContextProvider from './context/AppContext';

const App = () => {
  return (
    <div className="transition-all ease-out">
      <ToastContainer />

      <AuthContextProvider>
        <AppContextProvider>
          <RouterProvider router={routes} />
        </AppContextProvider>
      </AuthContextProvider>
    </div>
  );
};

export default App;
