import React, { useEffect, useState } from 'react';
import Button from '../../components/Base/Button';
import axiosInstance from '../../api/axios';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { IDiscount } from '../../interfaces/user';
import { formatFullString } from '../../functions/formatDate';
import Modal from '../../components/Base/Modal';
import DiscountForm from '../../components/Marketing/DiscountForm';

const Discount = () => {
  const { token } = useAuth();
  let { id } = useParams();
  const [editModal, setEditModal] = useState(false);
  const [discountData, setDiscountData] = useState<IDiscount>();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const getDiscount = async (): Promise<void> => {
    try {
      const { data } = await axiosInstance.get(
        `/marketing/get-coupon/${id}`,
        options
      );

      setDiscountData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <section className="pb-20 ">
      <div className="card">
        <div className="flex items-center justify-between md:p-8">
          <h3 className="font-bold md:text-xl text-dark">
            View Discount Coupon
          </h3>
          <Button onClick={() => setEditModal(true)}>
            Edit Discount Coupon
          </Button>
        </div>

        <Modal
          width="w-[40rem]"
          isOpen={editModal}
          onClose={() => setEditModal(false)}
        >
          {discountData && (
            <DiscountForm
              closeModal={() => setEditModal(false)}
              values={discountData}
              isEdit
            />
          )}
        </Modal>

        {discountData && (
          <div className="grid grid-rows-3 gap-10 pt-6 xs:grid-cols-2 sm:grid-cols-3 md:px-8">
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">Coupon Code</p>
              <p className="text-sm font-light">{discountData.code}</p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">Branch</p>
              <p className="text-sm font-light">
                {discountData.branch ? discountData.branch.name : 'All'}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">Percentage Off</p>
              <p className="text-sm font-light">
                {discountData.percentageOff + '%'}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">Coupon Quantity</p>
              <p className="text-sm font-light">{discountData.quantity}</p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">Start Date</p>
              <p className="text-sm font-light">
                {formatFullString(discountData.start_date)}
              </p>
            </div>
            <div className="flex flex-col gap-1.5">
              <p className="font-medium">End Date</p>
              <p className="text-sm font-light">
                {formatFullString(discountData.end_date)}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Discount;
