import { Formik, Field, Form } from 'formik';
import React, { useState } from 'react';
import { addCustomerValidationSchema } from '../../interfaces/forms';
import Button from '../Base/Button';
import Input from '../Base/Input';
import InputValidationError from '../Base/InputValidationError';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';
import { IUser } from '../../interfaces/user';
import { useDashboard } from '../../context/DashboardContext';

interface Props {
  isEdit?: boolean;
  userObj?: IUser;
  setIsOpen?: () => void;
}

const AddCustomerForm = ({ setIsOpen, isEdit, userObj }: Props) => {
  const navigate = useNavigate();
  const { setUsersReload } = useDashboard();
  const goToPrevPage = () => {
    isEdit ? setIsOpen && setIsOpen() : navigate('/customers/');
  };
  const { token } = useAuth();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const [submitting, setSubmitting] = useState(false);
  const addCustomer = async (body: {}) => {
    try {
      setSubmitting(true);
      const url = isEdit ? '/admin/user/update' : '/admin/user/create';
      const { data, status } = await axiosInstance.post(url, body, options);
      if (status === 200) {
        isEdit
          ? toast.success('Customer edited successfully')
          : toast.success('Customer created successfully');
      }
      if (setUsersReload) {
        setUsersReload((prev) => !prev);
      }
      setIsOpen && setIsOpen();
      navigate('/customers/');
      setSubmitting(false);
      return data;
    } catch (err) {
      setSubmitting(false);
      return err;
    }
  };
  return (
    <div>
      <div className="py-5 "></div>
      <div className="md:px-10">
        <Formik
          initialValues={{
            firstname: isEdit ? userObj?.firstname : '',
            lastname: isEdit ? userObj?.lastname : '',
            email: isEdit ? userObj?.email : '',
            profile_image: {},
            phone: isEdit ? userObj?.phone : '',
          }}
          validationSchema={addCustomerValidationSchema}
          onSubmit={async (data) => {
            const body = {
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              phone: data.phone,
            };
            const editBody = {
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              phone: data.phone,
              id: userObj?.id,
            };

            addCustomer(isEdit ? editBody : body);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="flex flex-col space-y-8">
                <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                  <div className="md:w-3/6">
                    <Field
                      name="firstname"
                      as={Input}
                      label="Customer's first name*"
                      placeholder="Enter first name"
                    />
                    {errors.firstname && touched.firstname ? (
                      <InputValidationError text={errors.firstname} />
                    ) : null}
                  </div>

                  <div className="md:w-3/6">
                    <Field
                      name="lastname"
                      as={Input}
                      label="Customer's last name*"
                      placeholder="Enter last name"
                    />
                    {errors.lastname && touched.lastname ? (
                      <InputValidationError text={errors.lastname} />
                    ) : null}
                  </div>
                </div>
                <div className="">
                  <Field
                    name="email"
                    type="email"
                    as={Input}
                    label="Customer's email address*"
                    placeholder="example@mail.com"
                  />
                  {errors.email && touched.email ? (
                    <InputValidationError text={errors.email} />
                  ) : null}
                </div>

                <div className="">
                  <Field
                    name="phone"
                    as={Input}
                    label="Phone Number*"
                    placeholder="Enter phone number"
                  />
                  {errors.phone && touched.phone ? (
                    <InputValidationError text={errors.phone} />
                  ) : null}
                </div>

                <div className="lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <Button variant="outline" onClick={goToPrevPage}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={submitting}
                      isSubmitting={submitting}
                    >
                      {submitting
                        ? `...`
                        : `${isEdit ? 'Edit Customer' : 'Add Customer'}`}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddCustomerForm;
