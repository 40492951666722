import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LogoutIcon } from '../assets/icons/icons';
import { SideBarRoutes } from '../data/SidebarRoutes';
import SubNavigation from './SubNavigation';
import { useAuth } from '../context/AuthContext';

const MobileSidebar = () => {
  const { pathname } = useLocation();
  const { logoutUser } = useAuth();
  return (
    <nav className="mt-[4.2rem] bg-white -top-4 -left-2 z-20 absolute min-w-[15rem] duration-500 overflow-y-auto p-2 bg-white`">
      {' '}
      <div className="h-screen px-3 py-16 mt-8 bg-white">
        {SideBarRoutes.map((route, index) => (
          <Fragment key={index}>
            <div
              className={` ${route.hasGap ? 'mt-5' : 'mt-0'} ${
                pathname === route.path &&
                'bg-textcolor bg-opacity-10 rounded-md'
              } flex items-center my-2 py-2 px-3 text-left cursor-pointer gap-x-3 `}
            >
              <span> {route.icon}</span>
              <Link to={route.path ? route.path : '/'} className="medparagraph">
                {route.name}
              </Link>
            </div>
            <div>
              {route.subtitle && (
                <SubNavigation
                  title={route.subtitle}
                  subRoutes={route.titles}
                />
              )}
            </div>
          </Fragment>
        ))}
        <div
          onClick={() => logoutUser()}
          className="flex items-center gap-4 px-3 py-2 mt-20 rounded-md cursor-pointer bg-red-50"
        >
          <LogoutIcon />
          <p className="font-medium text-red-500">Log out</p>
        </div>
      </div>
    </nav>
  );
};

export default MobileSidebar;
