import React from 'react';
import { Sparklines, SparklinesCurve } from 'react-sparklines';

interface Props {
  data: number[];
  text: string;
  number: number;
}
const AnalyticsGraphCard = ({ data, text, number }: Props) => {
  return (
    <div className="flex flex-col gap-2 p-3 bg-white h-fit xl:p-5 rounded-xl">
      <h3 className="text-xs text-black break-words whitespace-pre-line md:text-base text-opacity-70 whitespace-nowrap">
        {text}
      </h3>

      <h2 className="text-xs font-bold md:text-lg xl:text-2xl text-dark">
        {Math.floor(number)}%{' '}
        <span className="text-xs text-dark text-opacity-40">today</span>{' '}
      </h2>

      <div className="sm:w-32">
        <Sparklines data={data}>
          <SparklinesCurve color={number < 0 ? '#B91C1C' : '#1B59F8'} />
        </Sparklines>
      </div>
    </div>
  );
};

export default AnalyticsGraphCard;
