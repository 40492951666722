import React, { useState } from 'react';
import { ChevronFillDownIcon } from '../../assets/icons/icons';
import useOutsideClick from '../../hooks/useOutsideClicks';

interface Props {
  options: string[];
  text: string;
  onChange?: (val: string) => void;
}

const WhiteDropdown = ({ onChange, options, text }: Props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const dropdownItemsRef = React.createRef<HTMLDivElement>();
  const [value, setValue] = useState<string>(text);

  const handleChange = (option: string) => {
    setValue(option);

    onChange && onChange(option);
  };
  useOutsideClick(dropdownItemsRef, () => setShowOptions(false));
  return (
    <div
      onClick={() => setShowOptions(!showOptions)}
      className="relative gap-2 px-3 py-2 bg-white border rounded-lg w-fit border-gray2 border-opacity-70"
    >
      <div className="flex items-center gap-2 cursor-pointer">
        <div className="flex items-center gap-2 whitespace-nowrap">
          <span className="text-xs font-bold capitalize text-[#979699] xmd:text-sm">
            {value}
          </span>
        </div>
        <ChevronFillDownIcon className="ml-2 xmd:ml-3" />
      </div>
      {showOptions && (
        <div
          ref={dropdownItemsRef}
          className="absolute z-10 bg-white border right-3 top-10 w-44 border-gray2 border-opacity-40"
        >
          <ul>
            {options.map((option, i) => (
              <li
                onClick={() => handleChange(option)}
                key={i}
                className={`${
                  value === option ? 'bg-gray2 bg-opacity-40' : null
                } capitalize p-2 xmd:p-3  text-xs xmd:text-sm font-bold text-center border-b cursor-pointer hover:opacity-40 border-gray2 border-opacity-40`}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WhiteDropdown;
