import React, { createRef, useState } from 'react';
import { ThreeDotsIcon } from '../../assets/icons/icons';
import useOutsideClick from '../../hooks/useOutsideClicks';
import DropdownItems from '../Base/DropdownItems';
import { IUser } from '../../interfaces/user';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { useAuth } from '../../context/AuthContext';
import Modal from '../Base/Modal';
import EditStaffModal from '../Staff/EditStaffModal';
import ConfirmationModal from '../Base/ConfirmationModal';
import useDisableUser from '../../hooks/useDisableUser';

interface Props {
  items: IUser[];
  branches: string[];
  currentPage: number;
  itemsPerPage: number;
}
const StaffTable = ({ itemsPerPage, currentPage, items, branches }: Props) => {
  const navigate = useNavigate();
  const { user: currentuser } = useAuth();
  const [userObj, setUserObj] = useState<IUser>();
  const [showDropdown, setShowDropdown] = useState(0);
  const dropdownItemsRef = createRef<HTMLDivElement>();
  const [editType, setEditType] = useState<'branch' | 'role'>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [changeStatus, setChangeStatus] = useState<'Deactivate' | 'Activate'>(
    'Deactivate'
  );
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(0));
  const getDropdownRoles = () => {
    if (!currentuser) return;
    let options: string[] = [];
    if (currentuser.role === 'Super') {
      options = [
        'View',
        // 'Edit Info',
        'Change Branch',
        'Change Role',
        userObj?.status ? 'Deactivate' : 'Activate',
      ];
    } else {
      options = ['View'];
    }
    return options;
  };

  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: changeStatus,
    id: userObj?.id ? userObj.id : 0,
    isCustomer: false,
  });

  const handleDropdownOption = (option: string) => {
    if (option === 'View') navigate(`/staff/${showDropdown}`);
    if (option === 'Change Branch') {
      setEditType('branch');
      setIsOpen(!isOpen);
    }
    if (option === 'Change Role') {
      setIsOpen(!isOpen);
      setEditType('role');
    }
    if (option === 'Deactivate' || option === 'Activate') {
      setConfirmModal(true);
      setChangeStatus(option);
    }
  };

  const handleDisableUser = () => {
    disableUser();
  };

  return (
    <div className="mb-5 overflow-x-auto bg-white">
      <table className="w-full mb-20 text-sm text-left ">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl whitespace-nowrap">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’s nAME
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’S ID
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’S ROLE
            </th>
            <th scope="col" className="px-6 py-3">
              BRANCH
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3">
              ACTION
            </th>
          </tr>
        </thead>
        {items.length ? (
          <tbody className="text-sm">
            {items.map((item, index) => (
              <tr className="relative whitespace-nowrap" key={item.id}>
                <td className="px-6 py-4">{index + 1}</td>
                <th scope="row" className="px-6 py-4 font-normal">
                  {item.firstname + ' ' + item.lastname}
                </th>
                <td className="px-6 py-4 capitalize">
                  {item.id ? item.id : 'Nill '}
                </td>
                <td className="px-6 py-4">{item.role}</td>
                <td className="px-6 py-4">{item.branch?.name}</td>
                <td className="px-6 py-4 ">
                  <div
                    className={`status  ${
                      item.status ? 'active' : 'inactive'
                    } `}
                  >
                    {item.status ? 'Active' : 'Inactive'}
                  </div>
                </td>
                <td className="px-4 py-4 cursor-pointer f">
                  <div
                    className="flex items-center justify-center w-full h-8 "
                    onClick={(e) => {
                      e.stopPropagation();
                      setUserObj(item);
                      setShowDropdown(item.id);
                    }}
                  >
                    <ThreeDotsIcon />
                  </div>
                </td>

                <td>
                  <Transition
                    show={showDropdown === item.id}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    ref={dropdownItemsRef}
                  >
                    <DropdownItems
                      isOpen={showDropdown === item.id}
                      onChange={(option) => handleDropdownOption(option)}
                      options={getDropdownRoles()}
                      onSelected={() => setShowDropdown(0)}
                    />
                  </Transition>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="flex justify-center w-full py-10">
            <tr>
              <td>No Staff</td>
            </tr>
          </tbody>
        )}
      </table>

      <Modal width="w-[30rem]" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {userObj && (
          <EditStaffModal
            userObj={userObj}
            setIsOpen={() => setIsOpen(false)}
            branches={branches}
            type={editType}
          />
        )}
      </Modal>

      <Modal
        width="w-[30rem]"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
      >
        {userObj && (
          <ConfirmationModal
            closeModal={() => setConfirmModal(false)}
            name={userObj?.firstname + ' ' + userObj.lastname}
            type="staff"
            confirmAction={handleDisableUser}
            submitting={isSubmitting}
            status={changeStatus}
          />
        )}
      </Modal>
    </div>
  );
};

export default StaffTable;
