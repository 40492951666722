import React, { useEffect, useState } from 'react';
import { PlusIcon, QrCodeIcon, ShareIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import Header from '../../components/Pages/Header';
import CustomerProfileCard from '../../components/Customers/CustomerProfileCard';
import CustomerInformationTab from '../../components/Customers/CustomerInformationTab';
import CustomerTransactionTab from '../../components/Customers/CustomerTransactionTab';
import { useParams } from 'react-router-dom';
import { useDashboard } from '../../context/DashboardContext';
import { IUser } from '../../interfaces/user';
import CardSkeleton from '../../components/Base/CardSkeleton';
import Modal from '../../components/Base/Modal';
import PushNotificationForm from '../../components/Customers/PushNotificationForm';
import ConfirmationModal from '../../components/Base/ConfirmationModal';
import useDisableUser from '../../hooks/useDisableUser';
import EditCustomersModal from '../../components/Customers/EditCustomersModal';
import CustomerPaymentRequestsTab from '../../components/Customers/CustomerPaymentRequestTab';
import PaymentRequestModal from '../../components/PaymentRequests/PaymentRequestModal';
import DeviceInfo from '../../components/Customers/DeviceInfoCard';
import { formatNumberWithCommas } from '../../functions/formatNumber';

const Customer = () => {
  const [activeTab, setActiveTab] = useState('Customer Information');
  const [confirmModal, setConfirmModal] = useState(false);
  const { getSingleUser, loading, usersReload } = useDashboard();
  const [user, setUser] = useState<IUser>();
  const [editModal, setEditModal] = useState(false);
  let { userId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState<'qr' | 'request'>('request');
  const [openPaymentRequestModal, setOpenPaymentRequestModal] = useState(false);
  const fetchDetails = async () => {
    if (!userId) return;
    const { data } = await getSingleUser(userId, 'user');
    setUser(data);
  };

  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: user?.Status ? 'Deactivate' : 'Activate',
    id: user?.id ? user.id : 0,
    isCustomer: true,
    closeModal: () => setConfirmModal(false),
  });
  const handleDisableUser = async () => {
    await disableUser();
  };

  const getActiveTab = () => {
    if (activeTab === 'Customer Information') {
      return <CustomerInformationTab />;
    }
    if (activeTab === 'Customer Transactions' && userId) {
      return <CustomerTransactionTab userId={userId} />;
    }
    if (activeTab === 'Payment Requests' && userId) {
      return <CustomerPaymentRequestsTab userId={userId} />;
    }
  };
  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReload]);
  return (
    <>
      <section className="w-full pb-20">
        <Header
          breadCrumb="View customer details"
          header="Customers"
          subtext="Monitor and Manage customers here"
        >
          <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
            <Button variant="outline" onClick={() => setEditModal(true)}>
              Edit customer details
            </Button>
            <Button icon={<PlusIcon />} onClick={() => setConfirmModal(true)}>
              {user?.Status ? 'Deactivate customer' : 'Activate customer'}
            </Button>
          </div>
        </Header>

        {loading ? (
          <CardSkeleton />
        ) : user ? (
          <div className="flex flex-col justify-between gap-4 mx-5 my-8 xmd:flex-row lg:mx-10">
            {/* {JSON.stringify(user)} */}
            <div className="xl:w-[23%]">
              <CustomerProfileCard
                fullName={user.firstname + ' ' + user.lastname}
                lastName={user.lastname}
                email={user.email}
                phone={user.phone}
                image={user.profileImage}
                walletBalance={
                  '₦' + formatNumberWithCommas(Number(user.walletBalance))
                }
                handleClick={() => setIsOpen(!isOpen)}
              />
              <div className="w-full">
                <DeviceInfo
                  deviceName={user.currentDeviceName}
                  deviceId={user.currentDeviceId}
                />
              </div>
            </div>

            <div className="overflow-auto xl:w-9/12">
              <div className="flex flex-wrap items-center justify-between gap-2 p-3 overflow-scroll lg:flex-nowrap card">
                <div className="flex items-center h-10 gap-4">
                  <p
                    className={`font-bold cursor-pointer break-words  ${
                      activeTab === 'Customer Information'
                        ? 'text-primary'
                        : 'text-textcolor'
                    }`}
                    onClick={() => setActiveTab('Customer Information')}
                  >
                    Customer Information
                  </p>
                  <p
                    className={`font-bold cursor-pointer break-words  ${
                      activeTab === 'Customer Transactions'
                        ? 'text-primary'
                        : 'text-textcolor'
                    }`}
                    onClick={() => setActiveTab('Customer Transactions')}
                  >
                    Customer Transactions
                  </p>
                  <p
                    className={`font-bold cursor-pointer break-words  ${
                      activeTab === 'Payment Requests'
                        ? 'text-primary'
                        : 'text-textcolor'
                    }`}
                    onClick={() => setActiveTab('Payment Requests')}
                  >
                    Payment Requests
                  </p>
                </div>
                <div className="flex flex-wrap items-center gap-3 lg:flex-nowrap">
                  <Button
                    icon={<QrCodeIcon />}
                    variant="purpleoutline"
                    onClick={() => {
                      setModalType('qr');
                      setOpenPaymentRequestModal(!openPaymentRequestModal);
                    }}
                  >
                    Scan QR Code
                  </Button>
                  <Button
                    icon={<ShareIcon />}
                    variant="purple"
                    onClick={() => {
                      setModalType('request');
                      setOpenPaymentRequestModal(!openPaymentRequestModal);
                    }}
                  >
                    Send payment Request
                  </Button>
                </div>
              </div>
              <div className="p-3 mt-3 card">{getActiveTab()}</div>
            </div>
          </div>
        ) : (
          <div className="my-10 lg:mx-10">No Customer</div>
        )}
        <Modal
          width="w-[30rem]"
          isOpen={openPaymentRequestModal}
          onClose={() => setOpenPaymentRequestModal(false)}
        >
          <PaymentRequestModal
            setIsOpen={() => setOpenPaymentRequestModal(false)}
            userEmail={user?.email}
            userName={user?.uuid}
            type={modalType}
          />
        </Modal>
        <Modal
          width="w-[30rem]"
          isOpen={confirmModal}
          onClose={() => setConfirmModal(false)}
        >
          {user && (
            <ConfirmationModal
              closeModal={() => setConfirmModal(false)}
              name={user?.firstname + ' ' + user.lastname}
              type="customer"
              confirmAction={handleDisableUser}
              submitting={isSubmitting}
              status={user?.Status ? 'Deactivate' : 'Activate'}
            />
          )}{' '}
        </Modal>
        <Modal
          width="w-[40rem]"
          isOpen={editModal}
          onClose={() => setEditModal(false)}
        >
          {user && (
            <EditCustomersModal
              userObj={user}
              setIsOpen={() => setEditModal(false)}
            />
          )}{' '}
        </Modal>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <div className="bg-[#F8F8F8] lg:mx-10 gap-5 lg:gap-10 h-[75vh] p-5 lg:p-10 rounded-xl flex justify-between ">
            <div className="hidden md:block">
              {user && (
                <CustomerProfileCard
                  fullName={user.firstname + ' ' + user.lastname}
                  email={user.email}
                  phone={user.phone}
                  image={user.profileImage}
                  noButton
                />
              )}
            </div>

            {user && (
              <PushNotificationForm
                id={user.id}
                closeModal={() => setIsOpen(false)}
              />
            )}
          </div>
        </Modal>
      </section>
    </>
  );
};

export default Customer;
