import React from 'react';
import VerifyCode from '../../components/Auth/VerifyCode';
const Verification = () => {
  const tempEmail = window.localStorage.getItem('tempEmail');

  return (
    <>
      <VerifyCode
        email={tempEmail ? tempEmail : ''}
        isDeviceVerification={true}
      />
    </>
  );
};

export default Verification;
