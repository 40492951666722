import React, { useState } from 'react';
import { ChevronFillDownIcon } from '../../assets/icons/icons';
import useOutsideClick from '../../hooks/useOutsideClicks';
import { IBranch } from '../../interfaces/user';

interface Props {
  options: string[] | IBranch[];
  text: string;
  title?: string;
  onChange?: (val: string | IBranch) => void;
}

const AnalyticsDropdown = ({
  onChange,
  options,
  text,
  title = 'Duration',
}: Props) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const dropdownItemsRef = React.createRef<HTMLDivElement>();
  const [value, setValue] = useState<string | IBranch>(text);

  const handleChange = (option: IBranch | string) => {
    if (typeof option === 'string') {
      setValue(option);
    } else {
      setValue(option.name);
    }
    onChange && onChange(option);
  };
  useOutsideClick(dropdownItemsRef, () => setShowOptions(false));
  return (
    <div
      onClick={() => setShowOptions(!showOptions)}
      className="relative gap-2 px-3 py-2 rounded-xl bg-gray2 bg-opacity-20"
    >
      <div className="cursor-pointer fullflex">
        <div className="flex items-center gap-2 whitespace-nowrap">
          <p className="text-xs font-medium text-textcolor xmd:text-base">
            {title}:
          </p>
          <span className="text-xs font-bold capitalize xmd:text-sm">
            {typeof value === 'object' ? value.name : value}
          </span>
        </div>
        <ChevronFillDownIcon
          color="#000"
          className="ml-2 text-black xmd:ml-3"
          fillOpacity="1"
        />
      </div>
      {showOptions && (
        <div
          ref={dropdownItemsRef}
          className="absolute left-0 z-10 bg-white border top-10 w-44 border-gray2 border-opacity-40"
        >
          <ul>
            {options.map((option, i) => (
              <li
                onClick={() => handleChange(option)}
                key={i}
                className={`${
                  value === option ? 'bg-gray2 bg-opacity-40' : null
                } capitalize p-2 xmd:p-3  text-xs xmd:text-sm font-bold text-center border-b cursor-pointer hover:opacity-40 border-gray2 border-opacity-40`}
              >
                {typeof option === 'object' ? option.name : option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AnalyticsDropdown;
