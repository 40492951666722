import React from 'react';
import { Progress } from '@mantine/core';

interface Props {
  color?: string;
  componentHeader?: string;
  image?: string;
  percentage: number;
}

const AnalyticsProgressBar = ({
  componentHeader = 'IKEOLUWA STORE',
  color = 'orange',
  image,
  percentage,
}: Props) => {
  return (
    <div className="flex items-center gap-4 px-3">
      <div>
        <img src={image} alt="" className="object-cover w-10 h-8 rounded-md" />
      </div>
      <div className="w-full">
        <h3 className="text-sm font-medium">{componentHeader}</h3>

        <div className="flex items-center w-full gap-2">
          <div className="w-full">
            <Progress
              value={percentage}
              label=""
              size="lg"
              radius="lg"
              color={color}
            />
          </div>

          <div className="font-medium">{Math.ceil(percentage)}% </div>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsProgressBar;
