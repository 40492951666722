import React, { createRef, useState } from 'react';
import { ThreeDotsIcon } from '../../assets/icons/icons';
import DropdownItems from './DropdownItems';
import useOutsideClick from '../../hooks/useOutsideClicks';

const Table = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownItemsRef = createRef<HTMLDivElement>();
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(false));
  return (
    <div className="relative  overflow-x-auto min-h-[20em] bg-white">
      <table className="w-full text-sm text-left ">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’s nAME
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’S ID
            </th>
            <th scope="col" className="px-6 py-3">
              STAFF’S ROLE
            </th>
            <th scope="col" className="px-6 py-3">
              BRANCH
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3">
              ACTION
            </th>
          </tr>
        </thead>
        <tbody className="text-sm">
          <tr className="cursor-pointer">
            <td className="px-6 py-4">1</td>
            <th scope="row" className="px-6 py-4 font-normal whitespace-nowrap">
              Rufai Tomiwa
            </th>
            <td className="px-6 py-4">0201982768</td>
            <td className="px-6 py-4">ADMIN</td>
            <td className="px-6 py-4">IKEJA</td>
            <td className="px-6 py-4 ">
              <div className="status active">active</div>
            </td>
            <td className="px-4 py-4 cursor-pointer f">
              <div
                ref={dropdownItemsRef}
                className="flex items-center justify-center w-full h-8 "
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <ThreeDotsIcon />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {showDropdown && (
        <DropdownItems options={['View', 'Edit info', 'Deactivate']} />
      )}
    </div>
  );
};

export default Table;
