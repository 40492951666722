import React, { useState } from 'react';
import QuickActionsCard from './QuickActionsCard';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PaymentRequestModal from '../PaymentRequests/PaymentRequestModal';
import Modal from '../Base/Modal';
import RepushTransactionModal from '../PaymentRequests/RepushTransactionModal';

const QuickActions = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [paymentRequestModal, setPaymentRequestModal] = useState(false);
  const [repushTransactionModal, setRepushTransactionModal] = useState(false);
  const [modalType, setModalType] = useState<'qr' | 'request'>('request');
  const [retryType, setRetryType] = useState<'repush' | 'refund'>('refund');
  return (
    <div className="mt-5 card">
      <h3 className="text-lg font-medium text-black100">Quick Actions</h3>

      <div className="grid grid-cols-2 gap-5 mt-5 md:gap-6 md:grid-cols-3 lg:grid-cols-4">
        {user?.role !== 'Sales' && (
          <>
            <QuickActionsCard
              text="Add a Branch"
              emoji="🏬"
              handleClick={() => navigate('/store_locator/add')}
            />
            <QuickActionsCard
              text="Repush Transaction"
              emoji="♻️"
              handleClick={() => {
                setRetryType('repush');
                setRepushTransactionModal(true);
              }}
            />
            <QuickActionsCard
              text="Refund Transaction"
              emoji="💳"
              handleClick={() => {
                setRetryType('refund');
                setRepushTransactionModal(true);
              }}
            />
            <QuickActionsCard
              text="Add Staff"
              emoji="👥 "
              handleClick={() => navigate('/staff/add')}
            />
          </>
        )}

        <Modal
          width="w-[30rem]"
          isOpen={paymentRequestModal}
          onClose={() => setPaymentRequestModal(false)}
        >
          <PaymentRequestModal
            type={modalType}
            setIsOpen={() => setPaymentRequestModal(false)}
          />
        </Modal>
        <Modal
          width="w-[30rem]"
          isOpen={repushTransactionModal}
          onClose={() => setRepushTransactionModal(false)}
        >
          <RepushTransactionModal
            type={retryType}
            setIsOpen={() => setRepushTransactionModal(false)}
          />
        </Modal>

        <QuickActionsCard
          text="Scan QR code"
          emoji="🔍"
          handleClick={() => {
            setModalType('qr');
            setPaymentRequestModal(true);
          }}
        />

        <QuickActionsCard
          text="Request Payment"
          emoji="💰"
          handleClick={() => {
            setModalType('request');
            setPaymentRequestModal(true);
          }}
        />
        <QuickActionsCard
          text="Add Customer"
          emoji="🛍️"
          handleClick={() => navigate('/customer/add')}
        />
      </div>
    </div>
  );
};

export default QuickActions;
