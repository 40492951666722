export const getColor = (value: string | number) => {
  switch (value) {
    case 'pending':
      return {
        backgroundColor: 'rgba(255, 153, 31, 0.2)',
        color: 'rgba(255, 153, 31, 1)',
      };
    case 'expired':
      return {
        backgroundColor: 'rgba(189, 208, 205, 0.2)',
        color: 'rgb(108, 121, 119)',
      };
    case 'approved':
    case 'successfull':
    case 'successful':
    case 'credit':
      return {
        backgroundColor: 'rgba(0, 135, 90, 0.2)',
        color: 'rgba(0, 135, 90, 1)',
      };
    case 'declined':
    case 'failed':
    case 'debit':
      return {
        backgroundColor: 'rgba(222, 53, 11, 0.2)',
        color: 'rgba(222, 53, 11, 1)',
      };
    default:
      return {};
  }
};
