import React, { useEffect, useState } from 'react';
import Header from '../components/Pages/Header';
import Dropdown from '../components/Base/Dropdown';
import { useAuth } from '../context/AuthContext';
import AnalyticsCard from '../components/Dashboard/AnalyticsCard';
import QuickActions from '../components/Dashboard/QuickActions';
import TransactionsChart from '../components/Charts/TransactionsChart';
import { useDashboard } from '../context/DashboardContext';
import { filterByDate, formatTomorrow } from '../functions/formatDate';
import { IBranch, IDashboard, ITransaction } from '../interfaces/user';
import TableSkeleton from '../components/Base/TableSkeleton';
import TransactionsTable from '../components/Tables/TransactionsTable';
import { HistogramChart } from '../components/Charts/HistogramChart';
import DashboardSkeleton from '../components/Base/DashboardSkeleton';
import Modal from '../components/Base/Modal';
import FilterMonthModal from '../components/Dashboard/FilterMonthModal';

const Dashboard = () => {
  const { fetchDashboard, loading } = useDashboard();
  const { user } = useAuth();

  const [dashboardData, setDashboardData] = useState<IDashboard>();
  const [transactions, setTransactions] = useState<ITransaction[]>();
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [filteredValue, setFilteredValue] = useState('All');
  const currentdate = formatTomorrow();
  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
  });
  const fetchData = async () => {
    const { data } = await fetchDashboard(params);
    setTransactions(data.recentTransactions);
    setDashboardData(data);
    return data;
  };
  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setFilteredValue('All');
    }
    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      setFilteredValue(event);
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }
  };
  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <div className="w-full pb-20">
      <Header
        header={`Welcome Back, ${user?.firstname}`}
        subtext="Here is a brief report of what’s going on"
      />
      {loading ? (
        <DashboardSkeleton />
      ) : (
        dashboardData && (
          <div className="px-6 py-4 mt-7">
            <div className="flex justify-between">
              <h1 className="header">Analytics Overview</h1>
              <Dropdown
                options={[
                  'All',
                  'Today',
                  'This Week',
                  'Past Month',
                  'This Year',
                  'Custom',
                ]}
                text={filteredValue}
                title="Date"
                onChange={(val) => {
                  handleChange(val, 'date');
                }}
              />
            </div>
            <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-4 card">
              <AnalyticsCard
                title="Total Customers"
                numbers={dashboardData.totalCustomers}
                additions={`+${dashboardData.weeklytotalCustomers} this week`}
                color={'RGBA(255,153,31,0.4)'}
              />
              <AnalyticsCard
                title="Total Transactions"
                numbers={dashboardData.totalTransaction}
                additions={`+${dashboardData.weeklytotalTransaction} this week`}
                color={'RGBA(6,78,59,0.4)'}
              />
              <AnalyticsCard
                title="Total Revenue"
                numbers={dashboardData.totalRevenue}
                additions={`+${dashboardData.weeklytotalRevenue} this week`}
                color={'RGBA(185,28,28,0.4)'}
              />
              <AnalyticsCard
                title="Invoices Generated"
                numbers={dashboardData.totalInvoices}
                additions={`+${dashboardData.weeklytotalInvoices} this week`}
                color={'RGBA(9,6,107,0.4)'}
              />
            </div>
            <QuickActions />

            <div className="grid grid-cols-1 gap-10 xs:grid-cols-2 xl:grid-cols-4">
              <div className="row-span-2 mt-5 md:row-span-1 l card">
                <h5 className="header">Transactions</h5>
                <div className="w-full mt-6">
                  <TransactionsChart
                    categories={dashboardData.piechart.categories}
                    label={dashboardData.piechart.plots[0].name}
                    colors={dashboardData.piechart.plots[0].colors}
                    plots={dashboardData.piechart.plots[0].data}
                  />
                </div>
              </div>
              <div className="mt-5 xl:col-span-2 card ">
                <HistogramChart plots={dashboardData.histograph.data} />
              </div>
              <div className="mt-5 card">
                <p className="font-bold text-textcolor">Today’s Update</p>

                <div>
                  <div className="grid grid-cols-1 py-3 g xs:grid-cols-2 gap-x-2 gap-y-3">
                    <div className="p-4 bg-[#BCBBC1] rounded-lg bg-opacity-40">
                      <h1 className="font-bold smd:text-2xl text-primary">
                        {dashboardData.todayRegistered}
                      </h1>
                      <p className="text-[10px] xmd:text-xs break-words">
                        New Customers
                      </p>
                    </div>
                    <div className="p-4 bg-[#BCBBC1] rounded-lg bg-opacity-40">
                      <h1 className="font-bold smd:text-2xl text-primary">
                        {dashboardData.todayPaymentRequests}
                      </h1>
                      <p className="text-[10px] xmd:text-xs break-words">
                        Payment Requests
                      </p>
                    </div>
                    <div className="p-4 bg-[#BCBBC1] rounded-lg bg-opacity-40">
                      <h1 className="font-bold smd:text-2xl text-primary">
                        {dashboardData.todayTransactions}
                      </h1>
                      <p className="text-[10px] xmd:text-xs break-words">
                        Transactions perfomed
                      </p>
                    </div>
                    <div className="p-4 bg-[#BCBBC1] rounded-lg bg-opacity-40">
                      <h1 className="font-bold smd:text-2xl text-primary">
                        {dashboardData.todayDeposits}
                      </h1>
                      <p className="text-[10px] xmd:text-xs break-words">
                        Deposits
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
              <FilterMonthModal
                setIsOpen={() => setShowFilterMonthModal(false)}
                getStartDate={(val) => {
                  handleCustomDates(val, 'start_date');
                }}
                getEndDate={(val) => {
                  handleCustomDates(val, 'end_date');
                }}
              />
            </Modal>

            <div className="mt-8">
              {loading ? (
                <div className="py-10">
                  <TableSkeleton />
                </div>
              ) : transactions?.length ? (
                <TransactionsTable items={transactions} />
              ) : null}
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Dashboard;
