import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import AnalyticsDropdown from '../../components/Analytics/AnalyticsDropdown';
import AnalyticsTopBar from '../../components/Analytics/AnalyticsTopBar';
import AnalyticsBottomBar from '../../components/Analytics/AnalyticsBottomBar';
import AnalyticsMiddleBar from '../../components/Analytics/AnalyticsMiddleBar';
import { useDashboard } from '../../context/DashboardContext';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IAnalytics, IBranch } from '../../interfaces/user';
import DashboardSkeleton from '../../components/Base/DashboardSkeleton';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import Modal from '../../components/Base/Modal';

const Analytics = () => {
  const { fetchAnalytics, loading } = useDashboard();
  const [analyticsData, setAnalyticsData] = useState<IAnalytics>();
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [filteredValue, setFilteredValue] = useState('All');
  const currentdate = formatTomorrow();
  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
    users_start_date: '2023-03-01',
    users_end_date: currentdate,
    graph_start_date: '2023-03-01',
    graph_end_date: currentdate,
    revenue_start_date: '2023-03-01',
    revenue_end_date: currentdate,
    deposit_start_date: '2023-03-01',
    deposit_end_date: currentdate,
  });
  const fetchData = async () => {
    const { data } = await fetchAnalytics(params);
    setAnalyticsData(data);
  };

  const getDate = (argument1: string, argument2: string, event: string) => {
    const val = filterByDate(event);
    if (!val) return;
    setParams((prevObj) => ({
      ...prevObj,
      [argument1]: val.startDate,
      [argument2]: val.endDate,
    }));
  };

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setFilteredValue('All');
    }
    if (typeof event !== 'string') return;
    if (event === 'Custom') {
      setShowFilterMonthModal(true);
    }

    setFilteredValue(event);
    if (type === 'date') {
      getDate('start_date', 'end_date', event);
    }
    if (type === 'revenue') {
      getDate('revenue_start_date', 'revenue_end_date', event);
    }
    if (type === 'deposits') {
      getDate('deposit_start_date', 'deposit_end_date', event);
    }
    if (type === 'users') {
      getDate('users_start_date', 'users_end_date', event);
    }
    if (type === 'graph') {
      getDate('graph_start_date', 'graph_end_date', event);
    }
  };
  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);
  return (
    <>
      {loading ? (
        <DashboardSkeleton />
      ) : (
        <section className="pb-32 overflow-auto">
          <Header
            header="Analytics"
            subtext="Insights on the company’s improvement recently"
          />

          <div className="px-8 my-10 ">
            <div className="w-6/12 lg:w-4/12">
              <AnalyticsDropdown
                title="Timeframe"
                text={filteredValue}
                options={[
                  'All',
                  'Today',
                  'This Week',
                  'Past Month',
                  'This Year',
                  'Custom',
                ]}
                onChange={(val) => {
                  handleChange(val, 'date');
                }}
              />
            </div>

            {analyticsData && (
              <div className="mt-10">
                <AnalyticsTopBar
                  analyticsData={analyticsData}
                  handleChange={handleChange}
                />

                <div className="my-8">
                  <AnalyticsMiddleBar
                    handleChange={handleChange}
                    analyticsData={analyticsData}
                    filteredValue={filteredValue}
                  />
                </div>

                <div className="flex flex-col items-center gap-10 mt-5 md:flex-row">
                  <AnalyticsBottomBar
                    branchData={analyticsData.storePerformance.branchRevenue}
                  />
                  <AnalyticsBottomBar
                    branchData={
                      analyticsData.storePerformance.paymentReqPercent
                    }
                    header="Store performance ( payment requests )"
                    color="green"
                  />
                </div>
              </div>
            )}
          </div>
          <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
            <FilterMonthModal
              setIsOpen={() => setShowFilterMonthModal(false)}
              getStartDate={(val) => {
                handleCustomDates(val, 'start_date');
              }}
              getEndDate={(val) => {
                handleCustomDates(val, 'end_date');
              }}
            />
          </Modal>
        </section>
      )}
    </>
  );
};

export default Analytics;
