import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IPlotData } from '../../interfaces/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  plots: IPlotData;
}
export const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: 'DM Sans',
          size: 12,
          weight: '500',
        },
      },
      barThickness: 2, // Adjust the bar thickness here
    },
    y: {
      barThickness: 2, // Adjust the bar thickness here
      grid: {
        display: false,
      },
      ticks: {
        font: {
          family: 'DM Sans',
          size: 12,
          weight: '500',
        },
      },
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        boxWidth: 0,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      barThickness: 10,
      borderRadius: 10, // Adjust the border radius here
    },
  },
};

export const UserActivitiesChart = ({ plots }: Props) => {
  const modifiedData = {
    ...plots,
    datasets: plots.datasets.map((dataset) => ({
      ...dataset,
      borderWidth: 0,
      barThickness: 10,
      borderRadius: 10,
    })),
  };

  return <Bar options={options} data={modifiedData} />;
};
