import React from 'react';

interface Props {
  text: string;
  number: string | number;
}

const TotalBox = ({ text, number }: Props) => {
  return (
    <div className="p-4 rounded-lg bg-gray2 bg-opacity-20">
      <p className="text-center">{text}</p>
      <p className="font-bold text-center">{number}</p>
    </div>
  );
};

export default TotalBox;
