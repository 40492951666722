import React from 'react';
import Header from '../../components/Pages/Header';
import AddCustomerForm from '../../components/Customers/AddCustomerForm';

const AddCustomer = () => {
  return (
    <div className="w-full pb-32">
      <Header
        header="Customers"
        subtext="Fill in the form to add new customer"
        breadCrumb="Add new customer"
      />
      <div className="mt-10 lg:px-32">
        <div className="card">
          <div className="px-5 py-8 md:px-10">
            <h1 className="header">Add new customer</h1>
            <AddCustomerForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
