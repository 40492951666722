import React, { useState } from 'react';
import AnalyticsGraphCard from './AnalyticsGraphCard';
import { UserActivitiesChart } from '../Charts/UserActivitiesChart';
import WhiteDropdown from './WhiteDropdown';
import { IAnalytics } from '../../interfaces/user';

interface Props {
  analyticsData: IAnalytics;
  handleChange: (value: string, type: string) => void;
}

const AnalyticsTopBar = ({ analyticsData, handleChange }: Props) => {
  const [filteredValue, setFilteredValue] = useState('3 months');
  return (
    <>
      <div className="grid grid-cols-3 gap-4 xl:gap-6 xl:grid-cols-6">
        <AnalyticsGraphCard
          text="Active users"
          number={analyticsData.sixBoxes.dailyUserIncrease}
          data={analyticsData.sixBoxes.activeUsersArray}
        />
        <AnalyticsGraphCard
          text="Transactions"
          number={analyticsData.sixBoxes.dailyTransactionIncrease}
          data={analyticsData.sixBoxes.transactionsArray}
        />
        <AnalyticsGraphCard
          text="Payment requests"
          number={analyticsData.sixBoxes.dailyPaymentRequestIncrease}
          data={analyticsData.sixBoxes.paymentRequestArray}
        />

        <div className="col-span-3 row-span-2">
          <div className="bg-white rounded-xl">
            <div className="px-5 py-1 border-b fullflex">
              <p className="text-[#4D4D4D] font-medium">Users monthly</p>
              <WhiteDropdown
                options={[
                  'All',
                  'This Week',
                  'Past Month',
                  '2 Months',
                  '3 Months',
                  '6 Months',
                  'Custom',
                ]}
                onChange={(val) => {
                  handleChange(val, 'users');
                  setFilteredValue(val);
                }}
                text={filteredValue}
              />
            </div>
            <div className="w-full p-6">
              <UserActivitiesChart
                plots={
                  analyticsData.usersMonthlyHistograph.customerHistograph.data
                }
              />
            </div>
          </div>
        </div>
        <AnalyticsGraphCard
          text="Total staff"
          number={analyticsData.sixBoxes.dailyStaffIncrease}
          data={analyticsData.sixBoxes.staffArray}
        />
        <AnalyticsGraphCard
          text="Promotions"
          number={analyticsData.sixBoxes.dailyPromotionIncrease}
          data={analyticsData.sixBoxes.promotionArray}
        />
        <AnalyticsGraphCard
          text="Discount coupons"
          number={analyticsData.sixBoxes.dailyCouponIncrease}
          data={analyticsData.sixBoxes.couponArray}
        />
      </div>
    </>
  );
};

export default AnalyticsTopBar;
