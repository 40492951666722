import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../assets/icons/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface Props {
  title: string;
  subRoutes: {
    title: string;
    icon: JSX.Element;
    path: string;
    hideStaff?: boolean;
  }[];
}

const SubNavigation = ({ title, subRoutes }: Props) => {
  const [visiblePaths, setVisiblePaths] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { user } = useAuth();
  return (
    <>
      <div
        className="my-2 cursor-pointer fullflex "
        onClick={() => setVisiblePaths(!visiblePaths)}
      >
        <p className="text-sm uppercase text-textcolor">{title}</p>
        <span>
          {visiblePaths ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </span>
      </div>

      {visiblePaths && (
        <div>
          {subRoutes.map((route, index) =>
            user?.role === 'Sales' && route.hideStaff ? null : (
              <Link
                to={route.path}
                key={index}
                className={`px-3  ${
                  pathname === route.path
                    ? 'bg-textcolor bg-opacity-10 rounded-md'
                    : ''
                }  flex items-center py-2 gap-x-3`}
              >
                {route.icon}
                <p className="medparagraph text-black100">{route.title}</p>
              </Link>
            )
          )}
        </div>
      )}
    </>
  );
};

export default SubNavigation;
