import React from 'react';

interface Props {
  title: string;
  numbers: string | number;
  additions?: string;
  red?: boolean;
  isCustomerPage?: boolean;
}
const GeneralCard = ({
  isCustomerPage,
  red,
  title,
  numbers,
  additions,
}: Props) => {
  return (
    <div
      className={`flex flex-col   text-center ${
        isCustomerPage
          ? 'bg-gray2 bg-opacity-20 gap-2 rounded-md py-2'
          : 'bg-white card p-4 gap-3'
      }  `}
    >
      <p className="">{title}</p>
      <h5 className="text-xl font-bold">{numbers}</h5>

      <p className={`text-xs ${red ? 'text-red-800' : 'text-green-800'}`}>
        {additions}
      </p>
    </div>
  );
};

export default GeneralCard;
