interface Props {
  label?: string;
  value?: string;
}
const PreviewInput = ({ label, value, ...props }: Props) => {
  return (
    <div className="relative w-full">
      <div className="flex flex-col space-y-2.5">
        {<label className="text-xs text-left">{label}</label>}
        <p className="w-full px-4 py-3 mb-4 text-sm text-center capitalize rounded bg-opacity-20 bg-gray2 text-dark">
          {value}
        </p>
      </div>
    </div>
  );
};

export default PreviewInput;
