import React from 'react';
import AnalyticsProgressBar from './AnalyticsProgressBar';
import { IBranchAnalytics } from '../../interfaces/user';

interface Props {
  header?: string;
  componentHeader?: string;
  image?: string;
  color?: string;
  branchData: IBranchAnalytics[];
}

const AnalyticsBottomBar = ({
  header = '      Store performance ( revenue )',
  componentHeader,
  image,
  color = 'orange',
  branchData,
}: Props) => {
  const sort = branchData.sort((a, b) => b.percentage - a.percentage);
  return (
    <div className="w-full p-3 bg-white md:w-6/12 rounded-xl">
      <h2 className="font-medium text-[17px] text-dark text-opacity-70">
        {header}
      </h2>

      <div className="flex flex-col gap-5 mt-5">
        {sort &&
          sort.map((branch) => (
            <AnalyticsProgressBar
              key={branch.branchId}
              color={color}
              componentHeader={branch.branchName}
              image={branch.branchImage}
              percentage={branch.percentage}
            />
          ))}
      </div>
    </div>
  );
};

export default AnalyticsBottomBar;
