import React from 'react';
import logo from './../.././assets/images/logo.svg';
import Input from '../../components/Base/Input';
import Button from '../../components/Base/Button';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Field, Form, Formik } from 'formik';
import { loginValidationSchema } from '../../interfaces/forms';
import InputValidationError from '../../components/Base/InputValidationError';
// import { useAlert } from '../../context/AlertContext';

const Login = () => {
  const { loginUser } = useAuth();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={loginValidationSchema}
      onSubmit={async ({ email, password }, { setSubmitting }) => {
        window.localStorage.setItem('tempEmail', email);
        try {
          setSubmitting(true);
          await loginUser(email, password);
          setSubmitting(false);
        } catch (err) {
          setSubmitting(false);
          console.log(err);
        }
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form>
          <div className="flex flex-col items-center justify-center py-8 mx-3 bg-white rounded-lg sm:mx-0 sm:px-10 sm:border border-gray1">
            <div>
              <div>
                <img src={logo} alt="" />
              </div>
            </div>
            <h2 className="pt-5 mb-3 lg:text-[22px] font-semibold">
              Welcome Back
            </h2>
            <p className="w-11/12 pb-10 text-center text-textcolor">
              Hi, enter your account details to login into your staff account
            </p>

            <div className="flex flex-col w-full space-y-8">
              <div>
                <Field
                  name="email"
                  type="email"
                  as={Input}
                  label="Email Address"
                  placeholder="example@mail.com"
                />
                {errors.email && touched.email ? (
                  <InputValidationError text={errors.email} />
                ) : null}
              </div>
              <div>
                <Field
                  as={Input}
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="**********"
                  isPassword
                />
                {errors.password && touched.password ? (
                  <InputValidationError text={errors.password} />
                ) : null}
                <p className="pt-1 font-medium text-right cursor-pointer text-primary">
                  <Link to={'/auth/reset-password'}>Forgot password?</Link>
                </p>
              </div>
              <Button
                type="submit"
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
              >
                {isSubmitting ? 'Logging in...' : 'Login'}
              </Button>
            </div>

            <p className="pt-10 text-textcolor">
              Need help logging in?
              <Link to={'/auth/signup'} className="cursor-pointer text-primary">
                Click here
              </Link>
            </p>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
