import { useEffect, useState } from 'react';
import { EmptyStateIcon } from '../../assets/icons/icons';
import Dropdown from '../../components/Base/Dropdown';
import SearchInput from '../../components/Base/SearchInput';
import Modal from '../../components/Base/Modal';
import PaymentRequestModal from '../../components/PaymentRequests/PaymentRequestModal';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { formatTomorrow } from '../../functions/formatDate';
import { IBranch, IPaymentRequest } from '../../interfaces/user';
import PaymentRequestsTable from '../../components/PaymentRequests/PaymentRequestsTable';
import { useDebouncedValue } from '@mantine/hooks';
import TableSkeleton from '../../components/Base/TableSkeleton';
import { useDashboard } from '../../context/DashboardContext';
import TotalBox from './TotalBox';
import Pagination from '../Base/Pagination';

interface Props {
  userId: string;
}
const CustomerPaymentRequestsTab = ({ userId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useAuth();
  const { usersReload } = useDashboard();
  const currentdate = formatTomorrow();
  const [searchValue, setSearchValue] = useState('');
  const [paymentRequests, setPaymentRequests] = useState<IPaymentRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [meta, setMeta] = useState({
    successfulReq: 0,
    totalReq: 0,
    failedReq: 0,
  });

  const [params, setParams] = useState({
    search_term: '',
    start_date: '2023-03-01',
    end_date: currentdate,
    userId: userId,
    page_size: 10,
    type: 0,
  });
  const fetchPaymentRequest = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('/payment-request/user', {
        headers,
        params,
      });
      setLastPage(data.meta?.lastPage);
      setCurrentPage(data.meta.currentPage);

      setMeta((prev) => ({
        ...prev,
        totalReq: data.totalReq,
        successfulReq: data.successfulReq,
        failedReq: data.failedReq,
      }));
      setLoading(false);
      setPaymentRequests(data.data);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChange = (
    event: string | number | IBranch,
    type: string = 'date'
  ) => {
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };

  useEffect(() => {
    fetchPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);

  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  return (
    <section className="w-full pb-20">
      <Modal width="w-[30rem]" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PaymentRequestModal setIsOpen={() => setIsOpen(false)} />
      </Modal>

      <div className="px-2">
        <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3 ">
          <TotalBox text={'Total Requests'} number={meta.totalReq} />
          <TotalBox text={'Successful Requests'} number={meta.successfulReq} />
          <TotalBox text={'Failed Requests'} number={meta.failedReq} />
        </div>

        <div className="mt-10 card">
          <div className="flex flex-col justify-between gap-3 sm:flex-row">
            <div className="sm:w-3/6 xmd:w-2/6">
              <SearchInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search..."
              />
            </div>
            <Dropdown
              options={['5', '10', '20', '30', '50', '70', '100', '120']}
              text="10"
              title="Table Size"
              onChange={(val) => {
                handleChange(val, 'size');
              }}
            />
          </div>

          <div className="mt-8">
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : paymentRequests.length ? (
              <PaymentRequestsTable items={paymentRequests} />
            ) : (
              <div className="grid gap-2 mx-auto place-items-center">
                <EmptyStateIcon />
                <p>No Payment Requests </p>
              </div>
            )}
          </div>

          <Pagination
            handleMoreClicks={(val) => handleMoreClicks(val)}
            lastPage={lastPage}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </section>
  );
};

export default CustomerPaymentRequestsTab;
