import React, { useEffect, useRef, useState } from 'react';
import { FileUploadIcon, XMarkIcon } from '../../assets/icons/icons';

interface Props {
  setFile: (file: any) => void;
  getImage?: (file: string) => void;
  text?: string;
  file?: any;
  small?: boolean;
}

const FileUploadView = ({
  small,
  file,
  setFile,
  text = 'Profile Picture',
  getImage,
}: Props) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const dragZoneRef = useRef<HTMLDivElement | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [image, setImage] = useState('');
  const handleDrag = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    type: string;
  }) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files === null) return;
    const file = e.dataTransfer.files[0];
    setFile(file);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(e.dataTransfer.files, 'drag');
    }
  };

  const getInputRef = () => {
    if (!inputFileRef.current) return;
    inputFileRef.current.click();
  };
  const onChange = (event: any, type = '') => {
    let url = '';
    let file = [];
    if (type === 'drag') {
      file = event[0];
    } else {
      if (event.target.files === null) return;
      file = event.target.files[0];
    }

    if (file) {
      url = URL.createObjectURL(file);
      setImage(url);
      getImage && getImage(url);
      setFile(file);
    }
  };

  const handleDeleteImage = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setImage('');
  };

  useEffect(() => {
    if (file) {
      setImage(file);
    }
  }, [file]);

  return (
    <>
      <p
        className={`pb-2 font-medium text-left ${
          small ? 'lg:ml-2' : ' lg:ml-40'
        }  text-textcolor`}
      >
        {text}
      </p>
      <div
        className="flex flex-col items-center w-full"
        ref={dragZoneRef}
        onDragEnter={handleDrag}
      >
        <div
          onClick={getInputRef}
          className={`flex items-center justify-center ${
            small ? 'w-11/12' : ' md:w-4/6 w-9/12'
          } h-48 overflow-hidden border border-dashed rounded-lg cursor-pointer md:rounded-full bg-textcolor bg-opacity-5`}
        >
          {image ? (
            <div className="flex items-center justify-center gap-10 ">
              <div className="">
                <img src={image} alt="" className="object-cover h-auto" />
              </div>

              <div onClick={handleDeleteImage}>
                <XMarkIcon color="red" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <div className="flex items-center justify-center w-14 h-14 bg-textcolor bg-opacity-10 rounded-2xl">
                <FileUploadIcon />
              </div>
              <p className="font-bold text-center text-textcolor">
                Drag and Drop or <span className="text-primary"> Browse </span>
                to upload
              </p>
            </div>
          )}
        </div>
        {dragActive && (
          <div
            className="absolute inset-0 w-full h-full"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
        <input
          hidden
          ref={inputFileRef}
          className="w-full "
          id="file_input"
          type="file"
          onChange={(event) => {
            onChange(event);
          }}
          accept="image/*"
        />
      </div>
    </>
  );
};

export default FileUploadView;
