import React, { createRef, useState } from 'react';
import { ThreeDotsIcon } from '../../assets/icons/icons';
import useOutsideClick from '../../hooks/useOutsideClicks';
import DropdownItems from '../Base/DropdownItems';
import { IUser } from '../../interfaces/user';
import { formatDate } from '../../functions/formatDate';
import { useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import Modal from '../Base/Modal';
import ConfirmationModal from '../Base/ConfirmationModal';
import useDisableUser from '../../hooks/useDisableUser';
import EditCustomersModal from '../Customers/EditCustomersModal';

interface Props {
  items: IUser[];
}
const CustomerTable = ({ items }: Props) => {
  const navigate = useNavigate();

  const [userObj, setUserObj] = useState<IUser>();
  const [showDropdown, setShowDropdown] = useState(0);
  const dropdownItemsRef = createRef<HTMLDivElement>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(0));
  const [changeStatus, setChangeStatus] = useState<'Deactivate' | 'Activate'>(
    'Deactivate'
  );

  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: changeStatus,
    id: userObj?.id ? userObj.id : 0,
    isCustomer: true,
  });

  const handleDropdownOption = (option: string) => {
    if (option === 'View') {
      navigate(`/customers/${showDropdown}`);
    }
    if (option === 'Edit Info') {
      setEditModal(true);
    }
    if (option === 'Deactivate' || option === 'Activate') {
      setConfirmModal(true);
      setChangeStatus(option);
    }
  };

  const handleDisableUser = () => {
    disableUser();
    setConfirmModal(false);
  };
  return (
    <div className="relative mb-5 overflow-x-auto bg-white">
      <table className="w-full mb-10 text-sm text-left">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl whitespace-nowrap">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              customer’s nAME
            </th>
            <th scope="col" className="px-6 py-3">
              ACCT NUMBER
            </th>
            <th scope="col" className="px-6 py-3">
              ACCT name
            </th>
            <th scope="col" className="px-6 py-3">
              email address
            </th>
            <th scope="col" className="px-6 py-3">
              REG.DATE
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3">
              ACTIONs
            </th>
          </tr>
        </thead>

        {items.length ? (
          <tbody className="text-sm">
            {items.map((item, index) => (
              <tr className=" whitespace-nowrap" key={item.id}>
                <td className="px-6 py-4">{index + 1}</td>
                <th scope="row" className="px-6 py-4 font-normal">
                  {item.firstname + ' ' + item.lastname}
                </th>
                <td className="px-6 py-4 capitalize">
                  {item.accountNumber ? item.accountNumber : 'Nill'}
                </td>
                <td className="px-6 py-4 capitalize">
                  {item.accountName ? item.accountName : 'Nill'}
                </td>
                <td className="px-6 py-4">{item.email}</td>
                <td className="px-6 py-4">{formatDate(item.createdAt)}</td>
                <td className="px-6 py-4 ">
                  <span
                    className={`status  ${
                      item.Status ? 'active' : 'inactive'
                    } `}
                  >
                    {item.Status ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td className="px-4 py-4 cursor-pointer">
                  <div
                    className="flex items-center justify-center w-full h-8 "
                    onClick={(e) => {
                      e.stopPropagation();
                      setUserObj(item);
                      setShowDropdown(item.id);
                    }}
                  >
                    <ThreeDotsIcon />
                  </div>
                </td>

                <td>
                  <Transition
                    show={showDropdown === item.id}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    ref={dropdownItemsRef}
                  >
                    <DropdownItems
                      isOpen={showDropdown === item.id}
                      options={[
                        'View',
                        'Edit Info',
                        item.Status ? 'Deactivate' : 'Activate',
                      ]}
                      onChange={(option) => handleDropdownOption(option)}
                      onSelected={() => setShowDropdown(0)}
                    />
                  </Transition>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="flex justify-center w-full py-10">
            <tr>
              <td className="whitespace-nowrap w-100">No Customers</td>
            </tr>
          </tbody>
        )}
      </table>
      <Modal
        width="w-[30rem]"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
      >
        {userObj && (
          <ConfirmationModal
            closeModal={() => setConfirmModal(false)}
            name={userObj?.firstname + ' ' + userObj.lastname}
            type="customer"
            confirmAction={handleDisableUser}
            submitting={isSubmitting}
            status={changeStatus}
          />
        )}{' '}
      </Modal>
      <Modal
        width="w-[40rem]"
        isOpen={editModal}
        onClose={() => setEditModal(false)}
      >
        {userObj && (
          <EditCustomersModal
            userObj={userObj}
            setIsOpen={() => setEditModal(false)}
          />
        )}{' '}
      </Modal>
    </div>
  );
};

export default CustomerTable;
