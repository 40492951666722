import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import { EmptyStateIcon, PlusIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import Dropdown from '../../components/Base/Dropdown';
import SearchInput from '../../components/Base/SearchInput';
import GeneralCard from '../../components/GeneralCard';
import Modal from '../../components/Base/Modal';
import PaymentRequestModal from '../../components/PaymentRequests/PaymentRequestModal';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IBranch, IPaymentRequest } from '../../interfaces/user';
import PaymentRequestsTable from '../../components/PaymentRequests/PaymentRequestsTable';
import { useDebouncedValue } from '@mantine/hooks';
import TableSkeleton from '../../components/Base/TableSkeleton';
import { useDashboard } from '../../context/DashboardContext';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import Pagination from '../../components/Base/Pagination';
import useBranches from '../../hooks/useBranches';
import { formatNumberWithCommas } from '../../functions/formatNumber';
import downloadExcel from '../../functions/downloadExcel';
const PaymentRequests = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { token } = useAuth();
  const { usersReload } = useDashboard();
  const currentdate = formatTomorrow();
  const branches = useBranches();
  const [lastPage, setLastPage] = useState(1);
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [paymentRequests, setPaymentRequests] = useState<IPaymentRequest[]>([]);
  const [filteredValue, setFilteredValue] = useState('All');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [meta, setMeta] = useState({
    successfulReq: 0,
    thisWeekFailed: 0,
    thisWeekSuccessful: 0,
    thisWeekTotal: 0,
    totalReq: 0,
    failedReq: 0,
    totalSum: 0,
    weeklytotalSum: 0,
  });

  const [params, setParams] = useState({
    search_term: '',
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 10,
    status: '',
    branch: null,
  });

  const fetchPaymentRequest = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('/payment-request/all', {
        headers,
        params,
      });
      setCurrentPage(data.meta.currentPage);
      setLastPage(data.meta?.lastPage);
      setMeta((prev) => ({
        ...prev,
        successfulReq: data.successfulReq,
        thisWeekFailed: data.thisWeekFailed,
        thisWeekSuccessful: data.thisWeekSuccessful,
        totalReq: data.totalReq,
        thisWeekTotal: data.thisWeekTotal,
        failedReq: data.failedReq,
        weeklytotalSum: data.weeklytotalSum,
        totalSum: data.totalSum,
      }));
      setLoading(false);
      setPaymentRequests(data.data);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setParams((prevObj) => ({
        ...prevObj,
        branch: null as any,
        status: '',
      }));
      setFilteredValue('All');
    }
    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }

    if (type === 'branch') {
      if (typeof event === 'string') return;
      setParams((prevObj) => ({
        ...prevObj,
        branch: event.id as any,
      }));
      setFilteredValue(event.name);
    }

    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
    if (event === 'All') {
      setFilteredValue('All');
    } else {
      if (typeof event === 'string') {
        setParams((prevObj) => ({
          ...prevObj,
          status: event.toLowerCase(),
        }));
        setFilteredValue(event);
      }
    }
  };

  useEffect(() => {
    fetchPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);

  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  const handleDownload = () => {
    downloadExcel(paymentRequests, 'Payment Requests');
  };
  return (
    <section className="w-full pb-20">
      <Header
        header="Payment Requests"
        subtext="Monitor and Manage Payment Requests"
      >
        <div className="flex flex-wrap items-center gap-3 md:gap-10 lg:flex-nowrap">
          <Dropdown
            options={['All', ...branches]}
            text="All"
            title="BRANCH"
            onChange={(val) => {
              handleChange(val, 'branch');
            }}
          />
          <Dropdown
            options={[
              'All',
              'Today',
              'This Week',
              'Past Month',
              'This Year',
              'Custom',
            ]}
            text="All"
            title="Date"
            onChange={(val) => {
              handleChange(val, 'date');
            }}
          />

          <Dropdown
            options={['All', 'Approved', 'Pending', 'Declined', 'Expired']}
            text="All"
            title="Sort By"
            onChange={(val) => {
              handleChange(val, 'type');
            }}
          />
          <Button icon={<PlusIcon />} onClick={() => setIsOpen(!isOpen)}>
            Send Payment Request
          </Button>
          <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
            <Button variant="outline" onClick={handleDownload}>
              Download CSV
            </Button>
          </div>
        </div>
      </Header>

      <Modal width="w-[30rem]" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PaymentRequestModal setIsOpen={() => setIsOpen(false)} />
      </Modal>

      <div className="px-8">
        <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-4 ">
          <GeneralCard
            title={'Total Requests'}
            numbers={` ${meta.totalReq}`}
            additions={`+ ${meta.thisWeekTotal} this week`}
          />
          <GeneralCard
            title={'Total Failed Requests'}
            numbers={` ${meta.failedReq}`}
            additions={`+ ${meta.thisWeekFailed} this week`}
            red
          />
          <GeneralCard
            title={'Total successful Requests'}
            numbers={` ${meta.successfulReq}`}
            additions={`+ ${meta.thisWeekSuccessful} this week`}
          />
          <GeneralCard
            title={'Amount'}
            numbers={'₦' + formatNumberWithCommas(meta.totalSum)}
            red
            additions={`+${meta.weeklytotalSum} this week`}
          />
        </div>

        <div className="mt-10 card">
          <div className="flex flex-col justify-between gap-3 sm:flex-row">
            <div className="sm:w-3/6 xmd:w-2/6">
              <SearchInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search..."
              />
            </div>
            <Dropdown
              options={['5', '10', '20', '30', '50', '70', '100', '120']}
              text="10"
              title="Table Size"
              onChange={(val) => {
                handleChange(val, 'size');
              }}
            />
          </div>
          <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
            <FilterMonthModal
              setIsOpen={() => setShowFilterMonthModal(false)}
              getStartDate={(val) => {
                handleCustomDates(val, 'start_date');
              }}
              getEndDate={(val) => {
                handleCustomDates(val, 'end_date');
              }}
            />
          </Modal>
          <div className="mt-8">
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : paymentRequests.length > 0 ? (
              <PaymentRequestsTable items={paymentRequests} />
            ) : (
              <div className="grid gap-2 mx-auto place-items-center">
                <EmptyStateIcon />
                <p>No {filteredValue} Requests</p>
              </div>
            )}
          </div>

          <Pagination
            handleMoreClicks={(val) => handleMoreClicks(val)}
            lastPage={lastPage}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </section>
  );
};

export default PaymentRequests;
