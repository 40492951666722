import React, { useEffect, useMemo, useState } from 'react';
import Header from '../../components/Pages/Header';
import Dropdown from '../../components/Base/Dropdown';
import Button from '../../components/Base/Button';
import { EmptyStateIcon, PlusIcon } from '../../assets/icons/icons';
import GeneralCard from '../../components/GeneralCard';
import SearchInput from '../../components/Base/SearchInput';
import { useNavigate } from 'react-router-dom';
import { useDashboard } from '../../context/DashboardContext';
import StaffTable from '../../components/Tables/StaffTable';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IBranch, IUser } from '../../interfaces/user';
import useBranches from '../../hooks/useBranches';
import { useAuth } from '../../context/AuthContext';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import Modal from '../../components/Base/Modal';
import TableSkeleton from '../../components/Base/TableSkeleton';
import { useDebouncedValue } from '@mantine/hooks';
import Pagination from '../../components/Base/Pagination';
import downloadExcel from '../../functions/downloadExcel';
const Staffs = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { fetchStaffs, loading, usersReload } = useDashboard();
  const [items, setItems] = useState<IUser[]>([]);
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [filteredValue, setFilteredValue] = useState('All');
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const currentdate = formatTomorrow();
  const branches = useBranches();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [params, setParams] = useState({
    search_term: '',
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 0,
    page_number: 0,
    type: null,
    branch: null,
  });
  const [meta, setMeta] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    perPage: 10,
    next: 0,
    prev: 0,
    currentPage: 1,
    lastPage: 2,
  });
  const goToAddPage = () => {
    navigate('/staff/add');
  };

  const fetchData = async () => {
    const data = await fetchStaffs(params);
    setMeta((obj) => ({
      ...obj,
      total: data.meta?.total,
      active: data?.activeStaffs,
      inactive: data?.inActiveStaffs,
      currentPage: data.meta?.currentPage,
      perPage: data.meta?.perPage,
      next: data.meta?.next,
      prev: data.meta?.prev,
      lastPage: data.meta?.lastPage,
    }));

    setCurrentPage(data.meta.currentPage);
    setLastPage(data.meta?.lastPage);
    setItems(data.data);
  };

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setParams((prevObj) => ({
        ...prevObj,
        branch: null,
        type: null,
      }));
      setFilteredValue('All');
    }
    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }

    if (type === 'type') {
      if (event === 'Active') {
        setParams((prevObj) => ({
          ...prevObj,
          type: 1 as any,
        }));
      }
      if (event === 'Inactive') {
        setParams((prevObj) => ({
          ...prevObj,
          type: 0 as any,
        }));
      }
      if (event === 'All') setFilteredValue('All');
    }
    if (type === 'branch') {
      if (typeof event === 'string') return;

      setParams((prevObj) => ({
        ...prevObj,
        branch: event.id as any,
      }));
    }
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };

  const filteredData = useMemo(() => {
    return items.filter((item) => {
      if (filteredValue === 'All') return item;
      if (filteredValue === 'Inactive') return item.status === false;
      if (filteredValue === 'Active') return item.status === true;
      else {
        return item.branch?.name
          .toLowerCase()
          .includes(filteredValue.toLowerCase());
      }
    });
  }, [items, filteredValue]);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);

  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };

  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };

  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handleDownload = () => {
    downloadExcel(items, 'staffs');
  };
  return (
    <>
      {items && (
        <section className="w-full pb-32">
          <Header header="Staffs" subtext="Monitor and Manage staffs here">
            <div className="flex flex-wrap items-center gap-3 lg:gap-10 lg:flex-nowrap">
              <Dropdown
                options={['All', ...branches]}
                text="All"
                title="BRANCH"
                onChange={(val) => {
                  handleChange(val, 'branch');
                }}
              />
              <Dropdown
                options={[
                  'All',
                  'Today',
                  'This Week',
                  'Past Month',
                  'This Year',
                  'Custom',
                ]}
                text="All"
                title="Date"
                onChange={(val) => {
                  handleChange(val, 'date');
                }}
              />
              <Dropdown
                options={['All', 'Active', 'Inactive']}
                text="All"
                title="Type"
                onChange={(val) => {
                  handleChange(val, 'type');
                }}
              />

              {user?.role === 'Super' && (
                <Button icon={<PlusIcon />} onClick={goToAddPage}>
                  Add New Staff
                </Button>
              )}
              <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
                <Button variant="outline" onClick={handleDownload}>
                  Download CSV
                </Button>
              </div>
            </div>
          </Header>

          <div className="px-8">
            <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3 ">
              <GeneralCard
                title={'Total Staffs'}
                numbers={meta?.total ? meta?.total : 'Nill'}
                // additions={'+5 this week'}
              />
              <GeneralCard
                title={'Total Active Staffs'}
                numbers={meta.active ? meta?.active : 'Nill'}
                // additions={'+5 this week'}
              />
              <GeneralCard
                title={'Total Inactive Staffs'}
                numbers={meta.inactive ? meta?.inactive : 'Nill'}
                // additions={'+5 this week'}
              />
            </div>

            <div className="mt-10 card">
              <div className="flex flex-col justify-between gap-3 sm:flex-row">
                <div className="xmd:w-2/6">
                  <SearchInput
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="Search Staffs..."
                  />
                </div>
                <Dropdown
                  options={['5', '10', '20', '30', '50', '70', '100', '120']}
                  text="10"
                  title="Table Size"
                  onChange={(val) => {
                    handleChange(val, 'size');
                  }}
                />
              </div>

              <div className="mt-8">
                {loading ? (
                  <div className="py-10">
                    <TableSkeleton />
                  </div>
                ) : filteredData.length > 0 ? (
                  <StaffTable
                    branches={branches}
                    items={filteredData}
                    currentPage={meta.currentPage}
                    itemsPerPage={meta.perPage}
                  />
                ) : (
                  <div className="grid gap-2 mx-auto place-items-center">
                    <EmptyStateIcon />
                    <p>No Staff</p>
                    <div className="">
                      <Button icon={<PlusIcon />} onClick={goToAddPage}>
                        Add New Staff
                      </Button>
                    </div>
                  </div>
                )}

                <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
                  <FilterMonthModal
                    setIsOpen={() => setShowFilterMonthModal(false)}
                    getStartDate={(val) => {
                      handleCustomDates(val, 'start_date');
                    }}
                    getEndDate={(val) => {
                      handleCustomDates(val, 'end_date');
                    }}
                  />
                </Modal>

                <Pagination
                  handleMoreClicks={(val) => handleMoreClicks(val)}
                  lastPage={lastPage}
                  handlePageClick={handlePageClick}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Staffs;
