import React, { useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import DatePicker from '../Base/DatePicker';
import Button from '../Base/Button';
interface Props {
  setIsOpen: () => void;
  getStartDate: (date: string) => void;
  getEndDate: (date: string) => void;
  blockPrevDate?: boolean;
}

const FilterMonthModal = ({
  blockPrevDate,
  setIsOpen,
  getStartDate,
  getEndDate,
}: Props) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const handleClick = () => {
    getStartDate(startDate);
    getEndDate(endDate);
    setIsOpen();
  };
  return (
    <div className="gap-20 mx-auto bg-white h-fit ">
      <div className="p-3">
        <div className="flex justify-end">
          <span
            onClick={setIsOpen}
            className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
          >
            <XMarkIcon />
          </span>
        </div>
        <div className="flex justify-center">
          <h1 className="text-center header">Set Custom Dates</h1>
        </div>
        <div className="flex flex-col w-full gap-10 px-8 my-8">
          <DatePicker
            onChange={(val) => setStartDate(val)}
            label="Start date"
          />
          <DatePicker onChange={(val) => setEndDate(val)} label="End date" />

          <div className="flex justify-center mt-5">
            <Button onClick={handleClick}> Sort Preference</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMonthModal;
