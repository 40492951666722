import { Checkbox, createStyles } from '@mantine/core';

interface Props {
  handleChange: (value: boolean) => void;
  label?: string;
  name?: string;
  value?: string;
  checkValue?: boolean;
}
const CheckBox = ({ value, handleChange, label, name, checkValue }: Props) => {
  const useStyles = createStyles(() => ({
    input: {
      ':checked': {
        backgroundColor: '#09066B',
        border: 'none',
      },
    },
    label: {
      fontSize: '12px',
    },
  }));
  const { classes } = useStyles();
  return (
    <>
      <Checkbox
        label={label}
        value={value}
        classNames={{
          input: classes.input,
          label: classes.label,
        }}
        name={name}
        checked={checkValue}
        onChange={(val) => {
          handleChange(val.target.checked);
        }}
      />
    </>
  );
};

export default CheckBox;
