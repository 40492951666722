import React from 'react';
import Button from './Button';

interface Props {
  name: string;
  closeModal: () => void;
  confirmAction?: () => void;
  type?:
    | 'staff'
    | 'customer'
    | 'promotion'
    | 'discount'
    | 'transaction'
    | 'notification'
    | 'branch';
  submitting?: boolean;
  status?: string;
}

const ConfirmationModal = ({
  type = 'staff',
  name,
  closeModal,
  confirmAction,
  submitting,
  status = 'Deactivate',
}: Props) => {
  return (
    <div className="h-auto gap-10 mx-auto bg-white rounded-xl ">
      <div className="p-10">
        <h1 className="text-lg font-bold text-center capitalize text-dark text-opacity-60">
          {type === 'promotion'
            ? `${status} promotion`
            : `${status + ' ' + type}`}
        </h1>
        <p className="px-10 pt-5 text-base text-center">
          Are you sure you want to {status}
          <span className="pl-1 font-bold">{name} </span> {type}{' '}
          {type !== 'promotion' && type !== 'notification' && 'account?'}
        </p>
      </div>
      <div className="bg-[#F7F5F4] rounded-b-xl  p-4 flex justify-center gap-3 items-end">
        <div className="flex items-center gap-4">
          <Button
            isSubmitting={submitting}
            disabled={submitting}
            onClick={confirmAction}
          >
            {submitting ? `${status}....` : status}
          </Button>
          <p
            onClick={closeModal}
            className="font-bold cursor-pointer text-primary"
          >
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
