import axios from 'axios';
import { toast } from 'react-toastify';
const userAgent = navigator.userAgent;
let os = userAgent;

// if (userAgent.indexOf('Win') !== -1) {
//   os = 'Windows';
// } else if (userAgent.indexOf('Mac') !== -1) {
//   os = 'Macbook';
// } else if (userAgent.indexOf('Linux') !== -1) {
//   os = 'Linux';
// } else if (userAgent.indexOf('Android') !== -1) {
//   os = 'Android';
// } else if (userAgent.indexOf('iOS') !== -1) {
//   os = 'iOS';
// } else {
//   os = 'Unknown';
// }

const token = window.localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    DeviceId: os,
    DeviceName: os,
    Authorization: `Bearer ${token}`,
  },

  withCredentials: false,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      toast.error(`${error.response.data.message}`);
    }
    if (error.response && error.response.status === 401) {
      toast.error('You are unauthorized, try logging in again');

      localStorage.removeItem('token');
      localStorage.removeItem('efgauth');
      window.location.href = `/auth/login`;
    }

    if (error.response.status === 403) {
      setTimeout(() => {
        window.location.href = `/auth/verification`;
      }, 2000);
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
