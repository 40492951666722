import React, { useState } from 'react';
import { EyeClosedIcon, EyeIcon } from '../../assets/icons/icons';

interface Props {
  label?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  type?: string;
  required?: boolean;
  isPassword?: boolean;
  value?: string;
  isCoupon?: boolean;
  handleCodeChange?: () => void;
}
const Input = ({
  required,
  type = 'text',
  label,
  placeholder,
  onChange,
  isPassword,
  value,
  isCoupon,
  handleCodeChange,
  ...props
}: Props) => {
  const [newType, setNewType] = useState(type);
  const togglePasswordVisibility = () =>
    newType === 'password' ? setNewType('text') : setNewType('password');
  return (
    <div className="relative z-10 w-full">
      <div className="flex flex-col space-y-1">
        <label className="text-xs text-left">{label}</label>
        <input
          type={newType}
          className="w-full px-4 py-2 mb-4 border rounded border-gray1 focus:border-violet-300 focus:outline-none focus:shadow-outline placeholder:text-sm text-dark"
          placeholder={placeholder}
          required={required}
          onChange={onChange}
          value={value}
          {...props}
        />

        {isCoupon && (
          <div className="absolute right-2 top-6">
            <span
              onClick={handleCodeChange}
              className="px-3 py-1.5 cursor-pointer text-xs font-bold bg-white border rounded-md text-primary border-primary"
            >
              Generate Code
            </span>
          </div>
        )}

        {isPassword && (
          <p
            className="absolute cursor-pointer right-2 bottom-2 hover:opacity-70"
            onClick={togglePasswordVisibility}
          >
            {newType === 'password' ? (
              <EyeIcon className="w-6 h-6" />
            ) : (
              <EyeClosedIcon className="w-6 h-6" />
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
