import { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import { useDashboard } from '../context/DashboardContext';
import axiosInstance from '../api/axios';
import { useAuth } from '../context/AuthContext';

interface UseDisableUserProps {
  changeStatus: 'Deactivate' | 'Activate';
  id: number;
  isCustomer?: boolean;
  isBranch?: boolean;
  closeModal?: () => void;
}

interface UseDisableUserReturn {
  disableUser: () => Promise<void>;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

const useDisableUser = ({
  changeStatus,
  id,
  isCustomer,
  closeModal,
  isBranch,
}: UseDisableUserProps): UseDisableUserReturn => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { setUsersReload } = useDashboard();
  const { token } = useAuth();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const disableUser = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      const body = {
        type: changeStatus === 'Deactivate' ? 'disable' : 'enable',
        id: id,
      };

      const url = isCustomer ? 'admin/user/action/' : 'admin/action/';
      await axiosInstance.post(
        `${!isBranch ? url : 'branch/action/'}${
          changeStatus === 'Deactivate' ? 'disable' : 'enable'
        }`,
        body,
        options
      );

      if (changeStatus === 'Deactivate') {
        toast.success('Deactivated successfully');
      } else {
        toast.success('Activated successfully');
      }

      closeModal && closeModal();
      if (setUsersReload) {
        setUsersReload((prev) => !prev);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return { setIsSubmitting, isSubmitting, disableUser };
};

export default useDisableUser;
