import { createBrowserRouter } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import DefaultLayout from '../layouts/DefaultLayout';
import Dashboard from '../pages/dashboard';
import Login from '../pages/auth/login';
import ResetPassword from '../pages/auth/resetPassword';
import SignUp from '../pages/auth/signup';
import Verification from '../pages/auth/verification';
import Staffs from '../pages/staffs';
import AddStaff from '../pages/staffs/addStaff';
import Customers from '../pages/customers';
import AddCustomer from '../pages/customers/addCustomer';
import Profile from '../pages/profile';
import UpdatePassword from '../pages/profile/updatePassword';
import EditProfile from '../pages/profile/editProfile';
import StoreLocator from '../pages/storeLocator';
import Transactions from '../pages/transactions';
import PaymentRequests from '../pages/paymentRequests.tsx';
import Analytics from '../pages/analytics';
import Settings from '../pages/settings';
import PushNotification from '../pages/pushNotification';
import VerifiyPasswordChange from '../pages/auth/verifyPassword';
import ChangePassword from '../pages/auth/changePassword';
import AddBranch from '../pages/storeLocator/addBranch';
import Customer from '../pages/customers/customer';
import Staff from '../pages/staffs/staff';
import Transaction from '../pages/transactions/transaction';
import AddTransaction from '../pages/transactions/addTransaction';
import PaymentRequest from '../pages/paymentRequests.tsx/paymentRequest';
import ErrorElement from '../components/Base/ErrorElement';
import Marketing from '../pages/marketing';
import PromotionalInformation from '../components/Marketing/PromotionalInformation';
import Discounts from '../pages/marketing/discounts';
import AddNewPromotionForm from '../components/Marketing/AddNewPromotionForm';
import DiscountForm from '../components/Marketing/DiscountForm';
import Discount from '../pages/marketing/discount';
import AddPushNotification from '../pages/pushNotification/addPushNotification';
import Notifications from '../pages/notifications';
import Branch from '../pages/storeLocator/branch';

export const routes = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      {
        path: 'auth/login',
        element: <Login />,
      },
      {
        path: 'auth/signup',
        element: <SignUp />,
      },
      {
        path: 'auth/reset-password',
        element: <ResetPassword />,
      },
      {
        path: 'auth/verification',
        element: <Verification />,
      },
      {
        path: 'auth/verifypassword',
        element: <VerifiyPasswordChange />,
      },
      {
        path: 'auth/changepassword',
        element: <ChangePassword />,
      },
    ],
  },
  {
    element: <DefaultLayout />,
    errorElement: <ErrorElement />,
    children: [
      {
        element: <Marketing />,
        errorElement: <ErrorElement />,
        children: [
          {
            path: '/marketing',
            element: <PromotionalInformation />,
          },
          {
            path: '/marketing/addpromotion',
            element: <AddNewPromotionForm />,
          },
          {
            path: '/marketing/addcoupon',
            element: <DiscountForm />,
          },
          {
            path: '/marketing/discounts',
            element: <Discounts />,
          },
          {
            path: '/marketing/discounts/:id',
            element: <Discount />,
          },
        ],
      },
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/staffs',
        element: <Staffs />,
      },
      {
        path: '/staff/:userId',
        element: <Staff />,
      },
      {
        path: '/staff/add',
        element: <AddStaff />,
      },
      {
        path: '/store_locator/add',
        element: <AddBranch />,
      },
      {
        path: '/customers',
        element: <Customers />,
      },
      {
        path: '/customers/:userId',
        element: <Customer />,
      },
      {
        path: '/customer/add',
        element: <AddCustomer />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/profile/update',
        element: <EditProfile />,
      },
      {
        path: '/store_locator',
        element: <StoreLocator />,
      },
      {
        path: '/store_locator/:id',
        element: <Branch />,
      },
      {
        path: '/transactions',
        element: <Transactions />,
      },

      {
        path: '/transactions/:transactionId',
        element: <Transaction />,
      },
      {
        path: '/transactions/add',
        element: <AddTransaction />,
      },
      {
        path: '/payment_requests',
        element: <PaymentRequests />,
      },

      {
        path: '/payment_requests/:id',
        element: <PaymentRequest />,
      },
      {
        path: '/profile/password/update',
        element: <UpdatePassword />,
      },
      {
        path: '/analytics',
        element: <Analytics />,
      },

      {
        path: '/push_notification',
        element: <PushNotification />,
      },
      {
        path: '/notifications',
        element: <Notifications />,
      },
      {
        path: '/push_notification/add',
        element: <AddPushNotification />,
      },
      {
        path: '/settings',
        element: <Settings />,
      },
    ],
  },
  {},
]);
