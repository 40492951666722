import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { IAdminNotification } from '../../interfaces/user';
import { formatDateTime, formatFullString } from '../../functions/formatDate';
import TableSkeleton from '../../components/Base/TableSkeleton';
import { useDashboard } from '../../context/DashboardContext';

const Notifications = () => {
  const [notifications, setNotifications] = useState<IAdminNotification[]>([]);
  const { token } = useAuth();
  const { fetchNotifications, loading, usersReload, setUsersReload } =
    useDashboard();

  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const fetchData = async () => {
    const data = await fetchNotifications();
    setNotifications(data.data);
  };
  const markAsRead = async (id: number) => {
    try {
      const { data } = await axiosInstance.get(
        `/push-notification/read-admin-notification/${id}`,
        {
          headers,
        }
      );
      setUsersReload && setUsersReload((prev) => !prev);
      return data;
    } catch (err) {}
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReload]);
  return (
    <section className="pb-20">
      <Header header=" Notifications" subtext="" />

      {loading ? (
        <div className="p-10">
          <TableSkeleton />
        </div>
      ) : (
        <div className="px-3 mt-16 md:px-8">
          {notifications.map((notification, index) => (
            <div key={index} className="my-10">
              <h2 className="text-xs font-bold sm:text-sm text-dark text-opacity-60">
                {formatFullString(notification.date, true)}
              </h2>

              {notification.notifications.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-wrap items-center justify-between px-5 py-4 my-3 bg-white rounded-md sm:flex-nowrap"
                >
                  <div className="flex items-center gap-4 md:gap-10">
                    <p className="text-xs font-medium text-dark sm:text-base text-opacity-80">
                      {formatDateTime(item.createdAt)}
                    </p>
                    <div>
                      <p className="text-xs font-medium md:text-sm text-dark text-opacity-80">
                        {item.content}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-end pt-10 sm:pt-0">
                    <span
                      onClick={() => markAsRead(item.id)}
                      className="px-4 py-1 text-xs font-semibold text-white rounded-md cursor-pointer whitespace-nowrap bg-primary"
                    >
                      Mark as read
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default Notifications;
