import React, { useState } from 'react';
import OtpInput from 'react18-input-otp';
import Button from '../Base/Button';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../context/AuthContext';

interface Props {
  email: string;
  isDeviceVerification: boolean;
}

const VerifyCode = ({ email, isDeviceVerification }: Props) => {
  const navigate = useNavigate();
  const { sendVerificationCode, reSendVerificationCode } = useAuth();
  const [isSending, setIsSending] = useState(false);
  const [otp, setOtp] = useState('');
  const handleResend = async () => {
    if (!email) return;
    const data = await reSendVerificationCode(
      email,
      isDeviceVerification ? 'device' : 'password'
    );
    if (data) {
      toast.success('Code resent successfully');
    }
  };

  const handleVerification = async () => {
    if (otp === '') {
      toast.error('Make sure to enter a code');
    }
    if (email && otp) {
      setIsSending(true);
      try {
        const data = await sendVerificationCode(
          email,
          Number(otp),
          isDeviceVerification ? 'device' : 'password'
        );
        if (data) {
          toast.success('Code verified successfully');
          isDeviceVerification
            ? navigate('/auth/login')
            : navigate('/auth/changepassword');
        }
      } catch (err) {
        setIsSending(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 mx-3 w-[25rem] sm:mx-0 ">
      <h2 className="pt-5 mb-3 lg:text-[22px] text-center font-semibold capitalize">
        {isDeviceVerification
          ? 'looks like you changed your device'
          : ' Enter verification code'}
      </h2>

      <div className="flex justify-center">
        <p className="w-11/12 pb-10 text-center text-textcolor">
          Please check your mail. Verification code has been sent to
          <span className="pl-1">{email}</span>
        </p>
      </div>

      <div className="flex justify-center my-6">
        <OtpInput
          className="p-4 mx-4 font-semibold text-center bg-white border rounded-md focus:outline-0 focus:border-blue-300"
          value={otp}
          onChange={(e: string) => setOtp(e)}
          numInputs={4}
          shouldAutoFocus={true}
          placeholder={'----'}
          inputStyle={{
            outlineWidth: '0px',
            focus: 'none',
          }}
        />
      </div>
      <div className="flex flex-col w-full py-4 space-y-8">
        <p className="pt-2 pb-8 font-medium text-center text-purple60">
          <span className="opacity-70"> Didn’t get the OTP?</span>
          <span
            onClick={handleResend}
            className="pl-1 cursor-pointer text-primary "
          >
            Resend
          </span>
        </p>
        <Button isSubmitting={isSending} onClick={handleVerification}>
          {isDeviceVerification ? 'Activate this device' : 'Reset Password'}
        </Button>
      </div>
    </div>
  );
};

export default VerifyCode;
