import React, { useState } from 'react';
import logo from './../.././assets/images/logo.svg';
import Input from '../../components/Base/Input';
import Button from '../../components/Base/Button';
import { Link } from 'react-router-dom';
const SignUp = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // handle login logic here
  };

  return (
    <div className="flex flex-col items-center justify-center py-8 mx-3 bg-white rounded-lg w-96 ">
      <div>
        <div>
          <img src={logo} alt="" />
        </div>
      </div>
      <h2 className="pt-5 mb-3 lg:text-[22px] font-semibold">Howdy!</h2>
      <p className="w-11/12 pb-10 text-center text-textcolor">
        Let’s help you get signed up quickly
      </p>

      <div className="flex flex-col w-full space-y-8">
        <div className="flex gap-4">
          <Input
            type="text"
            label="First name*"
            placeholder="Enter first name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            type="text"
            label="Last name*"
            placeholder="Enter last name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

        <Input
          type="email"
          label="Email Address*"
          placeholder="example@mail.com"
        />

        <div>
          <Input
            type="password"
            label="Password*"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            isPassword
          />
        </div>
        <div>
          <Input
            type="password"
            label="Confirm Password*"
            placeholder="Re-enter Password"
            isPassword
          />
        </div>
        <Button onClick={handleLogin}>Sign up</Button>
      </div>

      <p className="pt-10 text-textcolor">
        Already have an account?
        <Link to={'/auth/login'} className="pl-2 cursor-pointer text-primary">
          Log in
        </Link>
      </p>
    </div>
  );
};

export default SignUp;
