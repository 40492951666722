import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import FileUploadView from '../Base/FileUploadView';
import Button from '../Base/Button';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { useDashboard } from '../../context/DashboardContext';
import { IBranch } from '../../interfaces/user';
interface Props {
  setIsOpen: () => void;
  isBranch?: boolean;
  branchObj?: IBranch;
}
const UpdateProfilePictureModal = ({
  setIsOpen,
  isBranch,
  branchObj,
}: Props) => {
  const { token } = useAuth();
  const { setReload } = useDashboard();
  const [file, setFile] = useState();
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState('');
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  const onChange = async () => {
    setUploading(true);
    if (file) {
      const formData = new FormData();

      if (isBranch) {
        formData.append('branch_image', file);
      } else {
        formData.append('profile_image', file);
      }
      const url = isBranch
        ? `branch/update-branchImage/${branchObj?.id}`
        : '/admin/update-profileimage';
      try {
        const { data } = await axiosInstance.post(url, formData, options);
        toast.success('Profile image updated successfully');
        setReload && setReload((prev) => !prev);
        setUploading(false);
        setIsOpen();
        return data;
      } catch (err) {
        console.log(err);
        setUploading(false);

        return err;
      }
    }
  };

  useEffect(() => {
    if (branchObj && isBranch) {
      setImage(branchObj?.branchImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchObj]);
  return (
    <div className="gap-20 mx-auto bg-white h-fit ">
      <div className="p-3">
        {' '}
        <div className="flex justify-end">
          <span
            onClick={setIsOpen}
            className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
          >
            <XMarkIcon />
          </span>
        </div>
        <div className="flex justify-center">
          <h1 className="text-center header">
            {' '}
            {isBranch ? 'Update Branch Picture' : 'Update Profile Image'}
          </h1>
        </div>
        <div className="py-5">
          {branchObj?.branchImage && (
            <div className="flex justify-center">
              <img
                src={image}
                alt="branch"
                className="object-cover w-20 h-20 rounded-lg"
              />
            </div>
          )}
          <FileUploadView
            small
            setFile={(file) => {
              setFile(file);
            }}
            getImage={(file) => {
              setImage(file);
            }}
            text={isBranch ? 'Branch Image' : 'Profile Picture'}
          />

          <div className="w-full mt-20">
            <Button onClick={onChange} full isSubmitting={uploading}>
              {isBranch ? 'Change Branch Picture' : '  Change Profile Picture'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfilePictureModal;
