import React from 'react';

interface Props {
  title: string;
  numbers: string | number;
  additions: string;
  color: string;
}
const AnalyticsCard = ({ title, numbers, additions, color }: Props) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`flex flex-col gap-3 p-4 rounded  bg-opacity-40`}
    >
      <p className="text-left">{title}</p>
      <h5 className="text-xl font-bold">{numbers}</h5>

      <p className="text-xs text-left">{additions}</p>
    </div>
  );
};

export default AnalyticsCard;
