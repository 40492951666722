import React from 'react';

interface Props {
  firstName: string;
  lastName: string;
}
const NameBackground = ({ firstName = 'John', lastName = 'Doe' }: Props) => {
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;
  return (
    <div>
      <div className="flex items-center justify-center w-20 h-20 bg-primary bg-opacity-10 font-bold text-3xl rounded-[50%]">
        {initials.toUpperCase()}
      </div>
    </div>
  );
};

export default NameBackground;
