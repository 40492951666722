export const formatDate = (initialDateString: string): string => {
  if (!initialDateString) {
    return '';
  }
  const date = new Date(initialDateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${day < 10 ? '0' + day : day}-${
    month < 10 ? '0' + month : month
  }-${year} ${hours}:${minutes}`;
};
export const formatFilter = (initialDateString: Date) => {
  const date = new Date(initialDateString);

  // Get the year, month, and day components of the date
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Note: month is zero-indexed, so add 1
  const day = date.getDate();

  // Create a new date string in the format "YYYY-MM-DD"
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;

  return formattedDate; // Output: "2023-04-25"
};

export const formatISODate = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleString('en-US', options);
  const timeZoneName = date
    .toLocaleString('en-US', { timeZoneName: 'short' })
    .split(' ')[2];

  return `${formattedDate} (${timeZoneName})`;
};

export const formatDateTime = (dateTimeString: string | number | Date) => {
  const dateTime = new Date(dateTimeString);

  // Define the options for formatting the date and time
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  // Format the date and time string using the defined options
  const formattedDateTime = dateTime.toLocaleDateString('en-US', options);

  return formattedDateTime;
};

const inputDateTimeString = '2023-05-15T19:28:00';
const formattedDateTime = formatDateTime(inputDateTimeString);

console.log(formattedDateTime); // Output: May 15, 2023 19:28

export const formatTomorrow = () => {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);
  const currentdate = formatFilter(tomorrow);
  return currentdate;
};

export const formatFullString = (
  dateString: string,
  hasWeekday?: boolean
): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: hasWeekday ? 'long' : 'short',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const getDaysBetweenDates = (
  startDate: string,
  endDate: string
): number => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const timeDiff = Math.abs(end.getTime() - start.getTime());
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
};
export const filterByDate = (event: string | number) => {
  const currentDate = new Date();
  let startDate: string | Date, endDate;
  switch (event) {
    // Show all data

    case 'All':
      startDate = '2023-03-01';
      endDate = formatFilter(currentDate);
      break;

    case 'Today':
      // Show data for today
      startDate = formatFilter(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
      );
      endDate = formatFilter(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        )
      );

      break;
    case 'This Week':
      // Show data from last week
      startDate = formatFilter(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7
        )
      );
      endDate = formatFilter(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 1
        )
      );
      break;
    case 'This Month':
      // Show data from this month
      startDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      );
      endDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
      break;
    case 'Past Month':
      // Show data from last month
      startDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
      );
      endDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      );
      break;
    case '2 Months':
      // Show data from the past 2 months
      startDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1)
      );
      endDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
      break;
    case '3 Months':
      // Show data from the past 3 months
      startDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1)
      );
      endDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
      break;
    case '6 Months':
      // Show data from the past 6 months
      startDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, 1)
      );
      endDate = formatFilter(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
      );
      break;

    case 'This Year':
      // Show data from this year
      startDate = formatFilter(new Date(currentDate.getFullYear(), 0, 1));
      endDate = formatFilter(new Date(currentDate.getFullYear() + 1, 0, 1));
      break;
    default:
      // Invalid option
      console.log('Invalid option selected');
      return;
  }
  return { startDate, endDate };
};
