import React from 'react';
import Header from '../../components/Pages/Header';
import { LongArrowLeftIcon } from '../../assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import AddNotificationForm from '../../components/Notification/AddNotificationForm';

const AddPushNotification = () => {
  const navigate = useNavigate();

  const goToPrevPage = () => {
    navigate('/push_notification');
  };

  return (
    <div className="w-full pb-36">
      <Header header="Send Notification" subtext="" />
      <div className="px-8 mt-6">
        <div className="card">
          <div
            onClick={goToPrevPage}
            className="flex items-center gap-4 cursor-pointer text-textcolor"
          >
            <LongArrowLeftIcon />
            <p>Back to Notifications</p>
          </div>
          <h1 className="px-8 py-10 header">Create a new push notification</h1>
          <AddNotificationForm />
        </div>
      </div>
    </div>
  );
};

export default AddPushNotification;
