import {
  AnalyticsIcon,
  CustomerIcon,
  DashboardIcon,
  FileIcon,
  ListIcon,
  MarketingIcon,
  ProfileIcon,
  PushNotificationsIcon,
  // SettingsIcon,
  StaffIcon,
  StoreIcon,
} from '../assets/icons/icons';

export const SideBarRoutes = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/',
  },
  {
    name: 'Analytics',
    icon: <AnalyticsIcon />,
    path: '/analytics',
    hideStaff: true,
  },
  {
    subtitle: 'User Management',
    titles: [
      {
        title: 'Customers',
        icon: <CustomerIcon />,
        path: '/customers',
      },
      {
        title: 'Staffs',
        icon: <StaffIcon />,
        path: '/staffs',
      },
    ],
  },
  {
    subtitle: 'TRANSACTIONS',
    titles: [
      {
        title: 'Payment Requests',
        icon: <FileIcon />,
        path: '/payment_requests',
        hideStaff: true,
      },
      {
        title: 'Transactions',
        icon: <ListIcon />,
        path: '/transactions',
      },
    ],
  },
  {
    hasGap: true,
    name: 'Branches',
    icon: <StoreIcon />,
    path: '/store_locator',
  },
  {
    name: 'Push Notification',
    icon: <PushNotificationsIcon />,
    path: '/push_notification',
    hideStaff: true,
  },
  {
    name: 'Marketing',
    icon: <MarketingIcon />,
    path: '/marketing',
    hideStaff: true,
  },
  {
    name: 'Profile',
    icon: <ProfileIcon />,
    path: '/profile',
  },
  // {
  //   name: 'Settings',
  //   icon: <SettingsIcon />,
  //   path: '/settings',
  // },
];
