import React from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import AddCustomerForm from './AddCustomerForm';
import { IUser } from '../../interfaces/user';

interface Props {
  setIsOpen: () => void;
  userObj: IUser;
}
const EditCustomersModal = ({ userObj, setIsOpen }: Props) => {
  return (
    <div className="bg-[#F8F8F8]  mx-auto gap-5 h-fit  rounded-xl ">
      <div className="p-3">
        <div className="flex justify-end">
          <span
            onClick={setIsOpen}
            className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
          >
            <XMarkIcon />
          </span>
        </div>
        <div className="flex justify-center">
          <h1 className="text-center header">Edit Customer</h1>
        </div>
        <div className="pb-10">
          <AddCustomerForm userObj={userObj} isEdit setIsOpen={setIsOpen} />
        </div>
      </div>
    </div>
  );
};

export default EditCustomersModal;
