import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useAuth } from './AuthContext';
import axiosInstance from '../api/axios';
import { toast } from 'react-toastify';
import { IUser } from '../interfaces/user';

interface paramProp {
  search_term: string;
  start_date: string;
  end_date: string;
  page_size: number;
  type?: number | null;
  branch?: number | null | undefined;
  status?: number | null | undefined | string;
}
interface dashboardProp {
  start_date: string;
  end_date: string;
}
interface analyticsProp {
  start_date: string;
  end_date: string;
  users_start_date: string;
  users_end_date: string;
  graph_start_date: string;
  graph_end_date: string;
  revenue_start_date: string;
  revenue_end_date: string;
  deposit_start_date: string;
  deposit_end_date: string;
}
interface IContext {
  addStaff: (arg0: FormData) => Promise<any>;
  createBranch: (
    arg0: FormData | { branch_name: string; location: string },
    isEdit?: boolean | undefined,
    id?: number | undefined
  ) => Promise<any>;
  fetchCustomers: (params: paramProp) => Promise<any>;
  getBranches: () => Promise<any>;
  fetchStaffs: (params: paramProp) => Promise<any>;
  getBranchesWithFilter: (params: paramProp) => Promise<any>;
  getSingleUser: (id: string | number, type: 'user' | 'admin') => Promise<any>;
  user: IUser | undefined;
  loading?: boolean;
  mobileMenu: boolean;
  setMobileMenu: Dispatch<SetStateAction<boolean>>;
  fetchDashboard: (params: dashboardProp) => Promise<any>;
  fetchAnalytics: (params: analyticsProp) => Promise<any>;
  fetchNotifications: () => Promise<any>;
  reload?: boolean;
  setReload?: Dispatch<SetStateAction<boolean>>;
  usersReload?: boolean;
  setUsersReload?: Dispatch<SetStateAction<boolean>>;
}

let DashboardContext = createContext<IContext>({
  addStaff: async () => {
    return;
  },
  createBranch: async () => {
    return;
  },
  fetchDashboard: async () => {
    return;
  },
  fetchNotifications: async () => {
    return;
  },
  fetchAnalytics: async () => {
    return;
  },
  fetchCustomers: async () => {
    return;
  },
  fetchStaffs: async () => {
    return;
  },
  getBranchesWithFilter: async () => {
    return;
  },
  getBranches: async () => {
    return;
  },
  getSingleUser: async () => {
    return;
  },
  setMobileMenu: (boolean) => boolean,
  mobileMenu: false,
  user: {
    id: 0,
    firstname: '',
    lastname: '',
    fullname: '',
    email: '',
    phone: '',
    password: '',
    profileImage: '',
    currentDeviceId: '',
    currentDeviceName: '',
    createdBy: '',
    lastLogin: '',
    lastLoginIp: '',
    isEmailVerified: false,
    status: false,
    role: '',
    branch: {
      id: 0,
      name: '',
      createdBy: '',
      assignedStaff: 0,
      location: '',
      branchImage: '',
      createdAt: '',
      updatedAt: '',
    },
    createdAt: '',
    updatedAt: '',
  },
});

export const useDashboard = () => useContext(DashboardContext);

const DashboardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { token } = useAuth();
  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [reload, setReload] = useState(false);
  const [usersReload, setUsersReload] = useState(false);
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const addStaff = async (body: FormData) => {
    try {
      const { data, status } = await axiosInstance.post(
        '/admin/create',
        body,
        options
      );
      if (status === 201) {
        toast.success('Staff created successfully');
      }
      return data;
    } catch (err) {
      return err;
    }
  };
  const createBranch = async (
    body: FormData | { branch_name: string; location: string },
    isEdit: boolean | undefined = false,
    id: number | undefined
  ) => {
    try {
      if (!(body instanceof FormData) && isEdit) {
        await axiosInstance.post(
          `branch/update/${id}?branch_name=${body.branch_name}&address=${body.location}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        await axiosInstance.post('/branch/create', body, options);
      }
      toast.success('Branch created successfully');
    } catch (err) {
      return err;
    }
  };

  const fetchCustomers = async (params: paramProp) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/user/get-all`, {
        headers,
        params,
      });
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const fetchStaffs = async (params: paramProp) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/admin/get-all`, {
        headers,
        params,
      });
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const fetchDashboard = async (params: dashboardProp) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/dashboard/data`, {
        headers,
        params,
      });
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const fetchAnalytics = async (params: analyticsProp) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/dashboard/analytics`, {
        headers,
        params,
      });
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get('/push-notification/admin/get', {
        headers,
      });

      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };
  const getSingleUser = async (id: string | number, type: string = 'user') => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`/${type}/get-single`, {
        headers,
        params: {
          id,
        },
      });
      setUser(data.data);
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const getBranches = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`branch/get-all`, {
        headers,
      });

      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };
  const getBranchesWithFilter = async (params: paramProp) => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(`branch/get-filteredbranch`, {
        headers,
        params,
      });

      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      return err;
    }
  };

  const values = {
    getBranchesWithFilter,
    loading,
    user,
    getSingleUser,
    addStaff,
    createBranch,
    fetchCustomers,
    fetchStaffs,
    getBranches,
    mobileMenu,
    setMobileMenu,
    fetchDashboard,
    reload,
    setReload,
    usersReload,
    setUsersReload,
    fetchAnalytics,
    fetchNotifications,
  };
  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
