import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

interface Props {
  categories: string[];
  colors: string[];
  plots: number[];
  label: string;
}

const TransactionsChart = ({ plots, colors, categories, label }: Props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = {
    labels: categories,
    datasets: [
      {
        label: label,
        data: plots,
        backgroundColor: colors,
        borderWidth: 0,
        innerRadius: '6%',
      },
    ],
  };
  const options = {
    cutout: 90,

    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: false,
      },
    },

    //   circumference: 200,
  };
  return <Doughnut data={data} options={options} />;
};

export default TransactionsChart;
