import React, { createRef, useEffect, useState } from 'react';
import { ShareIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import CardSkeleton from '../../components/Base/CardSkeleton';
import Modal from '../../components/Base/Modal';
import CustomerProfileCard from '../../components/Customers/CustomerProfileCard';
import Header from '../../components/Pages/Header';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { ITransaction } from '../../interfaces/user';
import UserDetailCard from '../../components/UserDetailCard';
import { formatDate } from '../../functions/formatDate';
import DropdownItems from '../../components/Base/DropdownItems';
import useOutsideClick from '../../hooks/useOutsideClicks';
import { Transition } from '@headlessui/react';
import PaymentRequestModal from '../../components/PaymentRequests/PaymentRequestModal';
import { formatNumberWithCommas } from '../../functions/formatNumber';

const Transaction = () => {
  const { token } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);
  let { transactionId } = useParams();
  const dropdownItemsRef = createRef<HTMLDivElement>();
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(false));
  const [loading, setLoading] = useState(false);
  const [openPaymentRequestModal, setOpenPaymentRequestModal] = useState(false);
  const [, setChangeStatus] = useState('');
  const [transaction, setTransaction] = useState<ITransaction>();
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const getTransaction = async (): Promise<void> => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/transactions/${transactionId}`,
        options
      );
      setLoading(false);
      setTransaction(data.data);
    } catch (err) {
      setLoading(false);
    }
  };
  const handleDropdownOption = (option: string) => {
    if (option === 'Completed') setChangeStatus(option);
    if (option === 'Pending') setChangeStatus(option);
    if (option === 'Refund') setChangeStatus(option);
  };

  useEffect(() => {
    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {loading ? (
        <div className="p-10">
          <CardSkeleton />
        </div>
      ) : (
        <section className="w-full pb-20 ">
          <Transition
            show={showDropdown}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            ref={dropdownItemsRef}
          >
            <DropdownItems
              isOpen={showDropdown}
              isPage
              options={['Complete', 'Pending', 'Failed', 'Refund']}
              onChange={(option) => handleDropdownOption(option)}
              onSelected={() => setShowDropdown(false)}
            />
          </Transition>
          <Header
            breadCrumb="View transactions"
            header="Transactions"
            subtext="Monitor and Manage transactions"
          >
            <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
              <Button variant="outline">Download CSV</Button>
              <Button onClick={() => setShowDropdown(!showDropdown)}>
                Change status
              </Button>
            </div>
          </Header>

          {transaction && (
            <div className="flex flex-col justify-between gap-4 mx-5 my-8 xmd:flex-row lg:mx-10">
              <div className="xl:w-[23%]">
                <CustomerProfileCard
                  fullName={
                    transaction.user.firstname + ' ' + transaction.user.lastname
                  }
                  lastName={transaction.user.lastname}
                  email={transaction.user.email}
                  image={transaction.user.profileImage}
                  walletBalance={`₦${formatNumberWithCommas(
                    transaction.total
                  )}`}
                  noButton
                />
              </div>
              <div className="overflow-auto xl:w-9/12">
                <div className="p-5 mt-3 bg-white card">
                  {transaction?.category === 'deposit' ? (
                    <UserDetailCard
                      details={[
                        {
                          title: 'Date Created',
                          value: transaction?.createdAt
                            ? formatDate(transaction.createdAt)
                            : '',
                        },
                        {
                          title: 'Transaction Type',
                          value: transaction.transactionType,
                        },
                        {
                          title: 'Category',
                          value: transaction.category,
                        },
                        {
                          title: 'Session ID',
                          value: transaction.sessionId
                            ? transaction.sessionId
                            : 'Not available',
                        },
                        {
                          title: 'Settlement ID',
                          value: transaction.settlementId
                            ? transaction.settlementId
                            : 'Not available',
                        },
                        {
                          title: 'Sender Name',
                          value: transaction.senderName
                            ? transaction.senderName
                            : 'Not available',
                        },
                        {
                          title: 'Sender Bank',
                          value: transaction.senderBank
                            ? transaction.senderBank
                            : 'Not available',
                        },

                        {
                          title: 'Transaction ID',
                          value: transaction.id,
                        },
                        {
                          title: 'Amount',
                          value:
                            '₦' +
                            formatNumberWithCommas(transaction.amountPaid),
                        },
                        {
                          title: 'Total',
                          value:
                            '₦' + formatNumberWithCommas(transaction.total),
                        },
                        {
                          title: 'Status',
                          value: transaction.status,
                          isStatusString: true,
                        },
                        // {
                        //   title: 'Account Number',
                        //   value: transaction.senderAccount
                        //     ? transaction.senderAccount
                        //     : 'Nil',
                        // },
                        {
                          title: 'Invoice ID',
                          value: transaction.settlementId,
                        },
                      ]}
                    />
                  ) : (
                    <UserDetailCard
                      details={[
                        {
                          title: 'Date',
                          value: transaction?.createdAt
                            ? formatDate(transaction.createdAt)
                            : '',
                        },
                        {
                          title: 'Transaction Type',
                          value: transaction.transactionType,
                        },
                        {
                          title: 'Category',
                          value: transaction.category,
                        },
                        {
                          title: 'Branch',
                          value: transaction.branch.name,
                        },
                        {
                          title: 'Coupon',
                          value: transaction.couponCode
                            ? transaction.couponCode
                            : 'Not available',
                        },
                        {
                          title: 'Transaction ID',
                          value: transaction.id,
                        },
                        {
                          title: 'Discount',
                          value:
                            '₦' + formatNumberWithCommas(transaction.discount),
                        },
                        {
                          title: 'Amount',
                          value:
                            '₦' +
                            formatNumberWithCommas(transaction.amountPaid),
                        },
                        {
                          title: 'Total',
                          value:
                            '₦' + formatNumberWithCommas(transaction.total),
                        },
                        {
                          title: 'Status',
                          value: transaction.status,
                          isStatusString: true,
                        },
                        // {
                        //   title: 'Account Number',
                        //   value: transaction.senderAccount
                        //     ? transaction.senderAccount
                        //     : 'Not available',
                        // },
                      ]}
                    />
                  )}

                  <Modal
                    width="w-[30rem]"
                    isOpen={openPaymentRequestModal}
                    onClose={() => setOpenPaymentRequestModal(false)}
                  >
                    <PaymentRequestModal
                      setIsOpen={() => setOpenPaymentRequestModal(false)}
                      userEmail={transaction?.user.email}
                      // userName={user?.uuid}
                    />
                  </Modal>

                  <div className="flex items-center justify-between mt-5">
                    <div className="">
                      <Button
                        icon={<ShareIcon />}
                        variant="purple"
                        onClick={() => {
                          setOpenPaymentRequestModal(true);
                        }}
                      >
                        Send payment Request
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
      )}
    </>
  );
};

export default Transaction;
