import { Listbox, Transition } from '@headlessui/react';
import { ThickDownArrow } from '../../assets/icons/icons';
import { IBranch } from '../../interfaces/user';
interface Props {
  label?: string;
  options: string[] | IBranch[];
  value?: string | number | IBranch;
  onChange?: (value: IBranch | string | number) => void;
}
const Select = ({ label, options, value, onChange }: Props) => {
  const handleChange = (value: IBranch) => {
    onChange && onChange(value);
  };
  return (
    <div className="flex flex-col space-y-1">
      <label className="text-xs text-left">{label}</label>
      <Listbox value={value} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button className="relative z-10 w-full py-2 pl-3 pr-10 text-left bg-white border rounded cursor-default border-gray1 focus:border-violet-300 focus:outline-none focus:shadow-outline placeholder:text-sm sm:text-sm">
                {typeof value === 'object'
                  ? value.name
                  : value || 'Select Option'}
                <span className="absolute inset-y-0 flex items-center pr-2 pointer-events-none -right-2">
                  <ThickDownArrow className="w-10 h-2" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Listbox.Options className="z-50 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg  max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((item, index) => (
                    <Listbox.Option
                      key={index}
                      value={item}
                      className={({ active }) =>
                        `${
                          active
                            ? 'text-white bg-primary opacity-20'
                            : 'text-gray-900'
                        }
                        capitalize  cursor-default select-none relative py-2 pl-10 pr-4`
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`${
                              selected ? 'font-medium' : 'font-normal'
                            } block truncate`}
                          >
                            {typeof item === 'object' ? item.name : item}
                          </span>
                          {selected ? (
                            <span
                              className={`${
                                active ? 'text-white' : 'text-primary'
                              }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                            ></span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
};

export default Select;
