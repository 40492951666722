import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import lines from './../assets/images/authlines.svg';
import { useAuth } from '../context/AuthContext';
const AuthLayout = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  useEffect(() => {
    if (user) navigate('/');
  }, [navigate, user]);

  return (
    <div className="flex items-center justify-center h-screen bg-white">
      <div className="relative z-20 flex flex-col items-center justify-center py-8 ">
        <Outlet />
      </div>
      <img src={lines} alt="" className="absolute" />
    </div>
  );
};

export default AuthLayout;
