import { useState, useEffect } from 'react';
import { useDashboard } from '../context/DashboardContext';

const useBranches = () => {
  const [branches, setBranches] = useState<string[]>([]);
  const { getBranches } = useDashboard();
  const fetchBranches = async () => {
    const { data } = await getBranches();
    setBranches(data.map((branch: { name: string }) => branch));
  };
  useEffect(() => {
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return branches;
};

export default useBranches;
