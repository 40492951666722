import React from 'react';
import { IUser } from '../../interfaces/user';
import UserDetailCard from '../UserDetailCard';
import { formatDate } from '../../functions/formatDate';

interface Props {
  user: IUser;
}
const StaffInformationTab = ({ user }: Props) => {
  return (
    <div className="px-4 ">
      <UserDetailCard
        details={[
          {
            title: 'Phone',
            value: '+' + user.phone,
          },
          {
            title: 'Email Address',
            value: user.email,
          },
          {
            title: 'Role Type',
            value: user.role,
          },
          {
            title: 'Staff ID',
            value: user.id,
          },
          {
            title: 'Reg. Date',
            value: formatDate(user.createdAt),
          },
          {
            title: 'Status',
            value: user.status ? 'active' : 'inactive',
          },
          {
            title: 'Branch of operation',
            value: user.branch.name,
          },
        ]}
      />
    </div>
  );
};

export default StaffInformationTab;
