import React, { useState } from 'react';
import { LongArrowLeftIcon } from '../../assets/icons/icons';
import Input from '../Base/Input';
import TextArea from '../Base/TextArea';
import Button from '../Base/Button';
import { toast } from 'react-toastify';
import CheckBox from '../Base/CheckBox';
import axiosInstance from '../../api/axios';
import { useAuth } from '../../context/AuthContext';
import { useDashboard } from '../../context/DashboardContext';

interface Props {
  closeModal: () => void;
  id: number;
}
const PushNotificationForm = ({ id, closeModal }: Props) => {
  const [heading, setHeading] = useState('');
  const [message, setMessage] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const [platform, setPlatform] = useState('');
  const { token } = useAuth();
  const { setUsersReload } = useDashboard();
  const handleSubmit = () => {
    if (!(message && heading)) {
      toast.error('Fill all fields');
    } else {
      setIsPreview(true);
    }
  };
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const sendNotification = async () => {
    const body = {
      content: message,
      title: heading,
      type: platform,
      userId: id,
    };
    try {
      await axiosInstance.post(
        'push-notification/admin/send-to-user',
        body,
        options
      );
      toast.success('Push Notification sent successfully');

      setUsersReload && setUsersReload((prev) => !prev);
      closeModal && closeModal();
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="w-full p-10 px-5 py-10 bg-white rounded-lg md:px-10 lg:px-20">
      <div>
        <h3 className="header">Send push notification</h3>
      </div>
      <div
        onClick={() => closeModal()}
        className="flex items-center gap-4 py-3 cursor-pointer text-textcolor"
      >
        <LongArrowLeftIcon />
        <p>Back to Customer</p>
      </div>

      {!isPreview ? (
        <div className="flex flex-col gap-5 py-5 md:gap-10">
          <Input
            onChange={(e) => setHeading(e.target.value)}
            label="Heading*"
            placeholder="Enter the main heading"
            value={heading}
          />

          <div>
            <label className="text-xs text-left">Receiving Platform</label>
            <div className="flex items-center gap-20 pt-3">
              <CheckBox
                label="Email"
                handleChange={(e) => {
                  e ? setPlatform('Email') : setPlatform('');
                }}
                value={platform}
                checkValue={
                  platform === 'Email' || platform === 'All platforms'
                }
              />

              <CheckBox
                name="In app"
                label="In app"
                handleChange={(e) => {
                  e ? setPlatform('in-app') : setPlatform('');
                }}
                value={platform}
                checkValue={
                  platform === 'in-app' || platform === 'All platforms'
                }
              />
            </div>
          </div>
          <TextArea
            onChange={(e) => setMessage(e.target.value)}
            label="Message*"
            placeholder="Enter message content"
            value={message}
          />

          <div className="w-full mx-auto ">
            <div className="flex justify-center">
              <Button full onClick={handleSubmit}>
                Proceed
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-10 py-10">
          <div className="flex flex-col gap-0.5">
            <p className="text-textcolor">Heading*</p>
            <h3 className="font-bold text-md">{heading}</h3>
          </div>
          <div className="flex flex-col gap-0.5">
            <p className="text-textcolor">Message*</p>
            <h3 className="font-bold text-md">{message}</h3>
          </div>

          <div className="w-7/12">
            <div className="flex gap-4">
              <div className="w-4/12">
                <Button
                  full
                  variant="outline"
                  onClick={() => setIsPreview(false)}
                >
                  Edit
                </Button>
              </div>
              <Button full onClick={sendNotification}>
                Confirm and Send
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PushNotificationForm;
