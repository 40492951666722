import React, { useEffect, useState } from 'react';
import Header from '../../components/Pages/Header';
import { LongArrowLeftIcon, PlusIcon } from '../../assets/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Base/Button';
import { useDashboard } from '../../context/DashboardContext';
import { IUser } from '../../interfaces/user';
import CardSkeleton from '../../components/Base/CardSkeleton';
import { useAuth } from '../../context/AuthContext';
import Modal from '../../components/Base/Modal';
import ConfirmationModal from '../../components/Base/ConfirmationModal';
import useDisableUser from '../../hooks/useDisableUser';
import EditStaffModal from '../../components/Staff/EditStaffModal';
import useBranches from '../../hooks/useBranches';
import StaffInformationTab from '../../components/Staff/StaffInformationTab';
import StaffPaymentRequestsTab from '../../components/Staff/StaffPaymentRequestsTab';
import CustomerProfileCard from '../../components/Customers/CustomerProfileCard';
const Staff = () => {
  const [activeTab, setActiveTab] = useState('Staff Information');
  const navigate = useNavigate();
  let { userId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const { user: currentuser } = useAuth();
  const [user, setUser] = useState<IUser>();
  const [confirmModal, setConfirmModal] = useState(false);
  const { getSingleUser, loading, usersReload } = useDashboard();
  const [editType, setEditType] = useState<'branch' | 'role'>();
  const goToPrevPage = () => {
    navigate('/staffs/');
  };
  const branches = useBranches();
  const fetchDetails = async () => {
    if (!userId) return;
    const { data } = await getSingleUser(userId, 'admin');
    setUser(data);
  };

  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: user?.status ? 'Deactivate' : 'Activate',
    id: user?.id ? user.id : 0,
    isCustomer: false,
    closeModal: () => setConfirmModal(false),
  });

  const getActiveTab = () => {
    if (activeTab === 'Staff Information') {
      if (user) return <StaffInformationTab user={user} />;
    }

    if (activeTab === 'Payment Requests' && userId) {
      return <StaffPaymentRequestsTab userId={userId} />;
    }
  };
  const handleDisableUser = async () => {
    await disableUser();
  };

  useEffect(() => {
    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReload]);
  return (
    <div className="w-full pb-36">
      <Header header=" Staff" subtext="Staff Details">
        {' '}
        <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
          {currentuser?.role === 'Super' && (
            <>
              <Button
                variant="outline"
                onClick={() => {
                  setIsOpen(!isOpen);
                  setEditType('role');
                }}
              >
                Change Role
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  setEditType('branch');
                  setIsOpen(!isOpen);
                }}
              >
                Change Branch
              </Button>
              <Button icon={<PlusIcon />} onClick={() => setConfirmModal(true)}>
                {user?.status ? 'Deactivate Staff' : 'Activate Staff'}
              </Button>
            </>
          )}
        </div>
      </Header>
      <div className="px-8 mt-6">
        <div className="card">
          <div
            onClick={goToPrevPage}
            className="flex items-center gap-4 cursor-pointer text-textcolor"
          >
            <LongArrowLeftIcon />
            <p>Back to staff</p>
          </div>

          {loading ? (
            <CardSkeleton />
          ) : user ? (
            <div className="flex justify-between gap-4 mx-5 my-8 lg:mx-10">
              <div className="flex flex-col items-center gap-4 sm:w-2/6 xl:w-1/6">
                <CustomerProfileCard
                  fullName={user.firstname + ' ' + user.lastname}
                  lastName={user.lastname}
                  email={user.email}
                  phone={user.phone}
                  image={user.profileImage}
                  noButton
                  handleClick={() => setIsOpen(!isOpen)}
                />
              </div>
              <div className="overflow-auto xl:w-9/12">
                <div className="flex flex-wrap items-center justify-between gap-2 p-3 overflow-scroll lg:flex-nowrap card">
                  <div className="flex items-center h-10 gap-4">
                    <p
                      className={`font-bold cursor-pointer break-words  ${
                        activeTab === 'Staff Information'
                          ? 'text-primary'
                          : 'text-textcolor'
                      }`}
                      onClick={() => setActiveTab('Staff Information')}
                    >
                      Staff Information
                    </p>
                    <p
                      className={`font-bold cursor-pointer break-words  ${
                        activeTab === 'Payment Requests'
                          ? 'text-primary'
                          : 'text-textcolor'
                      }`}
                      onClick={() => setActiveTab('Payment Requests')}
                    >
                      Payment Requests
                    </p>
                  </div>
                </div>
                <div className="p-3 mt-3 card">{getActiveTab()}</div>
              </div>

              <Modal
                width="w-[30rem]"
                isOpen={confirmModal}
                onClose={() => setConfirmModal(false)}
              >
                {user && (
                  <ConfirmationModal
                    closeModal={() => setConfirmModal(false)}
                    name={user?.firstname + ' ' + user.lastname}
                    type="staff"
                    confirmAction={handleDisableUser}
                    submitting={isSubmitting}
                    status={user?.status ? 'Deactivate' : 'Activate'}
                  />
                )}{' '}
              </Modal>
              <Modal
                width="w-[30rem]"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              >
                {user && (
                  <EditStaffModal
                    userObj={user}
                    setIsOpen={() => setIsOpen(false)}
                    branches={branches}
                    type={editType}
                  />
                )}
              </Modal>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Staff;
