import { useState, Fragment } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@mantine/core';

const colors = ['#09066B', '#FF991F', '#151411'];

interface Props {
  getColor: (color: string) => void;
  fetchedColor?: string;
}

const PickColor = ({ getColor, fetchedColor }: Props) => {
  const [color, setColor] = useState(colors[0]);

  const handleChange = (val: string) => {
    setColor(val);
    getColor(val);
  };

  return (
    <RadioGroup value={color} onChange={(e) => handleChange(e)}>
      <RadioGroup.Label className="text-sm">Card color</RadioGroup.Label>

      <div className="flex gap-3 pt-1">
        {colors.map((color) => (
          <RadioGroup.Option key={color} value={color} as={Fragment}>
            {({ active, checked }) => (
              <div
                style={{ backgroundColor: color }}
                className={`w-8 relative h-8 cursor-pointer rounded-[50%]`}
              >
                {fetchedColor === color && (
                  <span className="absolute top-2.5 left-2">
                    <CheckIcon color="white" className="w-4 text-xs" />
                  </span>
                )}
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default PickColor;
