import React from 'react';
import Header from '../../components/Pages/Header';
import EditProfileForm from '../../components/Profile/EditProfileForm';

const EditProfile = () => {
  return (
    <div className="w-full pb-20">
      <Header header="Profile" subtext="Update and make profile changes" />
      <div className="px-8 my-6">
        <div className="card">
          <div className="md:w-9/12">
            <EditProfileForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
