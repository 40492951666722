import React from 'react';
import DiscountsView from '../../components/Marketing/DiscountsView';

const Discounts = () => {
  return (
    <div>
      <DiscountsView />
    </div>
  );
};

export default Discounts;
