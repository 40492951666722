import React, { createRef, useState } from 'react';
import { IPromotionalInformation } from '../../interfaces/user';
import { formatDate, getDaysBetweenDates } from '../../functions/formatDate';
import { ThreeDotsIcon } from '../../assets/icons/icons';
import { Transition } from '@headlessui/react';
import DropdownItems from '../Base/DropdownItems';
import useOutsideClick from '../../hooks/useOutsideClicks';
import Modal from '../Base/Modal';
import ConfirmationModal from '../Base/ConfirmationModal';
import { useAuth } from '../../context/AuthContext';
import { useDashboard } from '../../context/DashboardContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import AddNewPromotionForm from './AddNewPromotionForm';

interface Props {
  items: IPromotionalInformation[];
}
const PromotionalInformationTable = ({ items }: Props) => {
  const [obj, setObj] = useState<IPromotionalInformation>();
  const [showDropdown, setShowDropdown] = useState(0);
  const dropdownItemsRef = createRef<HTMLDivElement>();
  const [editModal, setEditModal] = useState(false);
  const [isSubmitting, setIsSubmiting] = useState(false);
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(0));
  const [confirmModal, setConfirmModal] = useState(false);
  const { setUsersReload } = useDashboard();
  const { token } = useAuth();
  const [changeStatus, setChangeStatus] = useState<
    'disable' | 'enable' | 'delete'
  >('disable');
  const handleDropdownOption = (option: string) => {
    if (option === 'Delete slide') {
      setChangeStatus('delete');
      setConfirmModal(true);
    }
    if (option === 'Edit slide') {
      setEditModal(true);
    }
    if (option === 'Deactivate' || option === 'Activate') {
      setConfirmModal(true);
      setChangeStatus(option === 'Deactivate' ? 'disable' : 'enable');
    }
  };

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };

  const handleAction = async () => {
    setIsSubmiting(true);
    const body = {
      type: changeStatus,
      id: obj?.id,
    };

    try {
      await axiosInstance.post(
        `marketing/promotion-action/${changeStatus}/${obj?.id}`,
        body,
        options
      );

      toast.success(`Promotion changed successfully`);
      setUsersReload && setUsersReload((prev) => !prev);
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
      return error;
    }
  };
  return (
    <div className="overflow-x-auto bg-white ">
      <table className="w-full mb-32 text-sm text-left">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl whitespace-nowrap">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              header
            </th>
            <th scope="col" className="px-6 py-3">
              IMAGE DESCRIPTION
            </th>
            <th scope="col" className="px-6 py-3">
              DURATION
            </th>
            <th scope="col" className="px-6 py-3">
              DATE CREATED
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3">
              ACTION
            </th>
          </tr>
        </thead>

        <tbody className="text-sm">
          {items &&
            items.map((item, index) => (
              <tr className="relative whitespace-nowrap" key={item.id}>
                <td className="px-6 py-4">{index + 1}</td>
                <th scope="row" className="px-6 py-4 font-normal">
                  {item.title}
                </th>
                <td className="px-6 py-4 capitalize">
                  {item.body ? item.body : 'Nill '}
                </td>

                <td className="px-6 py-4">
                  {getDaysBetweenDates(
                    item.start_date.split('T')[0],
                    item.end_date.split('T')[0]
                  )}{' '}
                  days
                </td>
                <td className="px-6 py-4">{formatDate(item.createdAt)}</td>
                <td className="px-6 py-4 ">
                  <div
                    className={`status  ${
                      item.status ? 'active' : 'inactive'
                    } `}
                  >
                    {item.status ? 'active' : 'inactive'}
                  </div>
                </td>
                <td className="px-4 py-4 cursor-pointer f">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setObj(item);
                      setShowDropdown(item.id);
                    }}
                    className="flex items-center justify-center w-full h-8 "
                  >
                    <ThreeDotsIcon />
                  </div>
                </td>
                <td>
                  <Transition
                    show={showDropdown === item.id}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    ref={dropdownItemsRef}
                  >
                    <DropdownItems
                      isOpen={showDropdown === item.id}
                      options={[
                        'Delete slide',
                        'Edit slide',
                        item.status ? 'Deactivate' : 'Activate',
                      ]}
                      onChange={(option) => handleDropdownOption(option)}
                      onSelected={() => setShowDropdown(0)}
                    />
                  </Transition>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        width="w-[40rem]"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
      >
        {obj && (
          <ConfirmationModal
            closeModal={() => setConfirmModal(false)}
            name={obj.title}
            type="promotion"
            confirmAction={handleAction}
            submitting={isSubmitting}
            status={changeStatus}
          />
        )}{' '}
      </Modal>
      <Modal
        width="w-[40rem]"
        isOpen={editModal}
        onClose={() => setEditModal(false)}
      >
        {obj && (
          <AddNewPromotionForm
            closeModal={() => setEditModal(false)}
            values={obj}
            isEdit
          />
        )}{' '}
      </Modal>
    </div>
  );
};

export default PromotionalInformationTable;
