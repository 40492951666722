/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import Input from '../Base/Input';
import Button from '../Base/Button';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import Success from './../../assets/images/Success.svg';
import { useDashboard } from '../../context/DashboardContext';
import { IUser } from '../../interfaces/user';
interface Props {
  setIsOpen: () => void;
  userEmail?: string;
  type?: 'repush' | 'refund';
  userName?: string;
  user?: IUser;
}

const RepushTransactionModal = ({
  userEmail,
  setIsOpen,
  type = 'repush',
  userName,
  user,
}: Props) => {
  const { token } = useAuth();
  const { setUsersReload } = useDashboard();

  const [value, setValue] = useState('');
  const [secondValue, setSecondValue] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [successful, setSuccessful] = useState(false);
  useEffect(() => {
    if (value && secondValue) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [secondValue, value, isAllSelected]);
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const sendPaymentRequest = async () => {
    setSubmitting(true);
    try {
      const repushbody = {
        session_id: value,
        settlement_id: secondValue,
      };
      const refundbody = {
        transactionRef: value,
        actualAmount: secondValue,
      };

      const url = type === 'repush' ? '/providus/repush' : 'user/refund';
      await axiosInstance.post(
        url,
        type === 'repush' ? repushbody : refundbody,
        options
      );

      toast.success(' Sent successfully');
      setSuccessful(true);
      setSubmitting(false);
      setUsersReload && setUsersReload((prev) => !prev);
      setTimeout(() => {
        setIsOpen();
      }, 2000);
    } catch (err) {
      setSubmitting(false);
      return err;
    }
  };

  return (
    <div className="gap-10 mx-auto bg-white h-fit rounded-xl ">
      {!successful ? (
        <div className="p-3">
          <div className="flex justify-end">
            <span
              onClick={setIsOpen}
              className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
            >
              <XMarkIcon />
            </span>
          </div>

          <div className="flex justify-center">
            <h1 className="text-center header">
              {type === 'repush' ? 'Retry Transaction' : 'Refund Transaction'}
            </h1>
          </div>

          <div className="flex flex-col w-full gap-4 px-3 my-8 md:px-8">
            <Input
              label={type === 'repush' ? 'Session ID*' : 'Transaction Ref*'}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />

            <Input
              label={type === 'repush' ? 'Settlement ID*' : 'Actual Amount*'}
              value={secondValue}
              type="number"
              onChange={(e) => setSecondValue(e.target.value)}
            />

            <div className="mt-4">
              <Button
                full
                onClick={sendPaymentRequest}
                variant={isAllSelected ? 'primary' : 'gray'}
                disabled={!isAllSelected || submitting}
                isSubmitting={submitting}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3">
          <div className="flex flex-col items-center gap-3 pt-20">
            <img src={Success} alt="" />

            <p className="text-2xl font-bold text-center">n Successful</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepushTransactionModal;
