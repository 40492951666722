import React, { createRef, useState } from 'react';
import { ThreeDotsIcon, XMarkIcon } from '../../assets/icons/icons';
import useOutsideClick from '../../hooks/useOutsideClicks';
import DropdownItems from '../Base/DropdownItems';
import { IBranch } from '../../interfaces/user';
import { Transition } from '@headlessui/react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../Base/ConfirmationModal';
import Modal from '../Base/Modal';
import useDisableUser from '../../hooks/useDisableUser';
import AddBranchForm from '../Store/AddBranchForm';
import UpdateProfilePictureModal from '../Profile/UpdateProfilePictureModal';
interface Props {
  items: IBranch[];
}
const BranchTable = ({ items }: Props) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(0);
  const { user: currentuser } = useAuth();
  const [branchObj, setBranchObj] = useState<IBranch>();
  const dropdownItemsRef = createRef<HTMLDivElement>();
  const [showImageModal, setShowImageModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changeStatus, setChangeStatus] = useState<'Deactivate' | 'Activate'>(
    'Deactivate'
  );
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(0));
  const getDropdownRoles = () => {
    if (!currentuser) return;
    let options: string[] = [];
    if (currentuser.role === 'Super') {
      options = ['View', 'Edit', 'Change Image', 'Deactivate', 'Activate'];
    } else {
      options = ['View'];
    }
    return options;
  };

  const handleDropdownOption = (option: string) => {
    if (option === 'View') navigate(`/store_locator/${showDropdown}`);
    if (option === 'Change Image') {
      setShowImageModal(true);
    }
    if (option === 'Edit') {
      setEditModal(true);
    }
    if (option === 'Deactivate' || option === 'Activate') {
      setConfirmModal(true);
      setChangeStatus(option);
    }
  };
  const { disableUser, isSubmitting } = useDisableUser({
    changeStatus: changeStatus,
    id: branchObj?.id ? branchObj.id : 0,
    isBranch: true,
  });
  const handleDisableUser = () => {
    disableUser();
    setConfirmModal(false);
  };
  return (
    <div className="relative pb-32 mb-6 overflow-x-auto bg-white">
      <table className="w-full text-sm text-left ">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              BRANCH NaME
            </th>
            <th scope="col" className="px-6 py-3">
              LOCATION
            </th>
            <th scope="col" className="px-6 py-3">
              assigned STAFFS
            </th>
            <th scope="col" className="px-6 py-3">
              status
            </th>
            <th scope="col" className="px-6 py-3">
              ACTIONS
            </th>
          </tr>
        </thead>
        {items.length ? (
          <tbody className="text-sm">
            {items.map((item) => (
              <tr className="cursor-pointer whitespace-nowrap" key={item.id}>
                <td className="px-6 py-4">{item.id}</td>
                <th scope="row" className="px-6 py-4 font-normal">
                  {item.name}
                </th>
                <td className="px-6 py-4 capitalize">{item.location}</td>
                <td className="px-6 py-4">{item._count?.staffs}</td>
                <td className="px-6 py-4 ">
                  <span
                    className={`status  ${
                      item.status ? 'active' : 'inactive'
                    } `}
                  >
                    {item.status ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td className="px-4 py-4 cursor-pointer">
                  <span
                    ref={dropdownItemsRef}
                    className="flex items-center justify-start w-full h-8 ml-4 "
                    onClick={(e) => {
                      e.stopPropagation();
                      setBranchObj(item);
                      setShowDropdown(item.id);
                    }}
                  >
                    <ThreeDotsIcon />
                  </span>
                </td>

                <td>
                  <Transition
                    show={showDropdown === item.id}
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    ref={dropdownItemsRef}
                  >
                    <DropdownItems
                      isOpen={showDropdown === item.id}
                      onChange={(option) => handleDropdownOption(option)}
                      options={getDropdownRoles()}
                      onSelected={() => setShowDropdown(0)}
                    />
                  </Transition>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </table>
      <Modal
        width="w-[30rem]"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
      >
        {branchObj && (
          <ConfirmationModal
            closeModal={() => setConfirmModal(false)}
            name={branchObj?.name}
            type="branch"
            confirmAction={handleDisableUser}
            submitting={isSubmitting}
            status={changeStatus}
          />
        )}{' '}
      </Modal>
      <Modal
        width="w-[40rem]"
        isOpen={editModal}
        onClose={() => setEditModal(false)}
      >
        {branchObj && (
          <div className="bg-[#F8F8F8]  mx-auto gap-5 h-fit  rounded-xl ">
            <div className="p-3">
              <div className="flex justify-end">
                <span
                  onClick={() => setEditModal(false)}
                  className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
                >
                  <XMarkIcon />
                </span>
              </div>
              <div className="flex justify-center">
                <h1 className="text-center header">Edit Branch</h1>
              </div>
              <div className="px-10 pb-10">
                <AddBranchForm
                  isEdit
                  branchObj={branchObj}
                  setIsOpen={() => setEditModal(false)}
                />
              </div>
            </div>
          </div>
        )}{' '}
      </Modal>
      <Modal width="w-[30rem]" isOpen={showImageModal}>
        {branchObj && (
          <UpdateProfilePictureModal
            isBranch
            branchObj={branchObj}
            setIsOpen={() => setShowImageModal(false)}
          />
        )}
      </Modal>
    </div>
  );
};

export default BranchTable;
