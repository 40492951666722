import React, { useState } from 'react';
import TextArea from '../Base/TextArea';
import Input from '../Base/Input';
import Button from '../Base/Button';
import { Formik, Field, Form } from 'formik';
import { addPromotionFormSchema } from '../../interfaces/forms';
import InputValidationError from '../Base/InputValidationError';
import PickColor from './PickColor';
import DatePicker from '../Base/DatePicker';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axios';
import { IPromotionalInformation } from '../../interfaces/user';
import { useDashboard } from '../../context/DashboardContext';
import { useNavigate } from 'react-router-dom';

interface Props {
  closeForm?: () => void;
  closeModal?: () => void;
  values?: IPromotionalInformation;
  isEdit?: boolean;
}

const AddNewPromotionForm = ({
  isEdit,
  values,
  closeForm,
  closeModal,
}: Props) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { setUsersReload } = useDashboard();
  const [color, setColor] = useState(values?.color ? values?.color : '#09066B');
  return (
    <div className="w-full p-10 py-16 bg-white rounded-lg lg:px-20">
      <div>
        <h3 className="header">
          {isEdit ? 'Edit Promotion' : 'Add New Promotions'}
        </h3>
      </div>

      <div
        className={`flex flex-col gap-10 py-10 ${
          isEdit ? 'w-full' : 'lg:w-9/12'
        }`}
      >
        <Formik
          initialValues={{
            header: isEdit ? values?.title : '',
            description: isEdit ? values?.body : '',
            startDate: isEdit ? values?.start_date : '',
            endDate: isEdit ? values?.end_date : '',
          }}
          validationSchema={addPromotionFormSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const options = {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            };
            try {
              const body = {
                title: data.header,
                body: data.description,
                start_date: data.startDate,
                end_date: data.endDate,
                colour: color,
              };

              if (isEdit) {
                await axiosInstance.post(
                  `marketing/promotion/update/${values?.id}`,
                  body,
                  options
                );
                toast.success(' Promotion Edited Successfully');
              } else {
                await axiosInstance.post(
                  'marketing/promotion/create',
                  body,
                  options
                );
                toast.success('New Promotion Created Successfully');
                navigate('/marketing');
              }
              setUsersReload && setUsersReload((prev) => !prev);

              setSubmitting(false);
            } catch (err) {
              return err;
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, values }) => (
            <Form>
              <div className="flex flex-col space-y-8">
                <div className="w-full">
                  <Field
                    name="header"
                    as={Input}
                    label="Header*"
                    placeholder="E.g 50% off "
                  />
                  {errors.header && touched.header ? (
                    <InputValidationError text={errors.header} />
                  ) : null}
                </div>
                <div className="w-full">
                  <Field
                    name="description"
                    as={TextArea}
                    label="Description*"
                    placeholder="Enter promotion details"
                  />
                  {errors.description && touched.description ? (
                    <InputValidationError text={errors.description} />
                  ) : null}
                </div>

                <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                  <div className="md:w-3/6">
                    <Field
                      name="startDate"
                      type="startDate"
                      as={DatePicker}
                      blockPrevDate
                      label="Start Date*"
                      regularInput
                      editValue={values.startDate}
                      onChange={(data: { name: string; id: number }) => {
                        const newdata = {
                          target: {
                            name: 'startDate',
                            value: data,
                          },
                        };
                        handleChange(newdata);
                      }}
                    />
                    {errors.startDate && touched.startDate ? (
                      <InputValidationError text={errors.startDate} />
                    ) : null}
                  </div>
                  <div className="md:w-3/6">
                    <Field
                      name="endDate"
                      type="endDate"
                      as={DatePicker}
                      editValue={values.endDate}
                      blockPrevDate
                      label="End Date*"
                      onChange={(data: { name: string; id: number }) => {
                        const newdata = {
                          target: {
                            name: 'endDate',
                            value: data,
                          },
                        };
                        handleChange(newdata);
                      }}
                      regularInput
                    />
                    {errors.endDate && touched.endDate ? (
                      <InputValidationError text={errors.endDate} />
                    ) : null}
                  </div>
                </div>

                <PickColor
                  getColor={(val) => setColor(val)}
                  fetchedColor={color}
                />
                <div className="lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <Button
                      variant="outline"
                      onClick={
                        isEdit ? closeModal : () => navigate('/marketing')
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {isSubmitting
                        ? ` ${isEdit ? 'Editing' : 'Creating'} Promotion...`
                        : ` ${isEdit ? 'Edit' : 'Add'} Promotion`}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddNewPromotionForm;
