import React, { Fragment, createRef } from 'react';
import { SideBarRoutes } from '../data/SidebarRoutes';
import { Link, useLocation } from 'react-router-dom';
import { LogoutIcon } from '../assets/icons/icons';
import SubNavigation from './SubNavigation';
import { useAuth } from '../context/AuthContext';
import MobileSidebar from './MobileSidebar';
import { Transition } from '@headlessui/react';
import useOutsideClick from '../hooks/useOutsideClicks';
import { useApp } from '../context/AppContext';

const SideBar = () => {
  const { pathname } = useLocation();
  const { user, logoutUser } = useAuth();
  const { mobileMenu, setMobileMenu } = useApp();

  const handleLogout = () => {
    logoutUser();
  };
  const transitionRef = createRef<HTMLDivElement>();
  useOutsideClick(transitionRef, () => setMobileMenu(false));
  return (
    <>
      <nav
        className={`mt-[3.3rem] hidden md:block relative min-w-[15rem] duration-500 h-screen overflow-y-auto p-2 bg-white`}
      >
        <div className="p-3 mt-5">
          {SideBarRoutes.map((route, index) =>
            user?.role === 'Sales' && route.hideStaff ? null : (
              <Fragment key={index}>
                <div
                  className={` ${route.hasGap ? 'mt-5' : 'mt-0'} ${
                    pathname === route.path &&
                    'bg-textcolor bg-opacity-10 rounded-md'
                  } flex items-center my-2 py-2 px-3 text-left cursor-pointer gap-x-3 `}
                >
                  <span> {route.icon}</span>
                  <Link
                    to={route.path ? route.path : '/'}
                    className="medparagraph"
                  >
                    {route.name}
                  </Link>
                </div>
                <div>
                  {route.subtitle && (
                    <SubNavigation
                      title={route.subtitle}
                      // subRoutes={user?.role === 'Manager' && route.titles}
                      subRoutes={route.titles}
                    />
                  )}
                  {/* {route.subtitle &&
                  !(user?.role === 'Manager' && route.hideStaff) && (
                    <SubNavigation
                      title={route.subtitle}
                      subRoutes={route.titles}
                    />
                  )} */}
                </div>
              </Fragment>
            )
          )}
          <div
            onClick={handleLogout}
            className="flex items-center gap-4 px-3 py-2 mt-4 rounded-md cursor-pointer bg-red-50"
          >
            <LogoutIcon />
            <p className="font-medium text-red-500">Log out</p>
          </div>
        </div>
      </nav>

      <Transition
        ref={transitionRef}
        show={mobileMenu}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {<MobileSidebar />}
      </Transition>
    </>
  );
};

export default SideBar;
