import React from 'react';
import UserDetailCard from '../UserDetailCard';
import { useDashboard } from '../../context/DashboardContext';
import { formatDate } from '../../functions/formatDate';

const CustomerInformationTab = () => {
  const { user } = useDashboard();

  return (
    <div className="pb-20">
      <div className="py-10">
        <hr />
      </div>

      {user && (
        <div className="px-4">
          <UserDetailCard
            details={[
              {
                title: 'Username ',
                value: user.efgTag ? user.efgTag : 'Nil',
              },
              {
                title: 'Phone',
                value: '+' + user.phone,
              },
              {
                title: 'Email Address',
                value: user.email,
              },
              {
                title: 'Customer ID',
                value: user.id,
              },
              {
                title: 'Device',
                value: user.currentDeviceName,
              },
              {
                title: 'Account Name',
                value: user.accountName ? user.accountName : 'Not Available',
              },
              {
                title: 'Reg. Date',
                value: formatDate(user.createdAt),
              },
              {
                title: 'Status',
                value: user.Status ? 'active' : 'inactive',
              },
              {
                title: 'Account Number',
                value: user.accountNumber ? user.accountNumber : 'Nil',
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default CustomerInformationTab;
