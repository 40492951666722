import { Menu, Transition } from '@headlessui/react';
interface Props {
  options: string[] | undefined;
  title?: string;
  onChange?: (val: string) => void;
  isOpen?: boolean;
  onSelected?: () => void;
  isPage?: boolean;
}
const DropdownItems = ({
  onSelected,
  isOpen,
  onChange,
  options,
  title = 'Duration',
  isPage,
}: Props) => {
  const handleSelect = (item: string) => {
    onChange && onChange(item);
    onSelected && onSelected();
  };

  return (
    <Menu
      as="div"
      className={`${
        isPage ? 'absolute right-10 top-24' : 'relative'
      } inline-block text-left`}
    >
      <>
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Menu.Items className="absolute top-0 right-0 z-30 bg-white border w-44 border-gray2 border-opacity-40">
            {options &&
              options.map((item) => (
                <Menu.Item key={item}>
                  {({ active }) => (
                    <div
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } capitalize p-3 text-sm font-bold text-center border-b cursor-pointer hover:opacity-40 border-gray2 border-opacity-40`}
                      onClick={() => {
                        handleSelect(item);
                      }}
                    >
                      {item}
                    </div>
                  )}
                </Menu.Item>
              ))}
          </Menu.Items>
        </Transition>
      </>
    </Menu>
  );
};

export default DropdownItems;
