import { useEffect, useMemo, useState } from 'react';

import Dropdown from '../../components/Base/Dropdown';
import SearchInput from '../../components/Base/SearchInput';
import TransactionsTable from '../../components/Tables/TransactionsTable';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { useDashboard } from '../../context/DashboardContext';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IBranch, ITransaction } from '../../interfaces/user';
import TableSkeleton from '../../components/Base/TableSkeleton';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import Modal from '../../components/Base/Modal';
import { EmptyStateIcon } from '../../assets/icons/icons';
import TotalBox from './TotalBox';
import { useDebouncedValue } from '@mantine/hooks';

interface Props {
  userId: string;
}
const CustomerTransactionsTab = ({ userId }: Props) => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const { usersReload } = useDashboard();
  const currentdate = formatTomorrow();
  // const branches = useBranches();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [filteredStatus, setFilteredStatus] = useState('All');
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [meta, setMeta] = useState({
    totalReq: 0,
    successfulReq: 0,
    failedReq: 0,
  });

  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 10,
    userId,
    search_term: '',
    page_number: 0,
    // status: '',
    branch: 0,
  });

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }

    if (type === 'sort') {
      console.log(event);
      typeof event === 'string' && setFilteredStatus(event);
    }
    if (type === 'branch') {
      if (typeof event === 'string') return;
      setFilteredStatus(event.name);
    }
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };

  const fetchTransactions = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('/transactions/user', {
        headers,
        params,
      });

      setTransactions(data.data);
      setMeta((prev) => ({
        ...prev,
        totalReq: data.totalReq,
        successfulReq: data.successfulReq,
        failedReq: data.failedReq,
      }));
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };

  const filteredData = useMemo(() => {
    return transactions.filter((item) => {
      if (filteredStatus === 'Successful') {
        return item.status.toLowerCase().includes(filteredStatus.toLowerCase());
      } else {
        if (filteredStatus === 'Pending') {
          return item.status
            .toLowerCase()
            .includes(filteredStatus.toLowerCase());
        } else {
          if (filteredStatus === 'Failed') {
            return item.status
              .toLowerCase()
              .includes(filteredStatus.toLowerCase());
          } else {
            return item;
          }
        }
      }
    });
  }, [transactions, filteredStatus]);
  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  return (
    <section className="w-full pb-20">
      <div className="px-2">
        <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3 ">
          <TotalBox text={'Total Transactions'} number={meta.totalReq} />
          <TotalBox
            text={'Successful Transactions'}
            number={meta.successfulReq}
          />
          <TotalBox text={'Failed Transactions'} number={meta.failedReq} />
        </div>
        <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
          <FilterMonthModal
            setIsOpen={() => setShowFilterMonthModal(false)}
            getStartDate={(val) => {
              handleCustomDates(val, 'start_date');
            }}
            getEndDate={(val) => {
              handleCustomDates(val, 'end_date');
            }}
          />
        </Modal>
        <div className="mt-10 card">
          <div className="flex flex-col justify-between gap-3 sm:flex-row">
            <div className="xmd:w-2/6">
              <SearchInput
                placeholder="Search transactions..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Dropdown
              options={['5', '10', '20', '30', '50', '70', '100', '120']}
              text="10"
              title="Table Size"
              onChange={(val) => {
                handleChange(val, 'size');
              }}
            />
          </div>

          <div className="mt-8">
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : filteredData.length ? (
              <TransactionsTable items={transactions} />
            ) : (
              <div className="grid gap-2 mx-auto place-items-center">
                <EmptyStateIcon />
                <p>No Transactions </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerTransactionsTab;
