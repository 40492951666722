import React, { useEffect, useState } from 'react';
import ActivePromotionBox from './ActivePromotionBox';
import Dropdown from '../Base/Dropdown';
import SearchInput from '../Base/SearchInput';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { formatTomorrow } from '../../functions/formatDate';
import { useDashboard } from '../../context/DashboardContext';
import { IBranch, IPromotionalInformation } from '../../interfaces/user';
import PromotionalInformationTable from './PromotionalInformationTable';
import TableSkeleton from '../Base/TableSkeleton';
import { useDebouncedValue } from '@mantine/hooks';
import Pagination from '../Base/Pagination';
import Button from '../Base/Button';
import downloadExcel from '../../functions/downloadExcel';

// interface Props {
//   openForm: () => void;
// }
const PromotionalInformation = () => {
  const { token } = useAuth();
  const currentdate = formatTomorrow();
  const [loading, setLoading] = useState(false);
  const { usersReload } = useDashboard();
  const [items, setItems] = useState<IPromotionalInformation[]>([]);
  const [dynamicItems, setDynamicItems] = useState<IPromotionalInformation[]>(
    []
  );
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
    search_term: '',
  });
  const [meta, setMeta] = useState({ total: 0, active: 0, inactive: 0 });
  const fetchPromotionalInformation = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('marketing/get-all-promotions', {
        headers,
        params,
      });
      setLastPage(data.meta?.lastPage);
      setCurrentPage(data.meta.currentPage);
      setMeta((obj) => ({
        ...obj,
        total: data?.totalPromotion,
        active: data?.activePromotion,
        inactive: data?.inactivePromotion,
      }));
      setItems(data.data.data);
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };
  const fetchDynamicPromotionalList = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get(
        'marketing/dynamic-promotion-list',
        {
          headers,
          params,
        }
      );

      setDynamicItems(data.data);
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };

  const handleChange = (
    event: string | number | IBranch,
    type: string = 'date'
  ) => {
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };

  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  useEffect(() => {
    fetchPromotionalInformation();
    fetchDynamicPromotionalList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  const handleDownload = () => {
    downloadExcel(items, 'Promotions');
  };

  return (
    <div className="pb-20">
      <h3 className="text-lg font-bold">Active Promotions({meta.active})</h3>
      <div>
        <div className="grid gap-5 my-10 xs:grid-cols-2 lg:grid-cols-3">
          {dynamicItems &&
            dynamicItems
              .slice(0, 3)
              .map((item, index) => (
                <ActivePromotionBox
                  key={item.id}
                  color={item.color}
                  header={item.title}
                  description={item.body}
                  enddate={item.end_date}
                  startdate={item.start_date}
                />
              ))}
        </div>
        <div className="mt-10">
          <h3 className="text-lg font-bold">Promotions history</h3>

          <div className="mt-4 card">
            <div className="flex flex-col justify-between gap-3 mb-10 sm:flex-row">
              <div className="sm:w-3/6 xmd:w-2/6">
                <SearchInput
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
                <Button variant="outline" onClick={handleDownload}>
                  Download CSV
                </Button>
                <Dropdown
                  options={['5', '10', '20', '30', '50', '70', '100', '120']}
                  text="10"
                  title="Table Size"
                  onChange={(val) => {
                    handleChange(val, 'size');
                  }}
                />{' '}
              </div>
            </div>
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : (
              items && <PromotionalInformationTable items={items} />
            )}

            <Pagination
              handleMoreClicks={(val) => handleMoreClicks(val)}
              lastPage={lastPage}
              handlePageClick={handlePageClick}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionalInformation;
