import { Formik, Field, Form } from 'formik';
import React, { useState } from 'react';
import { pushNotificationFormSchema } from '../../interfaces/forms';
import Button from '../Base/Button';
import Input from '../Base/Input';
import InputValidationError from '../Base/InputValidationError';
import { useNavigate } from 'react-router-dom';
import Select from '../Base/Select';
import { useAuth } from '../../context/AuthContext';
import { toast } from 'react-toastify';
import axiosInstance from '../../api/axios';
import TextArea from '../Base/TextArea';
import CheckBox from '../Base/CheckBox';
import Modal from '../Base/Modal';
import PreviewNotification from './PreviewNotification';
import { INotification } from '../../interfaces/user';
import { useDashboard } from '../../context/DashboardContext';

interface Props {
  isEdit?: boolean;
  editData?: INotification;
  closeModal?: () => void;
}

const AddNotificationForm = ({ closeModal, isEdit, editData }: Props) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [obj, setObj] = useState<{
    title: string | undefined;
    content: string | undefined;
    type: string | undefined;
    platform: string | undefined;
  }>();
  const { setUsersReload } = useDashboard();
  const goToPrevPage = () => {
    navigate('/push_notification');
  };
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const sendNotification = async () => {
    try {
      await axiosInstance.post(
        'push-notification/admin/send-to-all',
        obj,
        options
      );
      toast.success('Push Notification sent successfully');

      goToPrevPage();
      setShowPreviewModal(false);
      setUsersReload && setUsersReload((prev) => !prev);
      closeModal && closeModal();
    } catch (err) {
      return err;
    }
  };
  const [platform, setPlatform] = useState(isEdit ? editData?.platform : '');
  return (
    <div>
      <div className="pb-20 md:px-10">
        <Formik
          initialValues={{
            header: isEdit ? editData?.title : '',
            description: isEdit ? editData?.description : '',
            type: '',
          }}
          validationSchema={pushNotificationFormSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const body = {
              title: data.header,
              content: data.description,
              type: data.type,
              platform,
            };
            setObj(body);
            setShowPreviewModal(true);

            return;
          }}
        >
          {({ isSubmitting, errors, touched, handleChange, values }) => (
            <Form>
              <div
                className={`flex flex-col ${
                  isEdit ? 'w-full space-y-4 ' : 'lg:w-8/12 space-y-8 '
                } `}
              >
                <div className="w-full">
                  <Field
                    name="header"
                    as={Input}
                    label="Notification’s Header*"
                    placeholder="Enter notifications header"
                  />
                  {errors.header && touched.header ? (
                    <InputValidationError text={errors.header} />
                  ) : null}
                </div>

                <div className="w-full">
                  <Field
                    name="description"
                    as={TextArea}
                    label="Notification’s Description*"
                    placeholder="Enter the notifications description"
                  />
                  {errors.description && touched.description ? (
                    <InputValidationError text={errors.description} />
                  ) : null}
                </div>

                <div>
                  <div className="flex items-center gap-20">
                    <CheckBox
                      label="All platforms"
                      handleChange={(e) => {
                        e ? setPlatform('All platforms') : setPlatform('');
                      }}
                      value={platform}
                      checkValue={platform === 'All platforms'}
                    />
                    <CheckBox
                      name="ios"
                      label="IOS"
                      handleChange={(e) => {
                        e ? setPlatform('IOS') : setPlatform('');
                      }}
                      value={platform}
                      checkValue={
                        platform === 'IOS' || platform === 'All platforms'
                      }
                    />
                    <CheckBox
                      name="android"
                      label="Android"
                      handleChange={(e) => {
                        e ? setPlatform('Andrioid') : setPlatform('');
                      }}
                      checkValue={
                        platform === 'Andrioid' || platform === 'All platforms'
                      }
                      value={platform}
                    />
                  </div>
                </div>
                <div>
                  <Field
                    name="type"
                    type="type"
                    as={Select}
                    label="Type*"
                    options={['in-app', 'Email']}
                    value={values.type}
                    onChange={(event: { name: string; id: number }) => {
                      const newEvent = {
                        target: {
                          name: 'type',
                          value: event,
                        },
                      };
                      handleChange(newEvent);
                    }}
                  />
                  {errors.type && touched.type ? (
                    <InputValidationError text={errors.type} />
                  ) : null}
                </div>

                <div className="lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <Button
                      variant="outline"
                      onClick={isEdit ? closeModal : goToPrevPage}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {isSubmitting ? 'Sending....' : ' Send Push Notification'}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>

        <Modal
          width="w-[30rem]"
          isOpen={showPreviewModal}
          onClose={() => setShowPreviewModal(false)}
        >
          {obj && (
            <PreviewNotification
              onSubmit={sendNotification}
              obj={obj}
              setIsOpen={() => setShowPreviewModal(false)}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AddNotificationForm;
