import React from 'react';

interface Props {
  text: string;
  emoji: string;
  handleClick?: () => void;
}

const QuickActionsCard = ({ handleClick, text, emoji }: Props) => {
  return (
    <div
      onClick={handleClick}
      className="px-3 py-2 rounded-lg cursor-pointer bg-purple60 bg-opacity-20"
    >
      <p className="flex gap-2 text-sm font-medium text-left text-purple60">
        <span> {emoji}</span>
        <span> {text}</span>
      </p>
    </div>
  );
};

export default QuickActionsCard;
