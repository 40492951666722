import { Formik, Field, Form } from 'formik';
import React, { useState } from 'react';
import { addBranchValidationSchema } from '../../interfaces/forms';
import Button from '../Base/Button';
import Input from '../Base/Input';
import InputValidationError from '../Base/InputValidationError';
import FileUploadView from '../Base/FileUploadView';
import { useDashboard } from '../../context/DashboardContext';
import { useNavigate } from 'react-router-dom';
import { IBranch } from '../../interfaces/user';

interface Props {
  isEdit?: boolean;
  branchObj?: IBranch;
  setIsOpen?: () => void;
}
const AddBranchForm = ({ isEdit, branchObj, setIsOpen }: Props) => {
  const navigate = useNavigate();
  const { createBranch, setUsersReload } = useDashboard();
  const [file, setFile] = useState<any>();
  const goToPrevPage = () => {
    navigate('/store_locator/');
  };

  return (
    <div>
      <div className="py-5 ">
        {!isEdit && (
          <FileUploadView
            setFile={(file) => {
              setFile(file);
            }}
            text="Branch Image"
          />
        )}
      </div>
      <div className="md:px-10">
        <Formik
          initialValues={{
            branchname: isEdit ? branchObj?.name : '',
            location: isEdit ? branchObj?.location : '',
          }}
          validationSchema={addBranchValidationSchema}
          onSubmit={async (data, { setSubmitting }) => {
            const formData = new FormData();
            setSubmitting(true);
            formData.append(
              'branch_name',
              data.branchname ? data.branchname : ''
            );
            formData.append('location', data.location ? data.location : '');
            if (!isEdit) {
              formData.append('branch_image', file);
            }

            if (isEdit) {
              await createBranch(
                {
                  branch_name: data.branchname ? data.branchname : '',
                  location: data.location ? data.location : '',
                },
                true,
                branchObj?.id
              );
              setIsOpen && setIsOpen();
              setUsersReload && setUsersReload((prev) => !prev);
            } else if (!isEdit) {
              await createBranch(formData);
            }

            goToPrevPage();
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <div className="flex flex-col space-y-8">
                <div className="flex flex-col gap-5 ">
                  <div className="w-full">
                    <Field
                      name="branchname"
                      as={Input}
                      label="Branch’s Name*"
                      placeholder="Enter branch name"
                    />
                    {errors.branchname && touched.branchname ? (
                      <InputValidationError text={errors.branchname} />
                    ) : null}
                  </div>
                  <div className="w-full">
                    <Field
                      name="location"
                      as={Input}
                      label="Branch’s Location*"
                      placeholder="Enter branch name"
                    />
                    {errors.location && touched.location ? (
                      <InputValidationError text={errors.location} />
                    ) : null}
                  </div>
                </div>

                <div className="lg:w-2/6">
                  <div className="flex items-center gap-5">
                    <Button variant="outline" onClick={goToPrevPage}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      isSubmitting={isSubmitting}
                    >
                      {isSubmitting
                        ? '...'
                        : `${!isEdit ? 'Add' : 'Edit'} Branch`}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddBranchForm;
