import React from 'react';
import { InfoIcon } from '../../assets/icons/icons';

const InputValidationError = ({ text }: { text: string }) => {
  return (
    <div className="flex items-center text-[9px] text-red-600 space-x-1 pt-1">
      <span className="text-red-600">
        <InfoIcon />
      </span>
      <p>{text}</p>
    </div>
  );
};

export default InputValidationError;
