import { PhoneIcon } from '../../assets/icons/icons';

interface Props {
  deviceName?: string;
  deviceId?: string;
}

const DeviceInfo = ({ deviceName, deviceId }: Props) => {
  return (
    <div className="w-full p-3 mt-4 bg-white rounded">
      <div className="flex items-center gap-2">
        <PhoneIcon />
        <p className="text-xs">Device info</p>
      </div>
      <div className="px-2 py-5">
        <div className="flex items-center justify-between my-2">
          <p className="text-[11px]">Device name</p>
          <p className="text-[11px]">{deviceName}</p>
        </div>
        <div className="flex items-center justify-between my-2">
          <p className="text-[11px]">Device ID</p>
          <p className="text-[11px]">{deviceId}</p>
        </div>
      </div>
    </div>
  );
};

export default DeviceInfo;
