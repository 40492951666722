import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../Base/Button';

const CancelButton = () => {
  const navigate = useNavigate();
  const goToPrevPage = () => {
    navigate(-1);
  };
  return (
    <Button variant="outline" onClick={goToPrevPage}>
      Cancel
    </Button>
  );
};

export default CancelButton;
