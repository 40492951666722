import React, { useEffect, useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import SideBar from '../components/Sidebar';
import Navigation from '../components/Navigation';
import { useAuth } from '../context/AuthContext';
import DashboardContextProvider from '../context/DashboardContext';
import { useApp } from '../context/AppContext';
import { toast } from 'react-toastify';
import { usePusher } from '../hooks/usePusher';

interface Props {
  children?: React.ReactNode;
}

const DefaultLayout = ({ children }: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [audio] = useState(
    new Audio('https://bigsoundbank.com/UPLOAD/mp3/0382.mp3')
  );
  const { setMobileMenu, mobileMenu } = useApp();
  useEffect(() => {
    localStorage.removeItem('tempEmail');
    if (!user) {
      navigate('auth/login');
    }
  }, [navigate, user]);

  const channel = usePusher(`efg-notify${user?.id}`);
  useEffect(() => {
    if (channel) {
      channel.bind('efg-admin', (data: { message: string; title: string }) => {
        audio.play();
        toast.info(
          <div>
            <h2 className="font-bold text-center">{data.title}</h2>
            <h3>{data.message}</h3>
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            theme: 'light',
            onClick: () => handleClick(data),
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  const handleClick = (data: { message: string; title: string }) => {
    navigate('notifications');
  };

  return (
    <>
      {user && (
        <DashboardContextProvider>
          <main className="overflow-hidden">
            <Navigation />
            <div className="flex ">
              <SideBar />
              <div className=" w-full h-screen flex justify-center py-[3.7rem] overflow-y-auto">
                <div className="w-full mt-14 2xl:w-9/12 md:mt-0">
                  <Outlet />
                </div>
                {children && children}
              </div>
            </div>
          </main>
          {mobileMenu && (
            <div
              onClick={() => setMobileMenu(false)}
              className="absolute inset-0 z-10 bg-gray-900 opacity-30"
            ></div>
          )}
        </DashboardContextProvider>
      )}
    </>
  );
};

export default DefaultLayout;
