import React from 'react';
import VerifyCode from '../../components/Auth/VerifyCode';

const VerifiyPasswordChange = () => {
  const tempEmail = window.localStorage.getItem('tempEmail');

  return (
    <>
      <VerifyCode
        email={tempEmail ? tempEmail : ''}
        isDeviceVerification={false}
      />
    </>
  );
};

export default VerifiyPasswordChange;
