import React from 'react';
import Header from '../../components/Pages/Header';
import { LongArrowLeftIcon } from '../../assets/icons/icons';
import { useNavigate } from 'react-router-dom';
import AddTransactionForm from '../../components/Notification/AddNotificationForm';

const AddTransaction = () => {
  const navigate = useNavigate();

  const goToPrevPage = () => {
    navigate('/transactions/');
  };

  return (
    <div className="w-full pb-36">
      <Header
        header="Add a New Transaction"
        subtext="Fill in the form to add new transaction"
      />
      <div className="px-8 mt-6">
        <div className="card">
          <div
            onClick={goToPrevPage}
            className="flex items-center gap-4 cursor-pointer text-textcolor"
          >
            <LongArrowLeftIcon />
            <p>Back to transactions</p>
          </div>

          <AddTransactionForm />
        </div>
      </div>
    </div>
  );
};

export default AddTransaction;
