/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import Input from '../Base/Input';
import PreviewInput from '../Base/PreviewInput';
import Button from '../Base/Button';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { useDebouncedValue } from '@mantine/hooks';
import Success from './../../assets/images/Success.svg';
import { useDashboard } from '../../context/DashboardContext';
import { formatNumberWithCommas } from '../../functions/formatNumber';
import { IUser } from '../../interfaces/user';
interface Props {
  setIsOpen: () => void;
  userEmail?: string;
  type?: 'qr' | 'request';
  userName?: string;
  user?: IUser;
}

const PaymentRequestModal = ({
  userEmail,
  setIsOpen,
  type = 'request',
  userName,
  user,
}: Props) => {
  const { token } = useAuth();
  const { setUsersReload } = useDashboard();

  const [customerID, setCustomerID] = useState('');
  const [invoiceID, setInvoiceID] = useState('');
  const [amount, setAmount] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fetchedName, setFetchedName] = useState('');
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [walletBalance, setWalletBalance] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [message, setMessage] = useState('');
  const [debounced] = useDebouncedValue(customerID, 1500, { leading: true });
  const [debouncedAmount] = useDebouncedValue(amount, 500, { leading: true });
  useEffect(() => {
    if (customerID && debouncedAmount) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    if (walletBalance) {
      if (Number(walletBalance) < Number(debouncedAmount)) {
        setIsAllSelected(false);
        setErrorMessage('Insufficient balance');
      } else {
        setErrorMessage('');
      }
    }
  }, [customerID, debouncedAmount, walletBalance]);
  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  const sendPaymentRequest = async () => {
    setSubmitting(true);
    try {
      const body = {
        amount: Number(debouncedAmount),
        invoiceId: invoiceID,
        uniqueId: customerID,
      };

      const url = type === 'qr' ? '/qr/scantopay' : 'payment-request/create';
      const { data } = await axiosInstance.post(url, body, options);

      setMessage(
        `Yay! ${data.data.title} successfully sent with amount of N${data.data.amount}`
      );
      toast.success('Payment Request sent successfully');
      setSuccessful(true);
      setSubmitting(false);
      setUsersReload && setUsersReload((prev) => !prev);
      setTimeout(() => {
        setIsOpen();
      }, 2000);
    } catch (err) {
      return err;
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerID(e.target.value);
  };

  const makeRequest = async () => {
    setLoading(true);
    const url = type === 'qr' ? '/qr/resolve' : `/user/resolve`;
    try {
      const { data } = await axiosInstance.post(
        url,
        {
          uniqueId: debounced,
        },
        options
      );

      setProfileImage(data.data.profileImage);
      console.log(data.data);
      setWalletBalance(data.data.walletBalance);
      setFetchedName(data.data.fullname);
      setLoading(false);
    } catch (err) {
      setErrorMessage('Error occured');
      setLoading(false);
      return err;
    }
  };

  useEffect(() => {
    if (debounced) {
      makeRequest();
    }
  }, [debounced]);

  useEffect(() => {
    if (type === 'request') {
      userEmail && setCustomerID(userEmail);
    }
  }, [userEmail]);

  return (
    <div className="gap-10 mx-auto bg-white h-fit rounded-xl ">
      {!successful ? (
        <div className="p-3">
          <div className="flex justify-end">
            <span
              onClick={setIsOpen}
              className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
            >
              <XMarkIcon />
            </span>
          </div>

          <div className="flex justify-center">
            <h1 className="text-center header">
              {type === 'qr' ? 'Scan QR' : 'Send Payment Request'}
            </h1>
          </div>

          {profileImage && (
            <div className="flex justify-center mt-3">
              {' '}
              <img
                src={profileImage}
                alt=""
                className="rounded-[50%] w-16 object-cover  h-16"
              />{' '}
            </div>
          )}

          <div className="flex flex-col w-full gap-4 px-3 my-8 md:px-8">
            <Input
              label={type === 'qr' ? 'ID*' : 'Customer’s ID*'}
              value={customerID}
              onChange={(e) => handleEmailChange(e)}
            />

            {!loading && (
              <div className="flex flex-col ">
                {fetchedName ? <PreviewInput value={fetchedName} /> : null}

                {Number(walletBalance) >= 0 ? (
                  <PreviewInput
                    value={`Wallet Balance: ₦${formatNumberWithCommas(
                      Number(walletBalance)
                    )}`}
                  />
                ) : null}
              </div>
            )}
            <Input
              label="Invoice ID (optional)"
              value={invoiceID}
              onChange={(e) => setInvoiceID(e.target.value)}
            />
            <Input
              label="Amount*"
              value={amount}
              type="number"
              onChange={(e) => setAmount(e.target.value)}
            />

            <p className="text-sm font-bold text-red-500">{errorMessage}</p>

            <div className="mt-4">
              <Button
                full
                onClick={sendPaymentRequest}
                variant={isAllSelected ? 'primary' : 'gray'}
                disabled={!isAllSelected || submitting}
                isSubmitting={submitting}
              >
                Send Payment Request
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3">
          <div className="flex flex-col items-center gap-3 pt-20">
            <img src={Success} alt="" />

            <p className="text-2xl font-bold text-center">
              Transaction Successful
            </p>

            <p className="px-10 text-center">{message}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentRequestModal;
