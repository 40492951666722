import { Formik, Field, Form } from 'formik';
import { useEffect, useState } from 'react';
import Button from '../Base/Button';
import Input from '../Base/Input';
import InputValidationError from '../Base/InputValidationError';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import useCurrentUser from '../../hooks/useCurrentUser';
import { editProfileValidationSchema } from '../../interfaces/forms';
import { useDashboard } from '../../context/DashboardContext';

const EditProfileForm = () => {
  const { token } = useAuth();
  const { setReload } = useDashboard();
  const user = useCurrentUser();
  const [branchID, setBranchID] = useState(user?.branchId);
  const navigate = useNavigate();
  const goToPrevPage = () => {
    navigate('/profile/');
  };

  useEffect(() => {
    user?.branchId && setBranchID(user?.branchId);
  }, [user]);

  return (
    <div className="py-20">
      {user && (
        <div className="md:px-10">
          <Formik
            initialValues={{
              firstname: user?.firstname,
              lastname: user?.lastname,
              branch: user?.branch.name,
            }}
            validationSchema={editProfileValidationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              const options = {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json',
                },
              };

              console.log('object', user.branch);

              try {
                const body = {
                  firstname: data.firstname,
                  lastname: data.lastname,
                  branch: branchID,
                };
                await axiosInstance.post('/admin/update', body, options);
                toast.success('Profile updated successfully');
                setReload && setReload((prev) => !prev);
                setSubmitting(false);
              } catch (err) {
                return err;
              }
            }}
          >
            {({ isSubmitting, errors, touched, handleChange, values }) => (
              <Form>
                <div className="flex flex-col space-y-8">
                  <div className="flex flex-col gap-5 md:gap-10 md:flex-row">
                    <div className="md:w-3/6">
                      <Field
                        name="firstname"
                        as={Input}
                        label="First name"
                        placeholder="Enter first name"
                      />
                      {errors.firstname && touched.firstname ? (
                        <InputValidationError text={errors.firstname} />
                      ) : null}
                    </div>

                    <div className="md:w-3/6">
                      <Field
                        name="lastname"
                        as={Input}
                        label="Last name"
                        placeholder="Enter last name"
                      />
                      {errors.lastname && touched.lastname ? (
                        <InputValidationError text={errors.lastname} />
                      ) : null}
                    </div>
                  </div>

                  <div className="md:w-full">
                    {/* <Field
                      name="branch"
                      label="Staff’s Branch*"
                      as={Select}
                      options={branches}
                      value={values.branch}
                      onChange={(data: { name: string; id: number }) => {
                        const newdata = {
                          target: {
                            name: 'branch',
                            value: data.name,
                          },
                        };
                        setBranchID(data.id);
                        handleChange(newdata);
                      }}
                    />
                    {errors.branch && touched.branch ? (
                      <InputValidationError text={errors.branch} />
                    ) : null} */}

                    <Field
                      as={Input}
                      name="branch"
                      label="Staff’s Branch*"
                      readOnly
                    />
                    {errors.lastname && touched.lastname ? (
                      <InputValidationError text={errors.lastname} />
                    ) : null}
                  </div>

                  <div className="lg:w-2/6">
                    <div className="flex items-center gap-5">
                      <Button variant="outline" onClick={goToPrevPage}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        isSubmitting={isSubmitting}
                      >
                        {isSubmitting
                          ? 'Updating Profile...'
                          : 'Update Profile'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default EditProfileForm;
