import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IPlotData } from '../../interfaces/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  plots: IPlotData;
}

export const options = {
  responsive: true,
  // plugins: {
  //   legend: {
  //     display: false,
  //     labels: {
  //       boxWidth: 0,
  //     },
  //   },
  // },
  // elements: {
  //   point: {
  //     radius: 0,
  //   },
  // },

  tension: 0.8,
  scaleShowVerticalLines: true,
  scales: {
    x: {
      font: {
        family: 'DM Sans',
        size: 12,
        weight: '500',
      },
      grid: {
        display: false,
      },
    },
    y: {
      min: 0,
      ticks: {
        font: {
          family: 'DM Sans',
          size: 12,
          weight: '500',
        },
        display: true,
        callback: (value: any) => {
          if (value === 0) return value;
          const formattedValue = value / 100000;
          if (formattedValue % 50 === 0) {
            return formattedValue + 'K';
          }
          return '';
        },
      },
      display: true,
      grid: {
        display: true,
      },
    },
  },
};

export const AnalyticsLineChart = ({ plots }: Props) => {
  const modifiedData = {
    ...plots,
    datasets: plots.datasets.map((dataset) => ({
      ...dataset,
      borderColor: dataset.backgroundColor,
      backgroundColor: dataset.backgroundColor,
      tension: 0.4,
    })),
  };

  const labels = plots.datasets.map((label, _) => {
    return { label: label.label, color: label.backgroundColor };
  });

  return (
    <>
      <div className="flex items-center gap-3 mb-4">
        {labels.map((label, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              className="w-2 h-2 rounded-[50%] p-1"
              style={{ backgroundColor: label.color }}
            ></div>
            <p className="font-medium text-sm text-[#979699]">{label.label}</p>
          </div>
        ))}
      </div>
      <Line options={options} data={modifiedData} />
    </>
  );
};
