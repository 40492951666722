import React from 'react';
import Header from '../../components/Pages/Header';
import AddBranchForm from '../../components/Store/AddBranchForm';

const AddBranch = () => {
  return (
    <div className="w-full">
      <Header
        header="Store locator"
        subtext="Manage and monitor branches"
        breadCrumb="Add a new branch"
      />
      <div className="mt-10 lg:px-32">
        <div className="card">
          <div className="px-10 py-8">
            <h1 className="header">Add a branch</h1>
            {/* <AddStaffForm /> */}
            <AddBranchForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBranch;
