import React, { ReactNode } from 'react';
import { ChevronRight } from '../../assets/icons/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
  header: string;
  subtext: string;
  children?: ReactNode;
  breadCrumb?: string;
}

const Header = ({ breadCrumb, header, subtext, children }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-wrap justify-between w-full gap-4 px-6 bg-white xl:px-12 lg:flex-nowrap py-7 lg:gap-0">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          {breadCrumb ? (
            <h1 className="cursor-pointer header" onClick={() => navigate(-1)}>
              {header}
            </h1>
          ) : (
            <h1 className="header">{header}</h1>
          )}

          {breadCrumb && (
            <>
              <ChevronRight className="mt-1" />
              <p className="pt-1 text-xs text-textcolor"> {breadCrumb}</p>
            </>
          )}
        </div>
        <p className="text-left text-textcolor">{subtext}</p>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default Header;
