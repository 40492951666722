import React from 'react';

const DashboardSkeleton = () => {
  return (
    <div className="px-6 py-4 mt-7">
      <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-4 card">
        {Array.from({ length: 4 }, () => Math.random()).map((number, index) => (
          <div
            key={index}
            role="status"
            className="flex items-center justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse "
          >
            <span className="sr-only">Loading...</span>
          </div>
        ))}
      </div>

      <div role="status" className="my-10 animate-pulse">
        <div className="h-2.5 bg-gray-300 rounded-full  max-w-[640px] mb-2.5 mx-auto"></div>
        <div className="h-2.5 mx-auto bg-gray-300 rounded-full  max-w-[540px]"></div>
        <div className="flex items-center justify-center mt-4">
          <div className="w-20 h-2.5 bg-gray-200 rounded-full  mr-3"></div>
          <div className="w-24 h-2 bg-gray-200 rounded-full "></div>
        </div>
        <span className="sr-only">Loading...</span>
      </div>
      <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3 card">
        {Array.from({ length: 3 }, () => Math.random()).map((number, index) => (
          <div
            role="status"
            key={index}
            className="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6"
          >
            <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2.5"></div>
            <div className="w-48 h-2 mb-10 bg-gray-200 rounded-full "></div>
            <div className="flex items-baseline mt-4 space-x-6">
              <div className="w-full bg-gray-200 rounded-t-lg h-72 "></div>
              <div className="w-full h-56 bg-gray-200 rounded-t-lg "></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 "></div>
              <div className="w-full h-64 bg-gray-200 rounded-t-lg "></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 "></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-72 "></div>
              <div className="w-full bg-gray-200 rounded-t-lg h-80 "></div>
            </div>
            <span className="sr-only">Loading...</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardSkeleton;
