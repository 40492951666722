import React, { createRef, useState } from 'react';
import { INotification } from '../../interfaces/user';
import { formatDate } from '../../functions/formatDate';
import { ThreeDotsIcon, XMarkIcon } from '../../assets/icons/icons';
import Modal from '../Base/Modal';
import AddNotificationForm from './AddNotificationForm';
import useOutsideClick from '../../hooks/useOutsideClicks';
import { Transition } from '@headlessui/react';
import DropdownItems from '../Base/DropdownItems';
import ConfirmationModal from '../Base/ConfirmationModal';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { useDashboard } from '../../context/DashboardContext';

interface Props {
  items: INotification[];
}
const PushNotificationsTable = ({ items }: Props) => {
  const [showDropdown, setShowDropdown] = useState(0);
  const dropdownItemsRef = createRef<HTMLDivElement>();
  useOutsideClick(dropdownItemsRef, () => setShowDropdown(0));
  const [showResendModal, setShowResendModal] = useState(false);
  const [notification, setNotification] = useState<INotification>();
  const [confirmModal, setConfirmModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { setUsersReload } = useDashboard();
  const { token } = useAuth();

  const handleDropdownOption = (option: string) => {
    if (option === 'Resend') {
      setShowResendModal(true);
    }
    if (option === 'Delete') {
      setConfirmModal(true);
    }
  };

  const deleteTransaction = async () => {
    if (!notification) return;
    setSubmitting(true);
    try {
      await axiosInstance.post(
        `push-notification/delete/${notification.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      setSubmitting(false);

      toast.success('Deleted Successfully');
      setUsersReload && setUsersReload((prev) => !prev);
    } catch (err) {
      setSubmitting(false);
      return err;
    }
  };
  return (
    <div className="pb-3 mb-5 overflow-x-auto ">
      <table className="w-full text-sm text-left border-collapse border-spacing-4">
        <thead className="text-xs font-bold uppercase ">
          <tr className="text-white bg-dark bg-opacity-40 rounded-3xl whitespace-nowrap">
            <th scope="col" className="px-6 py-3"></th>
            <th scope="col" className="px-6 py-3">
              Date
            </th>
            <th scope="col" className="px-6 py-3">
              Description
            </th>
            <th scope="col" className="px-6 py-3">
              Platform
            </th>
            <th scope="col" className="px-6 py-3">
              Status
            </th>
            <th scope="col" className="px-6 py-3">
              Sent by
            </th>
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>

        <tbody className="text-sm ">
          {items.map((item, index) => (
            <tr
              className="relative py-2 bg-white whitespace-nowrap"
              key={item.id}
            >
              <td className="px-6 py-4">
                <div className=" bg-dark bg-opacity-20 p-2 flex items-center justify-center w-8 h-8 text-[10px] font-medium rounded-[50%]">
                  {index + 1}
                </div>
              </td>
              <th scope="row" className="px-6 py-4 font-normal">
                {formatDate(item.createdAt)}
              </th>
              <td className="px-6 py-4 capitalize">{item.description}</td>
              <td className="px-6 py-4">{item.platform}</td>
              <td className="px-6 py-4 ">
                <div
                  className={`status  ${item.status ? 'active' : 'inactive'} `}
                >
                  {item.status}
                </div>
              </td>
              <td className="px-6 py-4">{item.sentBy}</td>

              <td className="px-4 py-4 cursor-pointer f">
                <div
                  className="flex items-center justify-center w-full h-8 "
                  onClick={(e) => {
                    setNotification(item);
                    setShowDropdown(item.id);
                    // setNotification(item);
                    // setShowResendModal(true);
                    // navigate(`/payment_requests/${item.id}`);
                  }}
                >
                  <ThreeDotsIcon />
                </div>
              </td>
              <td>
                <Transition
                  show={showDropdown === item.id}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  ref={dropdownItemsRef}
                >
                  <DropdownItems
                    isOpen={showDropdown === item.id}
                    onChange={(option) => handleDropdownOption(option)}
                    options={['Resend', 'Delete']}
                    onSelected={() => setShowDropdown(0)}
                  />
                </Transition>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        width="w-[30rem]"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
      >
        {notification && (
          <ConfirmationModal
            name={notification.title}
            closeModal={() => setConfirmModal(false)}
            type="notification"
            confirmAction={deleteTransaction}
            submitting={submitting}
            status="Delete"
          />
        )}
      </Modal>

      <Modal
        width="h-full"
        isOpen={showResendModal}
        onClose={() => setShowResendModal(false)}
      >
        <div className="bg-[#F8F8F8] mx-10 gap-10 h-[75vh] rounded-xl">
          <div className="flex justify-end p-5">
            <span
              onClick={() => setShowResendModal(false)}
              className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
            >
              <XMarkIcon />
            </span>
          </div>

          <div className="px-10 ">
            <h1 className="pb-10 header">Resend push notification</h1>
            <AddNotificationForm
              isEdit
              editData={notification}
              closeModal={() => setShowResendModal(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PushNotificationsTable;
