import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/Base/Dropdown';
import SearchInput from '../../components/Base/SearchInput';
import GeneralCard from '../../components/GeneralCard';
import Header from '../../components/Pages/Header';
import TransactionsTable from '../../components/Tables/TransactionsTable';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { useDashboard } from '../../context/DashboardContext';
import { filterByDate, formatTomorrow } from '../../functions/formatDate';
import { IBranch, ITransaction } from '../../interfaces/user';
import TableSkeleton from '../../components/Base/TableSkeleton';
import FilterMonthModal from '../../components/Dashboard/FilterMonthModal';
import Modal from '../../components/Base/Modal';
import useBranches from '../../hooks/useBranches';
import { EmptyStateIcon } from '../../assets/icons/icons';
import Pagination from '../../components/Base/Pagination';
import { useDebouncedValue } from '@mantine/hooks';
import { formatNumberWithCommas } from '../../functions/formatNumber';
import downloadExcel from '../../functions/downloadExcel';
import Button from '../../components/Base/Button';

const Transactions = () => {
  // const navigate = useNavigate();
  const { user, token } = useAuth();
  const [loading, setLoading] = useState(false);
  const { usersReload } = useDashboard();
  const currentdate = formatTomorrow();
  const branches = useBranches();
  const [lastPage, setLastPage] = useState(1);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [filteredStatus, setFilteredStatus] = useState('All');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [showFilterMonthModal, setShowFilterMonthModal] = useState(false);
  const [meta, setMeta] = useState({
    successfulTransaction: 0,
    thisWeekFailed: 0,
    thisWeekSuccessful: 0,
    thisWeekTotal: 0,
    totalTransactions: 0,
    failedTransaction: 0,
    totalSum: 0,
    weeklytotalSum: 0,
  });

  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 10,
    search_term: '',
    page_number: 0,
    status: '',
    branch: null,
  });
  // useEffect(() => {
  //   if (user?.role === 'Sales') {
  //     navigate('/payment-requests');
  //   }
  // }, [navigate, user]);
  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (event === 'All') {
      setParams((prevObj) => ({
        ...prevObj,
        branch: null as any,
        status: '',
      }));
    }

    if (type === 'date' && typeof event === 'string') {
      if (event === 'Custom') {
        setShowFilterMonthModal(true);
      }
      const val = filterByDate(event);
      if (!val) return;
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val.startDate,
        end_date: val.endDate,
      }));
    }

    if (type === 'sort' && typeof event === 'string' && event !== 'All') {
      setParams((prevObj) => ({
        ...prevObj,
        branch: null as any,
        status: event.toLowerCase(),
      }));
      setFilteredStatus(event);
    }
    if (type === 'branch') {
      if (typeof event === 'string') return;

      setParams((prevObj) => ({
        ...prevObj,
        branch: event.id as any,
      }));
      setFilteredStatus(event.name);
    }
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  const fetchTransactions = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('/transactions/get-all', {
        headers,
        params,
      });
      setLastPage(data.meta?.lastPage);
      setTransactions(data.data);
      setCurrentPage(data.meta.currentPage);
      setMeta((prev) => ({
        ...prev,
        successfulTransaction: data.successfulTransaction,
        thisWeekFailed: data.thisWeekFailed,
        thisWeekSuccessful: data.thisWeekSuccessful,
        totalTransactions: data.totalTransactions,
        thisWeekTotal: data.thisWeekTotal,
        failedTransaction: data.failedTransaction,
        weeklytotalSum: data.weeklytotalSum,
        totalSum: data.totalSum,
      }));
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };

  const handleCustomDates = (val: string, type: string) => {
    if (type === 'start_date') {
      setParams((prevObj) => ({
        ...prevObj,
        start_date: val,
      }));
    } else {
      setParams((prevObj) => ({
        ...prevObj,
        end_date: val,
      }));
    }
  };
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);

  const handleDownload = () => {
    downloadExcel(transactions, 'Transactions');
  };
  return (
    <section className="w-full pb-20">
      <Header header="Transactions" subtext="Monitor and Manage Transactions">
        <div className="flex flex-wrap items-center gap-3 md:gap-10 lg:flex-nowrap">
          <Dropdown
            options={['All', ...branches]}
            text="All"
            title="BRANCH"
            onChange={(val) => {
              handleChange(val, 'branch');
            }}
          />
          <Dropdown
            options={[
              'All',
              'Today',
              'This Week',
              'This Month',
              'Last Month',
              'This Year',
              'Custom',
            ]}
            text="All"
            title="Date"
            onChange={(val) => {
              handleChange(val, 'date');
            }}
          />
          <Dropdown
            options={['All', 'Successful', 'Pending', 'Failed']}
            text="Successful"
            title="Sort By"
            onChange={(val) => {
              handleChange(val, 'sort');
            }}
          />
          <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
            <Button variant="outline" onClick={handleDownload}>
              Download CSV
            </Button>
          </div>
        </div>
      </Header>

      <div className="px-8">
        {user?.role !== 'Sales' && (
          <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-4 ">
            <GeneralCard
              title={'Total Transactions'}
              numbers={meta.totalTransactions}
              additions={`+${meta.thisWeekTotal} this week`}
            />
            <GeneralCard
              title={'Successful Transactions'}
              numbers={meta.successfulTransaction}
              additions={`+${meta.thisWeekSuccessful} this week`}
            />
            <GeneralCard
              title={'Failed Transactions'}
              numbers={meta.failedTransaction}
              red
              additions={`+${meta.thisWeekFailed} this week`}
            />
            <GeneralCard
              title={'Amount'}
              numbers={'₦' + formatNumberWithCommas(meta.totalSum)}
              red
              additions={`+${meta.weeklytotalSum} this week`}
            />
          </div>
        )}
        <Modal width="w-[30rem]" isOpen={showFilterMonthModal}>
          <FilterMonthModal
            setIsOpen={() => setShowFilterMonthModal(false)}
            getStartDate={(val) => {
              handleCustomDates(val, 'start_date');
            }}
            getEndDate={(val) => {
              handleCustomDates(val, 'end_date');
            }}
          />
        </Modal>
        <div className="mt-10 card">
          <div className="flex flex-col justify-between gap-3 sm:flex-row">
            <div className="xmd:w-2/6">
              <SearchInput
                placeholder="Search transactions..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Dropdown
              options={['5', '10', '20', '30', '50', '70', '100', '120']}
              text="10"
              title="Table Size"
              onChange={(val) => {
                handleChange(val, 'size');
              }}
            />
          </div>

          <div className="mt-8">
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : transactions.length ? (
              <TransactionsTable items={transactions} />
            ) : (
              <div className="grid gap-2 mx-auto place-items-center">
                <EmptyStateIcon />
                <p>No Transactions {filteredStatus && filteredStatus}</p>
              </div>
            )}
          </div>

          <Pagination
            handleMoreClicks={(val) => handleMoreClicks(val)}
            lastPage={lastPage}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </section>
  );
};

export default Transactions;
