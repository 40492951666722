import React, { useEffect, useState } from 'react';
import { PlusIcon } from '../../assets/icons/icons';
import Button from '../../components/Base/Button';
import Header from '../../components/Pages/Header';
import SearchInput from '../../components/Base/SearchInput';
import { useNavigate } from 'react-router-dom';
import { useDashboard } from '../../context/DashboardContext';
import BranchTable from '../../components/Tables/BranchTable';
import TableSkeleton from '../../components/Base/TableSkeleton';
import { formatTomorrow } from '../../functions/formatDate';
import { useDebouncedValue } from '@mantine/hooks';
import Pagination from '../../components/Base/Pagination';
import Dropdown from '../../components/Base/Dropdown';
import { IBranch } from '../../interfaces/user';
import downloadExcel from '../../functions/downloadExcel';

const StoreLocator = () => {
  const navigate = useNavigate();
  const { getBranchesWithFilter, loading, usersReload } = useDashboard();
  const currentdate = formatTomorrow();
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({
    search_term: '',
    start_date: '2023-03-01',
    end_date: currentdate,
    page_size: 0,
    page_number: 0,
    type: 0,
  });
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [items, setItems] = useState([]);
  const goToAddPage = () => {
    navigate('/store_locator/add');
  };
  const fetchData = async () => {
    const data = await getBranchesWithFilter(params);
    setLastPage(data.meta?.lastPage);
    setCurrentPage(data.meta.currentPage);
    setItems(data.data);
  };
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);

  const handleChange = (event: string | IBranch, type: string = 'date') => {
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  const handleDownload = () => {
    downloadExcel(items, 'Branches');
  };

  return (
    <section className="w-full pb-24">
      <Header header="Branches" subtext="Monitor and Manage staffs here">
        <div className="flex flex-wrap items-center gap-3 md:gap-10 lg:flex-nowrap">
          <Button variant="outline">Download CSV</Button>

          <Button icon={<PlusIcon />} onClick={goToAddPage}>
            Add New Branch
          </Button>
          <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
            <Button variant="outline" onClick={handleDownload}>
              Download CSV
            </Button>
          </div>
        </div>
      </Header>

      <div className="px-8">
        <div className="mt-10 card">
          <div className="flex flex-col justify-between gap-3 sm:flex-row">
            <div className="xmd:w-2/6">
              <SearchInput
                placeholder="Search branches..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <Dropdown
              options={['5', '10', '20', '30', '50', '70', '100', '120']}
              text="10"
              title="Table Size"
              onChange={(val) => {
                handleChange(val, 'size');
              }}
            />
          </div>

          <div className="mt-8">
            {loading ? <TableSkeleton /> : <BranchTable items={items} />}
          </div>
          <Pagination
            handleMoreClicks={(val) => handleMoreClicks(val)}
            lastPage={lastPage}
            handlePageClick={handlePageClick}
            currentPage={currentPage}
          />
        </div>
      </div>
    </section>
  );
};

export default StoreLocator;
