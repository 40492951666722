import React, { useState } from 'react';
import logo from './../.././assets/images/logo.svg';
import forgotPassword from './../.././assets/images/forgotPassword.svg';
import Input from '../../components/Base/Input';
import Button from '../../components/Base/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [uploading, setUploading] = useState(false);
  const { resetPassword } = useAuth();
  const navigate = useNavigate();
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    window.localStorage.setItem('tempEmail', email);
    e.preventDefault();
    setUploading(true);
    try {
      const data = await resetPassword(email);
      if (data.message === 'Code Sent Successfully') {
        toast.success('Check your email for a new password and login again');

        setTimeout(() => {
          navigate('/auth/verifypassword');
        }, 2000);
        setUploading(false);
      } else {
        setUploading(false);
      }
    } catch (err) {
      setUploading(false);
      toast.error('An error has occurred. Please try again later');
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-center justify-center py-8 mx-3 w-96 sm:mx-0 "
    >
      <div>
        <div>
          <img src={logo} alt="" />
        </div>
      </div>
      <h2 className="pt-5 mb-3 lg:text-[22px] font-semibold">Oops!</h2>
      <p className="w-11/12 pb-10 text-center text-textcolor">
        Let’s recover your account together
      </p>

      <div>
        <img src={forgotPassword} alt="" />
      </div>
      <h2 className="pt-5 mb-3 lg:text-[22px] font-semibold">
        Reset your password
      </h2>
      <div className="flex flex-col w-full py-4 space-y-8">
        <Input
          type="email"
          label="Email Address or Phone Number"
          placeholder="example@mail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <Button type="submit" isSubmitting={uploading} disabled={uploading}>
          {uploading ? 'Sending code to your email...' : 'Reset'}
        </Button>
      </div>

      <p className="pt-6 text-textcolor">
        Take me back to
        <Link to={'/auth/login'} className="pl-1 cursor-pointer text-primary ">
          Login
        </Link>
      </p>
    </form>
  );
};

export default ResetPassword;
