import { useState, useEffect } from 'react';
import { useDashboard } from '../context/DashboardContext';
import axiosInstance from '../api/axios';
import { useAuth } from '../context/AuthContext';
import { IUser } from '../interfaces/user';

const useCurrentUser = () => {
  const { token } = useAuth();
  const [user, setUser] = useState<IUser>();

  const { reload } = useDashboard();
  const fetchUser = async () => {
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const { data } = await axiosInstance.get('/admin/get/current', options);
      setUser(data.data);
      return data;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return user;
};

export default useCurrentUser;
