import React from 'react';
import ReactPaginate from 'react-paginate';
import {
  PaginationLeftIcon,
  ShortArrowLeftIcon,
  ShortArrowRightIcon,
  PaginationRightIcon,
} from '../../assets/icons/icons';

interface Props {
  handleMoreClicks: (val: 'first' | 'last') => void;
  lastPage: number;
  handlePageClick: (event: { selected: number }) => void;
  currentPage: number;
}
const Pagination = ({
  handleMoreClicks,
  lastPage,
  handlePageClick,
  currentPage,
}: Props) => {
  return (
    <div className="flex flex-wrap justify-end gap-3 pt-3 border-t">
      <span
        className=" paginationWrapper"
        onClick={() => handleMoreClicks('first')}
      >
        <PaginationLeftIcon />
      </span>

      <ReactPaginate
        pageCount={lastPage}
        previousLabel={
          <span className="paginationWrapper">
            <ShortArrowLeftIcon />
          </span>
        }
        nextLabel={
          <span className="paginationWrapper">
            <ShortArrowRightIcon />
          </span>
        }
        onPageChange={handlePageClick}
        forcePage={currentPage - 1}
        disabledClassName="pagination-disabled"
        activeClassName={'pagination_active'}
        containerClassName="pagination-container"
        pageClassName={'page-link'}
      />
      <span
        className="paginationWrapper"
        onClick={() => handleMoreClicks('last')}
      >
        <PaginationRightIcon />
      </span>
    </div>
  );
};

export default Pagination;
