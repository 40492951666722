import React, { useState } from 'react';
import { XMarkIcon } from '../../assets/icons/icons';
import Button from '../Base/Button';
import PreviewInput from '../Base/PreviewInput';
import Select from '../Base/Select';
import { IBranch, IUser } from '../../interfaces/user';
import { useAuth } from '../../context/AuthContext';
import axiosInstance from '../../api/axios';
import { toast } from 'react-toastify';
import { useDashboard } from '../../context/DashboardContext';
import { formatTomorrow } from '../../functions/formatDate';

interface Props {
  setIsOpen: () => void;
  userObj: IUser;
  branches: string[];
  type?: 'branch' | 'role';
}

const EditStaffModal = ({
  type = 'branch',
  setIsOpen,
  userObj,
  branches,
}: Props) => {
  const [newBranch, setNewBranch] = useState(userObj.branch?.name);
  const [newBranchID, setNewBranchID] = useState(0);
  const [newRole, setNewRole] = useState(userObj.role);
  const [submitting, setSubmitting] = useState(false);
  const { fetchStaffs, setUsersReload } = useDashboard();
  const { token } = useAuth();
  const currentdate = formatTomorrow();
  const handleClick = async () => {
    setSubmitting(true);
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const body = {
      // firstname: userObj.firstname,
      // lastname: userObj.lastname,
      branch: newBranchID,
      id: userObj.id,
    };
    const bodyRole = {
      // firstname: userObj.firstname,
      // lastname: userObj.lastname,
      id: userObj.id,
      role: newRole,
    };
    try {
      const url =
        type === 'branch'
          ? '/admin/update-adminbranch'
          : '/admin/update-adminrole';
      const { data } = await axiosInstance.post(
        url,
        type === 'branch' ? body : bodyRole,
        options
      );
      type === 'branch'
        ? toast.success('Staffs branch updated successfully')
        : toast.success('Staffs role updated successfully');
      setIsOpen();
      fetchStaffs({
        search_term: '',
        start_date: '2023-03-01',
        end_date: currentdate,
        page_size: 0,
        type: 0,
      });
      setSubmitting(false);
      if (setUsersReload) {
        setUsersReload((prev) => !prev);
      }
      return data;
    } catch (err) {
      setSubmitting(false);
      return err;
    }
  };

  const handleChange = (value: string | number | IBranch) => {
    if (type === 'role') {
      if (typeof value !== 'string') {
        return;
      }
      setNewRole(value);
    }
    if (type === 'branch') {
      if (typeof value === 'string' || typeof value === 'number') {
        return;
      }
      setNewBranchID(value.id);
      setNewBranch(value.name);
    }
  };
  return (
    <div className="bg-[#F8F8F8]  mx-auto gap-10 h-[65vh]  rounded-xl ">
      {/* {JSON.stringify(branches)} */}
      <div className="p-3">
        <div className="flex justify-end">
          <span
            onClick={setIsOpen}
            className="cursor-pointer bg-[#151411] bg-opacity-20 p-4 rounded-[50%]"
          >
            <XMarkIcon />
          </span>
        </div>
        <div className="flex justify-center">
          <h1 className="text-center header">
            Change staff’s{' '}
            <span className="pl-0.5">
              {type === 'branch' ? 'branch' : 'role'}
            </span>
          </h1>
        </div>
        <div className="flex flex-col w-full gap-4 px-8 my-8">
          <PreviewInput
            label="Staff's name"
            value={`${userObj?.firstname + ' ' + userObj?.lastname}`}
          />
          <PreviewInput
            label="Staff’s Current Branch"
            value={
              type === 'branch'
                ? `${userObj?.branch ? userObj?.branch?.name : ''}`
                : `${userObj?.role}`
            }
          />

          <Select
            value={type === 'branch' ? newBranch : newRole}
            options={
              type === 'branch' ? branches : ['Super', 'Manager', 'Sales']
            }
            label="Staff’s New Branch*"
            onChange={(value) => {
              handleChange(value);
            }}
          />

          <div className="mt-14">
            <Button
              full
              onClick={handleClick}
              disabled={submitting}
              isSubmitting={submitting}
            >
              {submitting
                ? 'Updating...'
                : type === 'branch'
                ? "Update staff's branch"
                : "Update staff's role"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaffModal;
