import React, { useEffect, useState } from 'react';
import logo from './../.././src/assets/images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import SearchInput from './Base/SearchInput';
import { BellIcon, MenuIcon } from '../assets/icons/icons';
import { useApp } from '../context/AppContext';
import useCurrentUser from '../hooks/useCurrentUser';
import NameBackground from './Base/NameBackground';
import { useDashboard } from '../context/DashboardContext';
const Navigation = () => {
  const user = useCurrentUser();
  const { setMobileMenu, mobileMenu } = useApp();
  const [notificationLength, setNotificationLength] = useState(0);
  const { fetchNotifications, usersReload } = useDashboard();
  const navigate = useNavigate();
  const fetchData = async () => {
    const data = await fetchNotifications();
    setNotificationLength(data.data.length);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersReload]);
  return (
    <nav className="fixed top-0 z-20 w-full bg-white border-b border-opacity-30 border-textcolor">
      <div className="px-4 sm:py-3 md:px-10 fullflex">
        <div className="gap-3 sm:w-7/12 sm:gap-10 md:gap-20 xl:gap-x-52 lg:w-6/12 fullflex">
          <Link to={'/'}>
            <img src={logo} alt="" />
          </Link>
          <div className="hidden xs:w-full xs:block">
            <SearchInput />
          </div>
        </div>

        <div className="flex items-center gap-3">
          <div
            onClick={() => navigate('profile')}
            className="flex items-center gap-3 cursor-pointer hover:opacity-60"
          >
            {user?.profileImage ? (
              <img
                src={user?.profileImage}
                className="w-10 h-10 rounded-[50%]"
                alt=""
              />
            ) : (
              user && (
                <NameBackground
                  firstName={user?.firstname}
                  lastName={user?.lastname}
                />
              )
            )}

            {user && (
              <div>
                <p className="font-medium text-black100">
                  {user.firstname + ' ' + user.lastname}
                </p>
                <p className="text-left text-dark text-opacity-60">
                  {user?.role}
                </p>
              </div>
            )}
          </div>
          <div
            className="relative pl-5 cursor-pointer hover:opacity-60"
            onClick={() => navigate('notifications')}
          >
            <div className="-top-2 -right-2 flex justify-center items-center absolute p-1 bg-primary rounded-[50%] w-4 h-4 text-white text-[10px]">
              <span> {notificationLength}</span>
            </div>
            <BellIcon />
          </div>
          <div
            className="cursor-pointer md:hidden"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            <MenuIcon />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
