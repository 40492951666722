import React from 'react';

import { ITransaction } from '../../interfaces/user';
import { formatDate } from '../../functions/formatDate';
import { useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../../functions/formatNumber';
interface Props {
  items: ITransaction[];
}
const TransactionsTable = ({ items }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="relative overflow-x-auto bg-white">
      <table className="w-full text-sm text-left ">
        <thead className="text-xs font-bold uppercase">
          <tr className=" bg-gray3 rounded-3xl whitespace-nowrap">
            <th scope="col" className="px-6 py-3">
              S/N
            </th>
            <th scope="col" className="px-6 py-3">
              title
            </th>
            <th scope="col" className="px-6 py-3">
              name
            </th>
            <th scope="col" className="px-6 py-3">
              TRANSACTION ID
            </th>
            <th scope="col" className="px-6 py-3">
              TRANSAC. DATE
            </th>
            <th scope="col" className="px-6 py-3">
              AMount Paid
            </th>
            <th scope="col" className="px-6 py-3">
              STATUS
            </th>
            <th scope="col" className="px-6 py-3">
              ACTION
            </th>
          </tr>
        </thead>

        <tbody className="text-sm">
          {items.map((item, index) => (
            <tr className="relative whitespace-nowrap" key={item.id}>
              <td className="px-6 py-4">{index + 1}</td>
              <th scope="row" className="px-6 py-4 font-normal">
                {item.title}
              </th>
              <th scope="row" className="px-6 py-4 font-normal">
                {item.user?.fullname ? item.user.fullname : ''}
              </th>

              <td className="px-6 py-4 capitalize">
                {item.txRef ? item.txRef : 'Nill '}
              </td>
              <td className="px-6 py-4">{formatDate(item.createdAt)}</td>
              <td className="px-6 py-4">{`₦${formatNumberWithCommas(
                item.amountPaid
              )}`}</td>

              <td className="px-6 py-4 ">
                <div
                  className={`status  ${item.status ? 'active' : 'inactive'} `}
                >
                  {item.status}
                </div>
              </td>
              <td className="px-4 py-4 cursor-pointer f">
                <div
                  className="flex items-center justify-center w-full h-8 "
                  onClick={(e) => {
                    navigate(`/transactions/${item.id}`);
                  }}
                >
                  <span className="px-5 py-1 text-sm font-semibold text-white rounded-md bg-primary">
                    View
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsTable;
