import React, { useEffect, useState } from 'react';
import Dropdown from '../Base/Dropdown';
import SearchInput from '../Base/SearchInput';
import GeneralCard from '../GeneralCard';
import { useAuth } from '../../context/AuthContext';
import { formatTomorrow } from '../../functions/formatDate';
import { useDebouncedValue } from '@mantine/hooks';
import axiosInstance from '../../api/axios';
import { useDashboard } from '../../context/DashboardContext';
import { IBranch, IDiscount } from '../../interfaces/user';
import DiscountsTable from './DiscountsTable';
import TableSkeleton from '../Base/TableSkeleton';
import Pagination from '../Base/Pagination';
import downloadExcel from '../../functions/downloadExcel';
import Button from '../Base/Button';

const DiscountsView = () => {
  const { token } = useAuth();
  const currentdate = formatTomorrow();
  const { usersReload } = useDashboard();
  const [items, setItems] = useState<IDiscount[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debounced] = useDebouncedValue(searchValue, 600, { leading: true });
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [params, setParams] = useState({
    start_date: '2023-03-01',
    end_date: currentdate,
    search_term: '',
  });
  const [meta, setMeta] = useState({ total: 0, active: 0, inactive: 0 });
  const fetchPromotionalInformation = async () => {
    setLoading(true);
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    try {
      const { data } = await axiosInstance.get('marketing/get-all-coupons', {
        headers,
        params,
      });

      setLastPage(data.meta?.lastPage);
      setCurrentPage(data.meta.currentPage);
      setMeta((obj) => ({
        ...obj,
        total: data?.totalDiscount,
        active: data?.activeDiscount,
        inactive: data?.inactiveDiscount,
      }));
      setItems(data.data);
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
    }
  };
  const handleChange = (
    event: string | number | IBranch,
    type: string = 'date'
  ) => {
    if (type === 'size') {
      setParams((prevObj) => ({
        ...prevObj,
        page_size: Number(event),
      }));
    }
  };

  useEffect(() => {
    setParams((prevObj) => ({
      ...prevObj,
      search_term: debounced,
    }));
  }, [debounced]);
  useEffect(() => {
    fetchPromotionalInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, usersReload]);
  const handleMoreClicks = (type: 'first' | 'last') => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: type === 'first' ? 1 : lastPage,
    }));
  };
  const handlePageClick = (event: { selected: number }) => {
    setParams((prevObj) => ({
      ...prevObj,
      page_number: event.selected + 1,
    }));
  };
  const handleDownload = () => {
    downloadExcel(items, 'Discounts');
  };

  return (
    <div>
      <div>
        <div className="grid gap-10 mt-5 sm:grid-cols-2 lg:grid-cols-3 ">
          <GeneralCard title={'Total Discounts'} numbers={meta.total} />
          <GeneralCard title={'Total Active Discounts'} numbers={meta.active} />
          <GeneralCard
            title={'Total Inactive Discounts'}
            numbers={meta.inactive}
          />
        </div>
        <div className="mt-10">
          <div className="mt-10 card">
            <div className="flex flex-col justify-between gap-3 mb-10 sm:flex-row">
              <div className="sm:w-3/6 xmd:w-2/6">
                <SearchInput
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="flex flex-wrap items-center gap-3 md:gap-10 md:flex-nowrap">
                <Button variant="outline" onClick={handleDownload}>
                  Download CSV
                </Button>
                <Dropdown
                  options={['5', '10', '20', '30']}
                  text="10"
                  title="Table Size"
                  onChange={(val) => {
                    handleChange(val, 'size');
                  }}
                />{' '}
              </div>
            </div>
            {loading ? (
              <div className="py-10">
                <TableSkeleton />
              </div>
            ) : (
              <DiscountsTable items={items} />
            )}
            <Pagination
              handleMoreClicks={(val) => handleMoreClicks(val)}
              lastPage={lastPage}
              handlePageClick={handlePageClick}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscountsView;
