import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IPlotData } from '../../interfaces/user';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  plots: IPlotData;
}

export const HistogramChart = ({ plots }: Props) => {
  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '500',
          },
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            family: 'DM Sans',
            size: 12,
            weight: '500',
          },
        },
      },
    },
    responsive: true,
    plugins: {
      // legend: {
      //   display: false,
      //   labels: {
      //     boxWidth: 0,
      //   },
      // },
    },
  };

  // const labels = plots.datasets.map((label, index) => {
  //   return { label: label.label, color: label.backgroundColor };
  // });

  return (
    <div className="">
      {/* <div className="flex items-center gap-3 mb-4">
        {labels.map((label, index) => (
          <div key={index} className="flex items-center gap-2">
            <div
              style={{ backgroundColor: label.color }}
              className="p-2 wp3 h-3 rounded-[50%]"
            ></div>
            <p className="text-sm">{label.label}</p>
          </div>
        ))}
      </div> */}
      <Bar options={options} data={plots} />
    </div>
  );
};
