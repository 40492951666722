import { useEffect, useState } from 'react';
import { DatePickerInput } from '@mantine/dates';
import { formatFilter } from '../../functions/formatDate';
import { createStyles } from '@mantine/core';

interface Props {
  onChange: (value: any) => void;
  label?: string;
  regularInput?: boolean;
  name?: string;
  editValue?: string;
  blockPrevDate?: boolean;
}

const DatePicker = ({
  name,
  regularInput,
  onChange,
  label = 'Pick date',
  editValue,
  blockPrevDate,
}: Props) => {
  const [value, setValue] = useState<Date | null>();

  const handleChange = (val: Date) => {
    const temp = formatFilter(val);
    onChange(temp);
    setValue(val);
  };

  const useStyles = createStyles(() => ({
    input: {
      backgroundColor: 'RGBA(242,242,242,0.61)',
      borderRadius: '20px',
      '& button': {
        border: 'none',
      },
    },
  }));
  const { classes } = useStyles();

  useEffect(() => {
    editValue && setValue(new Date(editValue));
  }, [editValue]);
  return (
    <div className="w-full">
      <label className={`${regularInput ? 'text-xs' : 'text-lg'} text-left`}>
        {label}
      </label>

      <DatePickerInput
        minDate={blockPrevDate ? new Date() : undefined}
        placeholder="Pick date"
        value={value}
        onChange={handleChange}
        mx="auto"
        maw={400}
        name={name}
        classNames={{
          wrapper: regularInput ? '' : classes.input,
        }}
      />
    </div>
  );
};
export default DatePicker;
