import React from 'react';
import Header from '../../components/Pages/Header';
import SearchInput from '../../components/Base/SearchInput';
import Table from '../../components/Base/Table';

const Settings = () => {
  return (
    <section className="w-full">
      <Header header="Settings" subtext="Handle your settings" />

      <div className="px-8 mt-10">
        <h1 className="header">Transaction Limit</h1>
        <div className="mt-10 card">
          <div className="w-2/6">
            <SearchInput placeholder="Search Staffs..." />
          </div>

          <div className="mt-8">
            <Table />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Settings;
